import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import DaumPostcodeEmbed from 'react-daum-postcode';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import instance from '../../../services/services';
import { ORDER_DETAIL_URL, ADDRESS_LIST_URL, ORDER_EXCHANGE_URL } from '../../../utils/urls';
import { formatLocalString } from '../../../utils/validation';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';
import useUserStore from '../../../store/userStore';

function CreateExchange() {
    const { userInfo } = useUserStore();
    const { order_seq, item_option_seq } = useParams();
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const [selectedMdeSeq, setSelectedMdeSeq] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [orderData, setOrderData] = useState(null);
    const [addressList, setAddressList] = useState([]);
    
    // 교환 관련 상태들을 최상단으로 이동
    const [exchangeReason, setExchangeReason] = useState('');
    const [exchangeMemo, setExchangeMemo] = useState('');
    const [exchangeMethod, setExchangeMethod] = useState('');
    const [deliveryMethod, setDeliveryMethod] = useState('');

    const [recipientInfo, setRecipientInfo] = useState({
        name: '',
        phone1: '010',
        phone2: '',
        phone3: ''
    });

    const [selectedAddressType, setSelectedAddressType] = useState('default');
    
    // 우편번호 관련 상태
    const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
    const [address, setAddress] = useState({
        zonecode: '',
        roadAddress: '',
        detailAddress: ''
    });
    

    // 우편번호 찾기 핸들러들
    const handleOpenPostcode = (e) => {
        e.preventDefault();
        setIsPostcodeOpen(true);
    };

        // 팝업 열기
        const popupOpen = (type) => {
            var box = document.querySelector(".popup-box > .box[data-name='" + type + "']");
    
            document.documentElement.classList.add("fixed");
            document.body.classList.add("fixed");
            document.querySelector(".popup-box").classList.add("fixed");
    
            if (box) {
                box.classList.add("active");
                var siblings = box.parentElement.querySelectorAll(".box");
                siblings.forEach(function(sibling) {
                    if (sibling !== box) {
                        sibling.classList.remove("active");
                    }
                });
            }
        }
    
        // 팝업 닫기
        const popupClose = () => {
            document.documentElement.classList.remove("fixed");
            document.body.classList.remove("fixed");
            document.querySelector(".popup-box").classList.remove("fixed");
        }
    
        useEffect(() => {
            document.addEventListener("mouseup", function(e) {
                console.log(e.target.className);
    
                if (e.target.classList.contains("popup-box") && e.target.classList.contains("fixed")) {
                    popupClose();
                }
            });
        }, []);


    // 주소록 리스트 api 호출
    useEffect(  () => {
        const handleGetAddressList = async () => {
        try {
            const response = await instance.post(ADDRESS_LIST_URL)
            setAddressList(response.data.data);
        } catch (error) {
                console.log(error, ':::error');
            }   
        };
        handleGetAddressList();
    }, [])

    const handleComplete = (data) => {
        setAddress({
            zonecode: data.zonecode,
            roadAddress: data.roadAddress,
            detailAddress: address.detailAddress
        });
        setIsPostcodeOpen(false);
    };

    const handleClosePostcode = () => {
        setIsPostcodeOpen(false);
    };

    const handleDetailAddressChange = (e) => {
        setAddress({
            ...address,
            detailAddress: e.target.value
        });
    };

    // 데이터 조회
    useEffect(() => {
        const fetchOrderData = async () => {
            try { 
                const response = await instance.post(ORDER_DETAIL_URL, {
                    order_seq: order_seq,
                    item_seq: item_option_seq
                });
                setOrderData(response.data.data);
            } catch (error) {
                console.error('주문 데이터 로드 실패:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchOrderData();
    }, [order_seq, item_option_seq]);

    // 주소록 보기 클릭시 팝업
    const handleAddressBookClick = () => {
        if (!addressList || addressList.length === 0) {
            setIsPopupLoginError('등록된 배송지가 없습니다.');
            return;
        }
        popupOpen('popup01');
    };

    // 주소 적용 핸들러 수정
    const handleApplyAddress = () => {
        if (!selectedMdeSeq) {
            setIsPopupLoginError('주소를 선택해주세요.');
            return;
        }

        const selectedAddress = addressList.find(addr => addr.mde_seq === selectedMdeSeq);
        
        if (selectedAddress) {
            // 주소 정보만 업데이트
            setAddress({
                zonecode: selectedAddress.recipient_zipcode || '',
                roadAddress: selectedAddress.recipient_address_street || '',
                detailAddress: selectedAddress.recipient_address_detail || ''
            });

            // 팝업 닫기
            popupClose();
        }
    };
    

    // 로딩 중이거나 데이터가 없을 때
    if (isLoading || !orderData || !orderData.orderInfo) {
        return <div>로딩중...</div>;
    }

    // 주소 선택 핸들러
    const handleAddressSelect = (selectedAddress) => {
        setSelectedMdeSeq(selectedAddress.mde_seq);
    };

    // 주소 유형 선택 핸들러 수정
    const handleAddressTypeChange = (e) => {
        const newType = e.target.value;
        setSelectedAddressType(newType);

        if (newType === 'new') {
            // 새로입력 선택 시 주소 정보 초기화
            setAddress({
                zonecode: '',
                roadAddress: '',
                detailAddress: ''
            });
        } else if (newType === 'default' && selectedMdeSeq) {
            // 기본주소 선택 시 이전에 선택했던 주소록 정보 복원
            const selectedAddress = addressList.find(addr => addr.mde_seq === selectedMdeSeq);
            if (selectedAddress) {
                setAddress({
                    zonecode: selectedAddress.recipient_zipcode || '',
                    roadAddress: selectedAddress.recipient_address_street || '',
                    detailAddress: selectedAddress.recipient_address_detail || ''
                });
            }
        }
    };

    // 교환신청 핸들러
    const handleExchangeSubmit = async () => {
        try {
            // 필수값 체크
            if (!exchangeReason) {
                setIsPopupLoginError('교환 사유를 선택해주세요.');
                return;
            }
            if (!exchangeMemo) {
                setIsPopupLoginError('교환 사유를 입력해주세요.');
                return;
            }
            if (!exchangeMethod) {
                setIsPopupLoginError('회수 방법을 선택해주세요.');
                return;
            }
            if (!deliveryMethod) {
                setIsPopupLoginError('교환 배송비 지불 방법을 선택해주세요.');
                return;
            }

            // 교환사유 코드 매핑
            const reasonCode = {
                '상품불량': '1',
                '상품상이': '2',
                '기타': '3'
            }[exchangeReason];

            // API 요청
            const response = await instance.post(ORDER_EXCHANGE_URL, {
                member_seq: userInfo.member_seq,
                order_seq: order_seq,
                item_seq: item_option_seq,
                exchange_reason: reasonCode,
                exchange_method: exchangeMethod === '직접반품' ? 'user' : 'shop',
                exchange_memo: exchangeMemo,
                exchange_delivery_method: deliveryMethod === '무통장 입금' ? 'A' : 'D',
                exchange_ea: orderData.items[0].ea,
                cellphone: orderData.orderInfo.recipient_cellphone,
                sender_zipcode: orderData.orderInfo.recipient_zipcode,
                sender_address_street: orderData.orderInfo.recipient_address_street,
                sender_address_detail: orderData.orderInfo.recipient_address_detail
            });

            if (response.data.success) {
                alert('교환신청이 완료되었습니다.');
                window.location.href = '/mypage';
            } else {
                setIsPopupLoginError(response.data.message || '교환신청에 실패했습니다.');
            }
        } catch (error) {
            console.error('교환신청 실패:', error);
            setIsPopupLoginError('교환신청 중 오류가 발생했습니다.');
        }
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"주문/배송 관리"} />
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>교환신청</h2>
                        </div>
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">주문 상품</h2>
                        </div>
                        <div className="review-list-box">
                            <ul>
                                <li>
                                    <div className="list-top">
                                        <dl>
                                            <dd>
                                                <p>주문일자:</p>
                                                <b>{orderData.orderInfo.regist_date.split(' ')[0]}</b>
                                            </dd>
                                            <dd>
                                                <Link to="#" className="link">{orderData.orderInfo.order_seq}</Link>
                                            </dd>
                                        </dl>
                                    </div>

                                    {orderData.items.map((item, index) => (
                                    <div className="list-bottom" key={index}>
                                        <div className="bottom-left flex-lg-vt">
                                            <img src={item.image} alt="상품 이미지" />
                                            <div className="inner">
                                                <div className="is-m mb12">
                                                    <div className="sticker-box">
                                                        <span className="red">배송완료</span>
                                                    </div>
                                                </div>
                                                <b>O.Tok</b>
                                                <p>{item.goods_name}</p>
                                                <small>수량: {item.ea}개</small>
                                            </div>
                                        </div>
                                        <div className="bottom-right">
                                            <div className="inner">
                                                <div className="bottom-right-price">
                                                    <p>{formatLocalString(item.price)}<em>원</em></p>
                                                </div>
                                                <div className="bottom-right-state">
                                                    <p>{item.step_name}</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">교환 사유</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li>
                                    <strong>교환사유</strong>
                                    <div>
                                        <div className="w500 col-lg-12">
                                            {/* <div className="select-box">
                                                <select className="h40">
                                                    <option>교환 수량 선택</option>
                                                </select>
                                            </div> */}
                                            <div className="select-box mt8">
                                                <select 
                                                    className="h40"
                                                    value={exchangeReason}
                                                    onChange={(e) => setExchangeReason(e.target.value)}
                                                >
                                                    <option value="">교환 사유 선택</option>
                                                    <option value="상품불량">상품불량</option>
                                                    <option value="상품상이">상품상이</option>
                                                    <option value="기타">기타</option>
                                                </select>
                                            </div>
                                            <div className="textarea-box sm mt8">
                                                <textarea 
                                                    placeholder="교환 사유를 입력해주세요."
                                                    value={exchangeMemo}
                                                    onChange={(e) => setExchangeMemo(e.target.value)}
                                                />
                                            </div>
                                            <div className="guide-text-box mt8">
                                                <p>상품불량, 상품상이 및 오배송의 경우 왕복 운송료는 모두 업체(판매자)에서 부담합니다.</p>
                                                <p>단순 변심, 선택 오류 및 기타 사유인 경우 구매자가 택배비용을 부담합니다.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">교환정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg flex-vt">
                                    <strong>추가 결제</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong className="ft-active">3,000<em className="ft-active">원</em></strong>
                                            <p>교환 배송비: 3,000원</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">수거지 정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li>
                                    <strong>회수 방법</strong>
                                    <div>
                                        <div className="flex flex-vc">
                                            <div className="check-box mr24">
                                                <input 
                                                    type="radio" 
                                                    name="radio01" 
                                                    id="radio01_01"
                                                    value="회수신청"
                                                    checked={exchangeMethod === '회수신청'}
                                                    onChange={(e) => setExchangeMethod(e.target.value)}
                                                />
                                                <label htmlFor="radio01_01"><p>회수신청</p></label>
                                            </div>
                                            <div className="check-box">
                                                <input 
                                                    type="radio" 
                                                    name="radio01" 
                                                    id="radio01_02"
                                                    value="직접반품"
                                                    checked={exchangeMethod === '직접반품'}
                                                    onChange={(e) => setExchangeMethod(e.target.value)}
                                                />
                                                <label htmlFor="radio01_02"><p>직접반품</p></label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>교환 배송비</strong>
                                    <div>
                                        <div className="flex flex-vc">
                                            <div className="check-box mr24">
                                                <input 
                                                    type="radio" 
                                                    name="radio03" 
                                                    id="radio03_01"
                                                    value="무통장 입금"
                                                    checked={deliveryMethod === '무통장 입금'}
                                                    onChange={(e) => setDeliveryMethod(e.target.value)}
                                                />
                                                <label htmlFor="radio03_01"><p>무통장 입금</p></label>
                                            </div>
                                            <div className="check-box">
                                                <input 
                                                    type="radio" 
                                                    name="radio03" 
                                                    id="radio03_02"
                                                    value="동봉"
                                                    checked={deliveryMethod === '동봉'}
                                                    onChange={(e) => setDeliveryMethod(e.target.value)}
                                                />
                                                <label htmlFor="radio03_02"><p>동봉</p></label>
                                            </div>
                                        </div>
                                        <div className="w500 col-lg-12">
                                            <div className="guide-text-box mt8">
                                                <p>예금주: 홍길동</p>
                                                <p>입금계좌: 국민은행 123456-12-123456</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>수거지 선택</strong>
                                    <div>
                                        <div className="flex flex-vc flex-lg-tj">
                                            <div className="flex flex-vc">
                                                <div className="check-box mr24">
                                                    <input 
                                                        type="radio" 
                                                        name="addressType" 
                                                        id="radio02_01"
                                                        value="default"
                                                        checked={selectedAddressType === 'default'}
                                                        onChange={handleAddressTypeChange}
                                                    />
                                                    <label htmlFor="radio02_01"><p>기본주소</p></label>
                                                </div>
                                                <div className="check-box mr24 mr-lg-0">
                                                    <input 
                                                        type="radio" 
                                                        name="addressType" 
                                                        id="radio02_02"
                                                        value="new"
                                                        checked={selectedAddressType === 'new'}
                                                        onChange={handleAddressTypeChange}
                                                    />
                                                    <label htmlFor="radio02_02"><p>새로입력</p></label>
                                                </div>
                                            </div>
                                            <div className="button-box">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-gray h32 f12 fw4 px16"
                                                    onClick={handleAddressBookClick}
                                                >
                                                    주소록 보기
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>보내시는 분</strong>
                                    <div>
                                        <div className="w400 col-lg-12">
                                            <div className="input-box">
                                                <input 
                                                    type="text" 
                                                    className="h40" 
                                                    value={recipientInfo.name}
                                                    onChange={(e) => setRecipientInfo({...recipientInfo, name: e.target.value})}
                                                    placeholder="보내시는 분 이름"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>핸드폰 번호</strong>
                                    <div>
                                        <div className="w400 flex flex-vc col-lg-12">
                                            <div className="select-box flex-1 mr8">
                                                <select 
                                                    className="h40"
                                                    value={recipientInfo.phone1}
                                                    onChange={(e) => setRecipientInfo({...recipientInfo, phone1: e.target.value})}
                                                >
                                                    <option value="010">010</option>
                                                </select>
                                            </div>
                                            <div className="input-box flex-1 mr8">
                                                <input 
                                                    type="text" 
                                                    className="h40"
                                                    value={recipientInfo.phone2}
                                                    onChange={(e) => setRecipientInfo({...recipientInfo, phone2: e.target.value})}
                                                    maxLength={4}
                                                />
                                            </div>
                                            <div className="input-box flex-1">
                                                <input 
                                                    type="text" 
                                                    className="h40"
                                                    value={recipientInfo.phone3}
                                                    onChange={(e) => setRecipientInfo({...recipientInfo, phone3: e.target.value})}
                                                    maxLength={4}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>회수 주소</strong>
                                    <div>
                                        <div className="w400 flex flex-vc col-lg-12">
                                            <div className="input-box flex-1 mr8">
                                                <input 
                                                    type="text" 
                                                    className="h40" 
                                                    value={address.zonecode}
                                                    readOnly
                                                    placeholder="우편번호"
                                                />
                                            </div>
                                            <div className="button-box">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-gray h40 f14 fw4 px16"
                                                    onClick={handleOpenPostcode}
                                                >
                                                    우편번호 찾기
                                                </button>
                                            </div>
                                        </div>
                                        <div className="input-box mt8">
                                            <input 
                                                type="text" 
                                                className="h40" 
                                                value={address.roadAddress}
                                                readOnly
                                                placeholder="기본주소"
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input 
                                                type="text" 
                                                className="h40" 
                                                value={address.detailAddress}
                                                onChange={handleDetailAddressChange}
                                                placeholder="상세주소 입력" 
                                            />
                                        </div>
                                    </div>
                                    {/* 우편번호 검색 팝업 */}
                                    {isPostcodeOpen && (
                                        <div style={{
                                            position: 'fixed',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 1000,
                                            width: '600px',
                                            height: '400px',
                                            background: 'white',
                                            border: '1px solid #ccc'
                                        }}>
                                            <DaumPostcodeEmbed
                                                onComplete={handleComplete}
                                                onClose={handleClosePostcode}
                                            />
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className="check-box mt16">
                            <input type="checkbox" name="check" id="check" />
                            <label htmlFor="check">
                                <p className="fw7">교환신청 내역을 확인하였으며, 반품 신청에 동의합니다. (필수)</p>
                                <button className="link">보기</button>
                            </label>
                        </div>
                        <div className="button-box mt40 mt-lg-24">
                            <div className="w200 flex flex-vc flex-lg-1 mr8">
                                <button type="button" className="btn btn-white f14">취소</button>
                            </div>
                            <div className="w200 flex flex-vc flex-lg-1">
                                <button 
                                    type="button" 
                                    className="btn btn-black f14"
                                    onClick={handleExchangeSubmit}
                                >
                                    교환 신청
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
              {/* 주소록 선택 팝업 */}
              <div className="popup-box">
                    <div className="box basic" data-name="popup01">
                        <div className="popup-head">
                            <button type="button" className="close" onClick={() => {popupClose()}}>닫기</button>
                        </div>
                        <div className="popup-body">
                            <div className="address-select-box">
                                <h3>주소록 선택</h3>
                                <ul>
                                    {addressList.map((address, index) => (
                                        <li key={address.mde_seq}>
                                            <input 
                                                type="radio" 
                                                name="radio02" 
                                                id={`radio02_${index}`}
                                                checked={selectedMdeSeq === address.mde_seq}
                                                onChange={() => handleAddressSelect(address)}
                                            />
                                            <label htmlFor={`radio02_${index}`}>
                                                <div>
                                                    <strong>
                                                        {address.default_flag === "Y" && <em>기본</em>}
                                                        {address.mde_title} ({address.recipient_name})
                                                    </strong>
                                                    <p>{address.recipient_zipcode} {address.recipient_address_street} {address.recipient_address_detail}</p>
                                                    <p>{address.recipient_phone}</p>
                                                </div>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="popup-foot">
                            <div className="button-box">
                                <button className="btn btn-black f13" onClick={handleApplyAddress}>선택</button>
                            </div>
                        </div>
                    </div>
                </div>
            <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
        </section>
    )
}

export default CreateExchange;
