import instance from "../services";
import { INFORMATION_VERIFY_URL } from "../../utils/urls";

// 점유인증 요청
export const verifyPossession = async (verifyData) => {
    try {
        const {userId, phoneNumber, idCheck} = verifyData;

        const response = await instance.post(INFORMATION_VERIFY_URL, {
            userid: userId,
            phoneNumber: phoneNumber,
            idCheck: idCheck
        });
        return response;
    } catch (error) {
        throw error;
    }
};
