import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { joinUser } from '../../services/Join/join';
import { checkUserId } from '../../services/Join/join';
import { ERROR_MESSAGE } from '../../utils/constants';
import { validateInput, validatePassword, validatePasswordMatch } from '../../utils/validation';
import PopupLoginError from '../../components/popupComponents/PopupLoginError';



function CreateStep4() {
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const location = useLocation();
    console.log(location.state, '::::location');
    const memData = location.state.memType;
    const navigate = useNavigate();


    const [userid, setUserid] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState({
        ID_EXISTENCT: false,
        ID: false,
        PASSWORD: false,
        PASSWORD_NOMATCH: false
    });

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        
        // 비밀번호 유효성 검사
        const isValid = validatePassword(newPassword);
        
        setError(prev => ({
            ...prev,
            PASSWORD: !isValid
        }));
    
        // 비밀번호 확인과 일치 여부 검사
        if (confirmPassword) {
            setError(prev => ({
                ...prev,
                PASSWORD_NOMATCH: !validatePasswordMatch(newPassword, confirmPassword)
            }));
        }
    };
    
    const handleConfirmPasswordChange = (e) => {
        const confirmPw = e.target.value;
        setConfirmPassword(confirmPw);
        
        setError(prev => ({
            ...prev,
            PASSWORD_NOMATCH: !validatePasswordMatch(password, confirmPw)
        }));
    };

    const handleInputChange = (setter) => (e) => {
        // name에 따라 input 포커스 되면 에러메세지 제거
        setError((prev) => ({
            ...prev,
            ...(e.target.name === 'user_id' ? {ID_EXISTENCT: false, ID: false} : {})
        }));
        setter(e.target.value);
    };

    const handleBlur = (type) => {
        CheckDuplicate(type);
    };
    
    const CheckDuplicate = useCallback(async (type) => {
        if (type === 'id') {
            if (!validateInput(userid) || (userid.length < 4 || userid.length > 16)) {
                setError((prev) => ({
                    ...prev,
                    ID: true
                }));
                return;
            }
    
            try {
                const response = await checkUserId({userid: userid});
                // data 배열의 길이로 중복 여부 확인
                const isDuplicate = response.data.data.length > 0;
                
                setError((prev) => ({
                    ...prev,
                    ID_EXISTENCT: isDuplicate,  // 데이터가 있으면 중복
                    ID: false
                }));
            } catch (e) {
                console.error(e);
            }
        }
    }, [userid]);

    

    const handleRegister = async () => {
        // 입력값 검증
        if (!userid || !password || !confirmPassword) {
            setIsPopupLoginError('아이디와 비밀번호를 입력해주세요.');
            return;
        }
    
        if (password !== confirmPassword) {
            setIsPopupLoginError('비밀번호가 일치하지 않습니다.');
            return;
        }
    
        try {
            // 기본 요청 데이터
            let requestData = {
                mem_type: location.state.memType,
                user_name: location.state.name,
                mem_phone: location.state.phone,
                email: location.state.email,
                userid,
                password,
                push_id: '',
                mailing: location.state.mailing,
                sms: location.state.sms
            };
    
            // memType이 2(도매회원)인 경우에만 business 정보 추가
            if (location.state.memType === 2) {
                requestData = {
                    ...requestData,
                    business_name: location.state.companyInfo.name,
                    business_num: location.state.companyInfo.businessNumber,
                    business_kind: location.state.companyInfo.businessType,
                    business_zipcode: location.state.companyInfo.address.zipcode,
                    business_address_street: location.state.companyInfo.address.address1,
                    business_address_detail: location.state.companyInfo.address.address2,
                    business_certificate: location.state.companyInfo.businessFile,
                };
            }
    
            console.log('회원가입 요청 데이터:', requestData);
    
            const response = await joinUser(requestData);
    
            if (response.data.success === false) {
                setIsPopupLoginError(response.data.message);
                return;
            } else {
                console.log('회원가입 성공');
                navigate('/users/createStep5', {
                    state: {
                        memData: memData
                    }
                });
            }
        } catch (error) {
            console.error('회원가입 실패:', error);
            setIsPopupLoginError('회원가입에 실패했습니다. 다시 시도해주세요.');
        }
    };
    

    return (
        <section>
            <div className="container">
                <div className="member-box">
                    <div className="title-box">
                        <h2>회원가입</h2>
                    </div>
                    <form onSubmit={(e) => { e.preventDefault();  handleRegister();}}>
                        <div className="title-box">
                            <h3>아이디/비밀번호</h3>
                        </div>
                        <div className="write-box">
                            <ul>
                                <li>
                                    <strong className="form-title">아이디</strong>
                                    <div className="form-content">
                                        <div className="input-box">
                                        <input 
                                            type="text" 
                                            placeholder="영문소문자/숫자 포함 4~16자" 
                                            value={userid}
                                            name="user_id"
                                            onChange={handleInputChange(setUserid)}
                                            onBlur={() => handleBlur('id')}
                                        />
                                        </div>
                                        <div className="message-box">
                                            {(error.ID_EXISTENCT || error.ID) && 
                                                <p className="error">
                                                    {error.ID_EXISTENCT ? ERROR_MESSAGE.ID_EXISTENCT : ERROR_MESSAGE.ID}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">비밀번호</strong>
                                    <div className="form-content">
                                        <div className="input-box">
                                        <input 
                                            type="password" 
                                            placeholder="영문소문자/숫자/특수문자 포함 8~16자" 
                                            value={password}
                                            onChange={handlePasswordChange}
                                        />
                                        </div>
                                        <div className="message-box">
                                            {error.PASSWORD && 
                                                <p className="error">
                                                    {ERROR_MESSAGE.PASSWORD}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">비밀번호 확인</strong>
                                    <div className="form-content">
                                        <div className="input-box">
                                        <input 
                                            type="password" 
                                            placeholder="비밀번호 재입력" 
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                        />
                                        </div>
                                        <div className="message-box">
                                            {error.PASSWORD_NOMATCH && 
                                                <p className="error">
                                                    {ERROR_MESSAGE.PASSWORD_NOMATCH}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-box mt24">
                            <button type="submit" className="btn btn-black">완료</button>
                        </div>
                    </form>
                </div>
            </div>
            <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
        </section>
    );
}

export default CreateStep4;
