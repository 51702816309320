import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../../components/MypageSidebarMenu';
import { getInquiryList } from '../../../../services/Border/border';
import useUserStore from '../../../../store/userStore';
import { PRODUCT_REVIEW_LIST_URL } from '../../../../utils/urls';
import instance from '../../../../services/services';


function Index() {
    const { userInfo } = useUserStore();
    console.log(userInfo.member_seq, '::::userInfo');
    const [inquiryData, setInquiryData] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    console.log(reviewData, '::::reviewData');

    
    // 내 후기 리스트
    useEffect(() => {
        const handleGetInquiryList = async () => {
            try {
                const response = await getInquiryList({
                    boardid : "review",  
                    member_seq: userInfo.member_seq,
                    member_chk : "Y" , 
                    offset : 1
                    
                });
                setInquiryData(response.data.data.data)
                

            } catch (error) {
                // 컴포넌트가 여전히 마운트 상태인 경우에만 에러 처리
                console.error('구매후기 리스트 조회 실패:', error);
            }
        };
    
        handleGetInquiryList();
    
    }, [userInfo.member_seq]); // 빈 의존성 배열 유지

    // 작성가능한 후기 리스트
    useEffect(() => {
    
        const handleGetReviewList = async () => {
            try {
                const response = await instance.post(PRODUCT_REVIEW_LIST_URL, {
                    offset : 1
                });
                setReviewData(response.data.data.data)
                

            } catch (error) {
                // 컴포넌트가 여전히 마운트 상태인 경우에만 에러 처리
                console.error('구매후기 리스트 조회 실패:', error);
            }
        };
    
        handleGetReviewList();
    
    }, []); // 빈 의존성 배열 유지


    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"구매후기"} />
                    <div className="sub-right">
                        <div className="tab-box mb0">
                            <ul>
                                <li className="active">
                                    <Link to="/mypage/review/able" className="link">
                                        작성 가능한 후기<em>{reviewData.length}</em>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mypage/review/my" className="link">
                                        내 후기<em>{inquiryData.length}</em>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="review-list-box">
                            <ul>
                                {reviewData.map((item, index) => (
                                    <li key={index}>
                                        <div className="list-top">
                                            <dl>
                                            <dd>
                                                <p>주문일자:</p>
                                                <b>{item.order_date}</b>
                                            </dd>
                                            <dd>
                                                <Link to="#" className="link">
                                                    {item.order_seq}
                                                </Link>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="list-bottom">
                                        <div className="bottom-left">
                                            <img
                                                src={item.order_goods_image}
                                                alt="상품 이미지"
                                            />
                                            <div className="inner">
                                                <b>O.Tok</b>
                                                <p>{item.goods_name}</p>
                                                <small>수량: {item.ea}개</small>
                                            </div>
                                        </div>
                                        <div className="bottom-right">
                                            <div className="button-box">
                                                <Link to="/mypage/review/create" state={{order_seq: item.order_seq, order_goods_seq: item.goods_seq, order_goods_name: item.goods_name, order_goods_image: item.order_goods_image}} className="btn btn-white sm h32">
                                                    후기작성
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
