import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { MAIN_LIST_URL } from '../../utils/urls';
import instance from '../../services/services';
import Swiper from "swiper";
import useUserStore from '../../store/userStore';
import PopupLoginError from '../../components/popupComponents/PopupLoginError';

function Main() {
    const { memberTokens } = useUserStore();
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const navigate = useNavigate();
    const [mainData, setMainData] = useState(null);
    const [bigData, setBigData] = useState(null);
    console.log(bigData, '::::bigData');

    console.log(mainData);

    // 공통 fetch 함수
    const fetchBannerData = async (type, setStateFunction) => {
        try {
            const response = await instance.post(MAIN_LIST_URL, {
                b_type: type
            });
            console.log(`response for ${type}:`, response.data.data);
            setStateFunction(response.data.data);
        } catch (error) {
            console.error(`${type} 배너 데이터 로딩 실패:`, error);
        }
    };

    useEffect(() => {
        // middle과 big 배너 데이터 동시에 가져오기
        Promise.all([
            fetchBannerData('middle', setMainData),
            fetchBannerData('big', setBigData)
        ]);
    }, []);

    const [openQuestionIndex, setOpenQuestionIndex] = useState(null); // 현재 열려 있는 질문의 인덱스

    const handleClick = (index) => {
        setOpenQuestionIndex(openQuestionIndex === index ? null : index); // 같은 질문 클릭 시 닫음
    };

    useEffect(() => {
        new Swiper(".main-values-box .swiper-container", {
            loop: true,
            loopAdditionalSlides: 1,
            spaceBetween: 0,
            slidesPerView: "auto",
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
            },
            speed: 5000,
            observer: true,
            observeParents: true,
            touchRatio: 0,
        });

        // document.querySelectorAll('.main-faq-box .list-head button').forEach(function(button) {
        //     button.addEventListener('click', function() {
        //         var parent = this.closest('li');
        //         var listBody = parent.querySelector('.list-body');

        //         // 부모 요소의 형제들에서 active 클래스 제거
        //         var siblings = Array.from(parent.parentElement.children);
        //         siblings.forEach(function(sibling) {
        //             if (sibling !== parent) {
        //                 sibling.classList.remove('active');
        //                 sibling.querySelector('.list-body').style.minHeight = '0'; // 형제 요소의 list-body 닫기
        //             }
        //         });

        //         if (parent.classList.contains('active')) {
        //             // 이미 열려 있으면, 닫기
        //             listBody.style.minHeight = '0';
        //             parent.classList.remove('active');
        //         } else {
        //             // 열기 전에, min-height를 동적으로 설정
        //             listBody.style.minHeight = listBody.scrollHeight + 'px';  // scrollHeight를 사용하여 높이를 설정
        //             parent.classList.add('active');
        //         }
        //     },  { once: true });
        // });
    }, []);

    // const handleImageClick = () => {
    //     if (mainData && mainData.target_type && mainData.link_url) {
    //         if (mainData.target_type === "blank") {
    //             // 새 창으로 열기
    //             window.open(mainData.link_url, '_blank');
    //         } else if (mainData.target_type === "self") {
    //             // 현재 창에서 열기
    //             window.location.href = mainData.link_url;
    //         }
    //     }
    // };

    // 지금 구매하기 버튼 클릭시
    const handleBuyNowClick = (e) => {
        e.preventDefault(); // 기본 링크 동작 방지
        
        if (!memberTokens) {
            setIsPopupLoginError('로그인이 필요한 서비스입니다.');
            
            setTimeout(() => {
            navigate('/login');
            }, 1000); // 1초 후 이동
            return;
        }
        // 로그인 상태면 장바구니 페이지로 이동
        navigate('/products/show/5');
    };


    useEffect(() => {
        // 애니메이션 효과 처리
        const handleScrollAnimation = () => {
            const counters = document.querySelectorAll(".scroll_on");
            const exposurePercentage = 100; // 노출 비율
            const loop = true; // 애니메이션 반복 여부

            counters.forEach((el) => {
                const rect = el.getBoundingClientRect();
                const winHeight = window.innerHeight; // 현재 브라우저 창의 높이
                const contentHeight = rect.bottom - rect.top; // 요소의 높이

                if (
                    rect.top <= winHeight - (contentHeight * exposurePercentage) / 150 &&
                    rect.bottom >= (contentHeight * exposurePercentage) / 150
                ) {
                    el.classList.add("on");
                } else if (
                    loop &&
                    (rect.bottom <= 0 || rect.top >= window.innerHeight)
                ) {
                    el.classList.remove("on");
                }
            });
        };

        // 숫자 카운터 처리
        const handleScrollCounter = () => {
            const counters = document.querySelectorAll(".counter");
            const exposurePercentage = 100; // 노출 비율
            const duration = 1000; // 애니메이션 속도(ms)
            const addCommas = true; // 숫자에 쉼표 추가 여부

            const updateCounter = (el, start, end) => {
                let startTime = null;

                const animateCounter = (timestamp) => {
                    if (!startTime) startTime = timestamp;
                    const progress = Math.min((timestamp - startTime) / duration, 1);
                    const current = Math.round(start + progress * (end - start));
                    el.textContent = addCommas ? current.toLocaleString() : current;

                    if (progress < 1) {
                        requestAnimationFrame(animateCounter);
                    } else {
                        el.textContent = addCommas ? end.toLocaleString() : end;
                    }
                };

                requestAnimationFrame(animateCounter);
            };

            counters.forEach((el) => {
                if (!el.dataset.scrolled) {
                    const rect = el.getBoundingClientRect();
                    const winHeight = window.innerHeight;
                    const contentHeight = rect.bottom - rect.top;

                    if (
                        rect.top <= winHeight - (contentHeight * exposurePercentage) / 100 &&
                        rect.bottom >= (contentHeight * exposurePercentage) / 100
                    ) {
                        const start = parseInt(el.dataset.start, 10) || 0;
                        const end = parseInt(el.dataset.end, 10) || 0;
                        updateCounter(el, start, end);
                        el.dataset.scrolled = true;
                    }
                }
            });
        };

        // 스크롤 이벤트 등록
        const handleScroll = () => {
            handleScrollAnimation();
            handleScrollCounter();
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // 초기 실행

        // 정리 함수
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <section>
            <div className="main-top-box">
                <div className="container">
                    <h2 className='scroll_on type_bottom'>오이스터로 만든<br />과일 야채 세정제, 오톡</h2>
                    <div className="top-left">
                        <div className="item-image">
                            <img src="/asset/images/img_main_item_top.png" alt={''} />
                        </div>
                        <div className="item-title">
                            <strong class="back scroll_on type_right delay02">Oyster</strong>
                            <strong class="front scroll_on type_right delay04">recycling</strong>
                        </div>
                    </div>
                    <div className="top-right">
                        <div className='image'>
                            <img src="/asset/images/img_main_top_icon_01.png" className="scroll_on type_bottom" alt='' />
                            <img src="/asset/images/img_main_top_icon_02.png" className="scroll_on type_bottom" alt='' />
                            <img src="/asset/images/img_main_top_icon_03.png" className="scroll_on type_bottom" alt='' />
                        </div>
                        <h2 className="scroll_on type_bottom">오이스터로 만든<br/>과일 야채 세정제, 오톡</h2>
                        <p className="scroll_on type_bottom">오톡은 100% 천연원료만을 사용하여 남녀노소 누구나 <br/>안전하게 사용할 수 있는 제품을 고객에게 전해드립니다.</p>
                        <div>
                            <div className="price">
                                <strike class="scroll_on type_bottom">20,000원</strike>
                                <strong class="scroll_on type_bottom">18,000원</strong>
                            </div>
                            <div className='scroll_on type_bottom'>
                                <div className="button-box">
                                    <Link 
                                        className="btn btn-active"
                                        onClick={handleBuyNowClick}
                                    >
                                        지금 구매하기
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-story-box">
                <div className="story-left">
                    <h2>
                        <img src="/asset/images/img_main_story_title.png" className="scroll_on type_bottom" alt='' />
                        <strong className="scroll_on type_bottom">STORY</strong>
                    </h2>
                    <p className='scroll_on type_bottom'>
                        건강한 식사는 깨끗한 재료에서 시작됩니다. 오톡 과일 야채 <br />
                        세정제는 천연 성분으로 안전하게 세정해 가족의 건강을 지켜줍니다. <br />
                        오늘도 건강한 식탁을 위해 오톡과 함께하세요 !
                    </p>
                    <div className='scroll_on type_bottom'>
                        <div className="button-box">
                            <Link to="/brands/introCompany" className="btn btn-black">더 알아보기</Link>
                        </div>
                    </div>
                </div>
                <div className="story-right">
                    <img alt={''} src="/asset/images/img_main_story.png" />
                    {/* <img src={mainData.pc_img} alt='메인 이미지'></img> */}
                </div>
            </div>
            <div className="main-values-box">
                <div className="values-head">
                    <div className="container">
                        <b className="scroll_on type_bottom">Oyster recycling</b>
                        <h2 className="scroll_on type_bottom">OUR VALUES</h2>
                        <ul>
                            <li className="scroll_on type_bottom">
                                <strong><em className="counter" data-start="0" data-end="92">0</em>%</strong>
                                <p>고순도 칼슘함유</p>
                            </li>
                            <li className="scroll_on type_bottom">
                                <strong><em className="counter" data-start="0" data-end="200">0</em>MESH</strong>
                                <p>초 미세 자연분말</p>
                            </li>
                            <li className="scroll_on type_bottom">
                                <strong><em className="counter" data-start="0" data-end="100">0</em>%</strong>
                                <p>식물성 천연원료</p>
                            </li>
                            <li className="scroll_on type_bottom">
                                <strong><em className="counter" data-start="0" data-end="80">0</em>G</strong>
                                <p>Mg, Mn, K, Zn</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="values-body scroll_on type_bottom">
                    <div className="swiper-container">
                        <ul className="swiper-wrapper">
                            <li className="swiper-slide item-01">
                                <img alt={''} src="/asset/images/img_values_item_01.png" />
                            </li>
                            <li className="swiper-slide item-02">
                                <img alt={''} src="/asset/images/img_values_item_02.png" />
                            </li>
                            <li className="swiper-slide item-01">
                                <img alt={''} src="/asset/images/img_values_item_01.png" />
                            </li>
                            <li className="swiper-slide item-03">
                                <img alt={''} src="/asset/images/img_values_item_03.png" />
                            </li>
                            <li className="swiper-slide item-01">
                                <img alt={''} src="/asset/images/img_values_item_01.png" />
                            </li>
                            <li className="swiper-slide item-02">
                                <img alt={''} src="/asset/images/img_values_item_02.png" />
                            </li>
                            <li className="swiper-slide item-01">
                                <img alt={''} src="/asset/images/img_values_item_01.png" />
                            </li>
                            <li className="swiper-slide item-03">
                                <img alt={''} src="/asset/images/img_values_item_03.png" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="main-product-box">
                <div className="product-image">
                    <img src="/asset/images/img_main_product.png" alt='' />
                </div>
                <div className="container">
                    <div className="product-content">
                        <ul>
                            <li className="merit-01">
                                <strong>유해물질 완벽제거</strong>
                                <p>
                                    잔류 농약제거, 미세먼지, 중금속 등 <br />
                                    유해물질 완벽제거
                                </p>
                            </li>
                            <li className="merit-02">
                                <strong>강력한 살균력</strong>
                                <p>
                                    대장균, 황색포도살구균, 폐렴균 등 <br />
                                    각종 세균 99.9% 살균
                                </p>
                            </li>
                            <li className="merit-03">
                                <strong>신선도 유지력 강화</strong>
                                <p>
                                    항균활성 효과를 가진 알카리수가 <br />
                                    미생물의 증식을 억제
                                </p>
                            </li>
                            <li className="merit-04">
                                <strong>높은 가성비</strong>
                                <p>
                                    저렴한 가격과 검증된 제품의 <br />
                                    우수성 저렴한 가격과 검
                                </p>
                            </li>
                        </ul>
                        <div className="button-box">
                            <Link className="btn btn-active" href="">
                                무료 체험하기
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="main-product-box">
                <div className="product-image">
                    <img alt={''} src="/asset/images/img_main_product.png" />
                </div>
                <div className="container">
                    <div className="product-content">
                        <ul>
                            <li className="merit-01">
                                <strong>유해물질 완벽제거</strong>
                                <p>잔류 농약제거, 미세먼지, 중금속 등 <br />유해물질 완벽제거</p>
                            </li>
                            <li className="merit-02">
                                <strong>강력한 살균력</strong>
                                <p>대장균, 황색포도살구균, 폐렴균 등 <br />각종 세균 99.9% 살균</p>
                            </li>
                            <li className="merit-03">
                                <strong>신선도 유지력 강화</strong>
                                <p>항균활성 효과를 가진 알카리수가 <br />미생물의 증식을 억제</p>
                            </li>
                            <li className="merit-04">
                                <strong>높은 가성비</strong>
                                <p>저렴한 가격과 검증된 제품의 <br />우수성 저렴한 가격과 검</p>
                            </li>
                        </ul>
                        <div className="button-box">
                            <Link to="" className="btn btn-active">무료 체험하기</Link>
                        </div>
                    </div>
                </div>
            </div> */}





            <div className="main-faq-box">
                <div className="container">
                    <div className="faq-left">
                        <b className='scroll_on type_bottom'>FAQ</b>
                        <h2 className='scroll_on type_bottom'>오톡 세정제,<br />자주 묻는 질문들에<br />답해드립니다.</h2>
                        <p className='scroll_on type_bottom'>
                            오톡 세정제에 대해서 궁금한 점이 있으신가요? 가장 자주 묻는 질문들과 그에 대한 답변을 <br />
                            한눈에 확인해보세요. 건강하고 안전한 사용을 위해 필요한 정보를 모두 모아두었습니다.
                        </p>
                        <div className='scroll_on type_bottom'>
                            <div className="button-box">
                                <Link to="/qna" className="btn btn-black">더 알아보기</Link>
                            </div>
                        </div>
                    </div>
                    <div className="faq-right">
                        <ul>
                            <li className={`scroll_on type_bottom on ${openQuestionIndex === 0 ? " active" : ""}`}>
                                <div className="list-head">
                                    <button type="button" onClick={() => handleClick(0)}>
                                        <p>Q. 오톡 과일 야채 세정제를 어떻게 사용하나요?</p>
                                    </button>
                                </div>
                                <div className="list-body">
                                    <p>
                                        오톡 세정제에 대해서 궁금한 점이 있으신가요? 가장 자주 묻는 질문들과 그에 대한 답변을 <br />
                                        한눈에 확인해보세요. 건강하고 안전한 사용을 위해 필요한 정보를 모두 모아두었습니다.
                                    </p>
                                </div>
                            </li>
                            <li className={`scroll_on type_bottom on ${openQuestionIndex === 1 ? " active" : ""}`}>
                                <div className="list-head">
                                    <button type="button" onClick={() => handleClick(1)}>
                                        <p>Q. 오톡 세정제를 사용해도 안전한가요?</p>
                                    </button>
                                </div>
                                <div className="list-body">
                                    <p>
                                        오톡 세정제에 대해서 궁금한 점이 있으신가요? 가장 자주 묻는 질문들과 그에 대한 답변을 <br />
                                        한눈에 확인해보세요. 건강하고 안전한 사용을 위해 필요한 정보를 모두 모아두었습니다.
                                    </p>
                                </div>
                            </li>
                            <li className={`scroll_on type_bottom on ${openQuestionIndex === 2 ? " active" : ""}`}>
                                <div className="list-head">
                                    <button type="button" onClick={() => handleClick(2)}>
                                        <p>Q. 오톡 세정제는 농약도 제거할 수 있나요?</p>
                                    </button>
                                </div>
                                <div className="list-body">
                                    <p>
                                        오톡 세정제에 대해서 궁금한 점이 있으신가요? 가장 자주 묻는 질문들과 그에 대한 답변을 <br />
                                        한눈에 확인해보세요. 건강하고 안전한 사용을 위해 필요한 정보를 모두 모아두었습니다.
                                    </p>
                                </div>
                            </li>
                            <li className={`scroll_on type_bottom on ${openQuestionIndex === 3 ? " active" : ""}`}>
                                <div className="list-head">
                                    <button type="button" onClick={() => handleClick(3)}>
                                        <p>Q. 오톡 세정제가 다른 세정제와 다른점은 무엇인가요?</p>
                                    </button>
                                </div>
                                <div className="list-body">
                                    <p>
                                        오톡 세정제에 대해서 궁금한 점이 있으신가요? 가장 자주 묻는 질문들과 그에 대한 답변을 <br />
                                        한눈에 확인해보세요. 건강하고 안전한 사용을 위해 필요한 정보를 모두 모아두었습니다.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
        </section>
    )
}

export default Main;
