import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { POINT_LIST_URL, MY_INFO_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import { formatLocalString } from '../../../utils/validation';

function Index() {
    const [pointList, setPointList] = useState([]);
    const [myInfo, setMyInfo] = useState(null);
    const [activeType, setActiveType] = useState(""); // 현재 선택된 타입 상태 추가

    // 포인트 리스트 조회 함수
    const fetchPointList = async (type) => {
        try {
            const response = await instance.post(POINT_LIST_URL, {
                type: type, // 전체: "", 적립: "plus", 사용: "minus", 소멸: "expire"
                offset: 1
            });
            setPointList(response.data.data);
        } catch (error) {
            console.error('error', error);
        }
    };

    // 카테고리 클릭 핸들러
    const handleCategoryClick = (type) => {
        setActiveType(type);
        fetchPointList(type);
    };

    // 초기 데이터 로드
    useEffect(() => {
        fetchPointList("");
    }, []);

    // 내 정보 리스트
    useEffect(()=> {
        const fetchMyInfo = async (type) => {
            try{
                const response = await instance.post(MY_INFO_URL);
                setMyInfo(response.data.data);
            } catch (error) {
                console.error('error', error);
            }
        };
        fetchMyInfo();
    }, []);

    // myInfo 데이터가 없을경우, null 일경우
    if (!myInfo) {
        return (
            <section>
                <div className="container">
                    <div className="sub-box">
                        <MypageSidebarMenu />
                        <div className="sub-right">
                            <div className="loading">로딩중...</div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"포인트"} />
                    <div className="sub-right">
                        <div className="flex flex-vc flex-tj bb2 pb8">
                            <div className="title-box mb0">
                                <h2 className="tl f18">
                                    포인트 <strong className="f18">{formatLocalString(myInfo.mem_point)}P</strong>
                                </h2>
                            </div>
                            <div className="category-box col-lg-12 mt-lg-16 mb0">
                                <ul>
                                    <li className={activeType === "" ? "active" : ""}>
                                        <Link to="" className="link" onClick={(e) => {
                                            e.preventDefault();
                                            handleCategoryClick("");
                                        }}>전체</Link>
                                    </li>
                                    <li className={activeType === "plus" ? "active" : ""}>
                                        <Link to="" className="link" onClick={(e) => {
                                            e.preventDefault();
                                            handleCategoryClick("plus");
                                        }}>적립</Link>
                                    </li>
                                    <li className={activeType === "minus" ? "active" : ""}>
                                        <Link to="" className="link" onClick={(e) => {
                                            e.preventDefault();
                                            handleCategoryClick("minus");
                                        }}>사용</Link>
                                    </li>
                                    <li className={activeType === "expire" ? "active" : ""}>
                                        <Link to="" className="link" onClick={(e) => {
                                            e.preventDefault();
                                            handleCategoryClick("expire");
                                        }}>소멸</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {pointList.length === 0 && (
                        <div className="list-none-box">
                            <p>포인트 내역이 없습니다.</p>
                        </div>
                        )}
                        <div className="point-list-box">
                            <ul>
                                {pointList.map((item, index) => (
                                    <li key={index}>
                                        <div className="list-head">
                                            <div>
                                                <b>{item.type_name}</b>
                                                <time>{item.use_date.split(' ')[0]}</time>
                                            </div>
                                            <strong className={item.gb === 'plus' ? 'ft-active' : ''}>
                                                {item.gb === 'plus' ? `+${formatLocalString(item.point)}P` : `-${formatLocalString(item.point)}P`}
                                            </strong>
                                        </div>
                                        <div className="list-body">
                                            <dl>
                                                <dd>{item.ordno}</dd>
                                                <dd>{item.regist_date.split(' ')[0]} 소멸예정</dd>
                                            </dl>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first"><button type="button">처음</button></li>
                                <li className="prev"><button type="button">이전</button></li>
                                {[1, 2, 3, 4, 5].map((page) => (
                                    <li key={page} className={page === 1 ? 'active' : ''}>
                                        <button type="button">{page}</button>
                                    </li>
                                ))}
                                <li className="next"><button type="button">다음</button></li>
                                <li className="last"><button type="button">맨끝</button></li>
                            </ul>
                        </div>
                        <div className="mt40 mt-lg-24">
                            <div className="guide-box">
                                <dl className="bb0 pb0">
                                    <dt>포인트 안내</dt>
                                    {[
                                        '포인트는 현금처럼 주문결제 시 1,000원부터 사용 가능하며, 포인트는 현금으로 환불될 수 없어요.',
                                        '포인트의 유효기간은 발생일로부터 최대 다음 해 해당 월 말일까지입니다. (단, 이벤트 및 프로모션에 따라 유효기간이 상이할 수 있으며, 기간 내 사용되지 않은 포인트는 자동으로 소멸돼요.)',
                                        '상품구매를 통해 적립된 포인트는 배송완료 후 7일 이내 지급되요. 단, 반품/취소시 지급된 포인트는 회수처리되는 점 양해 부탁드려요.',
                                        '회원 탈퇴 시 보유 포인트는 자동으로 소멸돼요. 부정한 방법으로 취득한 적립금/포인트가 확인되는 경우 무통보 소멸처리될 수 있는 점 유의해 주세요.',
                                    ].map((text, index) => (
                                        <dd key={index}>
                                            <em>{index + 1}.</em>
                                            <p>{text}</p>
                                        </dd>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
