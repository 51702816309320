import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { ADDRESS_CREATE_URL, ADDRESS_UPDATE_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';

function Create() {
    const location = useLocation();
    const addressData = location.state?.item;
    const isEdit = addressData !== undefined;
    const navigate = useNavigate();
    const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
    const [isPopupLoginError, setIsPopupLoginError] = useState("");
    
    // 초기 formData 설정
    const [formData, setFormData] = useState({
        mde_title: '',          
        recipient_name: '',     
        recipient_phone: {      
            phone1: '010',
            phone2: '',
            phone3: ''
        },
        recipient_zipcode: '',  
        recipient_address_street: '', 
        recipient_address_detail: '', 
        default_flag: 'N'
    });

    // 수정 모드일 때 기존 데이터로 폼 초기화
    useEffect(() => {
        if (addressData) {
            // 전화번호 분리 (11자리)
            const phone = addressData.recipient_phone;
            const phone1 = phone.substring(0, 3);
            const phone2 = phone.substring(3, 7);
            const phone3 = phone.substring(7, 11);

            setFormData({
                mde_title: addressData.mde_title || '',
                recipient_name: addressData.recipient_name || '',
                recipient_phone: {
                    phone1: phone1,
                    phone2: phone2,
                    phone3: phone3
                },
                recipient_zipcode: addressData.recipient_zipcode || '',
                recipient_address_street: addressData.recipient_address_street || '',
                recipient_address_detail: addressData.recipient_address_detail || '',
                default_flag: addressData.default_flag || 'N'
            });
        }
    }, [addressData]);

    // 우편번호 찾기 핸들러들
    const handleOpenPostcode = (e) => {
        e.preventDefault();
        setIsPostcodeOpen(true);
    };

    const handleClosePostcode = () => {
        setIsPostcodeOpen(false);
    };

    // 입력 필드 변경 핸들러
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // 전화번호 입력 핸들러
    const handlePhoneChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            recipient_phone: {
                ...prev.recipient_phone,
                [name]: value
            }
        }));
    };

    // 기본 배송지 체크박스 핸들러
    const handleDefaultFlagChange = (e) => {
        setFormData(prev => ({
            ...prev,
            default_flag: e.target.checked ? 'Y' : 'N'
        }));
    };

    // 우편번호 찾기 완료 핸들러
    const handleComplete = (data) => {
        setFormData(prev => ({
            ...prev,
            recipient_zipcode: data.zonecode,
            recipient_address_street: data.roadAddress,
            recipient_address_detail: ''
        }));
        setIsPostcodeOpen(false);
    };

    // 배송지 등록/수정 핸들러
    const handleSubmit = async () => {
        try {
            const fullPhone = `${formData.recipient_phone.phone1}${formData.recipient_phone.phone2}${formData.recipient_phone.phone3}`;
            
            const requestData = {
                mde_title: formData.mde_title,
                recipient_name: formData.recipient_name,
                recipient_phone: fullPhone,
                recipient_zipcode: formData.recipient_zipcode,
                recipient_address_street: formData.recipient_address_street,
                recipient_address_detail: formData.recipient_address_detail,
                default_flag: formData.default_flag
            };

            if (isEdit) {
                // 수정 모드일 때 mde_seq 추가
                requestData.mde_seq = addressData.mde_seq;
                await instance.post(ADDRESS_UPDATE_URL, requestData);
            } else {
                await instance.post(ADDRESS_CREATE_URL, requestData);
            }

            setIsPopupLoginError(isEdit ? '배송지 수정이 완료되었습니다.' : '배송지 등록이 완료되었습니다.');
        } catch (error) {
            console.error(isEdit ? '배송지 수정 실패:' : '배송지 등록 실패:', error);
            setIsPopupLoginError(isEdit ? '배송지 수정에 실패했습니다.' : '배송지 등록에 실패했습니다.');
        }
    };

    // PopupLoginError 컴포넌트에서 확인 버튼 클릭 시 실행될 핸들러
    const handlePopupClose = () => {
        setIsPopupLoginError('');
        navigate('/mypage/address');
    };

    // JSX에서 input 필드들 업데이트
    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"배송주소록 관리"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">배송주소록 관리</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li>
                                    <strong>배송지명</strong>
                                    <div>
                                        <div className="input-box">
                                            <input 
                                                type="text"
                                                name="mde_title"
                                                value={formData.mde_title}
                                                onChange={handleInputChange}
                                                className="h40"
                                                placeholder="배송지명"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>받으시는 분</strong>
                                    <div>
                                        <div className="w400 col-lg-12">
                                            <div className="input-box">
                                                <input 
                                                    type="text"
                                                    name="recipient_name"
                                                    value={formData.recipient_name}
                                                    onChange={handleInputChange}
                                                    className="h40"
                                                    placeholder="성명"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>핸드폰 번호</strong>
                                    <div>
                                        <div className="w400 flex flex-vc col-lg-12">
                                            <div className="select-box flex-1 mr8">
                                                <select 
                                                    name="phone1"
                                                    value={formData.recipient_phone.phone1}
                                                    onChange={handlePhoneChange}
                                                    className="h40"
                                                    maxLength={4}   
                                                >
                                                    <option value="010">010</option>
                                                </select>
                                            </div>
                                            <div className="input-box flex-1 mr8">
                                                <input 
                                                    type="text"
                                                    name="phone2"
                                                    value={formData.recipient_phone.phone2}
                                                    onChange={handlePhoneChange}
                                                    className="h40"
                                                    maxLength={4}   
                                                />
                                            </div>
                                            <div className="input-box flex-1">
                                                <input 
                                                    type="text"
                                                    name="phone3"
                                                    value={formData.recipient_phone.phone3}
                                                    onChange={handlePhoneChange}
                                                    className="h40"
                                                    maxLength={4}   
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>배송 주소</strong>
                                    <div>
                                        <div className="w400 flex flex-vc col-lg-12">
                                            <div className="input-box flex-1 mr8">
                                                <input 
                                                    type="text" 
                                                    value={formData.recipient_zipcode}
                                                    readOnly 
                                                    placeholder="우편번호"
                                                />
                                            </div>
                                            <div className="button-box">
                                                <button type="button" className="btn btn-gray h40 f14 fw4 px16" onClick={handleOpenPostcode}>
                                                    우편번호 찾기
                                                </button>
                                            </div>
                                            {isPostcodeOpen && (
                                                <>
                                                    <div 
                                                        style={{
                                                            position: 'fixed',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            zIndex: 999
                                                        }}
                                                        onClick={handleClosePostcode}
                                                    />
                                                    <div style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1000,
                                                        width: '600px',
                                                        height: '400px',
                                                        background: 'white',
                                                        border: '1px solid #ccc'
                                                    }}>
                                                        <DaumPostcodeEmbed
                                                            onComplete={handleComplete}
                                                            onClose={handleClosePostcode}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="input-box mt8">
                                            <input 
                                                type="text" 
                                                name="recipient_address_street"
                                                value={formData.recipient_address_street}
                                                readOnly
                                                placeholder="기본주소"
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input 
                                                type="text" 
                                                name="recipient_address_detail"
                                                value={formData.recipient_address_detail}
                                                onChange={handleInputChange}
                                                placeholder="상세주소 입력"
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="check-box mt16">
                            <input 
                                type="checkbox"
                                name="default_flag"
                                checked={formData.default_flag === 'Y'}
                                onChange={handleDefaultFlagChange}
                                id="check"
                            />
                            <label htmlFor="check">
                                <p>기본 배송지로 저장</p>
                            </label>
                        </div>
                        <div className="button-box mt40 mt-lg-24">
                            <div className="w200 flex flex-vc flex-lg-1 mr8">
                                <button type="button" className="btn btn-white f14" onClick={()=>{navigate(-1)}}>취소</button>
                            </div>
                            <div className="w200 flex flex-vc flex-lg-1">
                                <button type="button" className="btn btn-black f14" onClick={handleSubmit}>등록</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopupLoginError 
                isPopup={isPopupLoginError} 
                setIsPopup={handlePopupClose}  // setIsPopup 대신 handlePopupClose 사용
            />
        </section>
    );
}

export default Create;
