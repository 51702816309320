import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DaumPostcodeEmbed from 'react-daum-postcode';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import PopupUnsubscribe from '../../../components/popupComponents/PopupUnsubscribe';
import { MY_INFO_URL, PASSWORD_CHANGE_URL, MY_INFO_UPDATE_URL} from '../../../utils/urls';
import instance from '../../../services/services';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { validatePassword, validatePasswordMatch } from '../../../utils/validation';



function Create() {
    const navigate = useNavigate();

    const [isPopupUnsubscribe, setIsPopupUnsubscribe] = useState(false)
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const [userInfo, setUserInfo] = useState(null);
    console.log(userInfo, ':::userInfo');

    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    // 우편번호 관련 state 추가
    const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
    const [address, setAddress] = useState({
        zonecode: userInfo?.business_zipcode || '',
        roadAddress: userInfo?.business_address_street || '',
        detailAddress: userInfo?.business_address_detail || ''
    });

    // 파일 관련 state 추가
    const [businessFile, setBusinessFile] = useState(null);
    const [businessFileName, setBusinessFileName] = useState('');

    const [formData, setFormData] = useState({
        email: '',
        business_name: '',
        business_num: '',
        business_kind: '',
        business_zipcode: '',
        business_address_street: '',
        business_address_detail: '',
        mailing: 'N',
        sms: 'N'
    });

    // 내 정보 리스트 조회
    useEffect(() => {
        const fetchUserInfo = async () => {
            const response = await instance.post(MY_INFO_URL);
            setUserInfo(response.data.data);
        };
        fetchUserInfo();
    }, []);

        // myInfo 데이터가 없을경우, null 일경우
        if (!userInfo) {
            return (
                <section>
                    <div className="container">
                        <div className="sub-box">
                            <MypageSidebarMenu />
                            <div className="sub-right">
                                <div className="loading">로딩중...</div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    

    // 비밀번호 변경 함수 추가
    const handlePasswordChange = async () => {
        try {
            // 비밀번호 유효성 검사
            if (!newPassword || !confirmPassword) {
                setPasswordError('비밀번호를 입력해주세요.');
                return;
            }
            if (newPassword !== confirmPassword) {
                setPasswordError('비밀번호가 일치하지 않습니다.');
                return;
            }

            const response = await instance.post(PASSWORD_CHANGE_URL, {
                userid: userInfo.userid,
                password: newPassword,
                repassword: confirmPassword
            });

            if (response.data.success) {
                setIsPopupLoginError('수정되었습니다.');
                setShowPasswordChange(false);
                setNewPassword('');
                setConfirmPassword('');
                setPasswordError('');
            } else {
                setPasswordError('비밀번호 변경에 실패했습니다.');
            }
        } catch (error) {
            console.error('비밀번호 변경 오류:', error);
            setPasswordError('비밀번호 변경 중 오류가 발생했습니다.');
        }
    };

    // 우편번호 찾기 핸들러
    const handleOpenPostcode = (e) => {
        e.preventDefault();
        setIsPostcodeOpen(true);
    };

    const handleComplete = (data) => {
        setAddress({
            ...address,
            zonecode: data.zonecode,
            roadAddress: data.roadAddress,
        });
        setIsPostcodeOpen(false);
    };

    const handleClosePostcode = () => {
        setIsPostcodeOpen(false);
    };

    // 모달 외부 클릭 핸들러 추가
    const handleModalOutsideClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            setIsPostcodeOpen(false);
        }
    };

    // 파일 업로드 핸들러
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // 파일 확장자 검사
            const fileExt = file.name.split('.').pop().toLowerCase();
            if (!['jpg', 'jpeg', 'png', 'pdf'].includes(fileExt)) {
                setIsPopupLoginError('JPG, PNG, PDF 파일만 업로드 가능합니다.');
                return;
            }
            
            // 파일 크기 검사 (10MB)
            if (file.size > 10 * 1024 * 1024) {
                setIsPopupLoginError('파일 크기는 10MB 이하여야 합니다.');
                return;
            }

            setBusinessFile(file);
            setBusinessFileName(file.name);
        }
    };

    // 이메일 변경 핸들러
    const handleEmailChange = (e) => {
        setFormData(prev => ({
            ...prev,
            email: e.target.value
        }));
    };

    // 사업자 정보 변경 핸들러
    const handleBusinessInfoChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // 수신 여부 변경 핸들러
    const handleMailingChange = (e) => {
        setFormData(prev => ({
            ...prev,
            mailing: e.target.checked ? 'Y' : 'N'
        }));
    };

    // 마케팅 활용 동의 변경 핸들러
    const handleMarketingChange = (e) => {
        setFormData(prev => ({
            ...prev,
            sms: e.target.checked ? 'Y' : 'N'
        }));
    };

    // 회원정보 수정 핸들러 수정
    const handleSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            
            // 기본 정보
            formDataToSend.append('email', formData.email);
            
            // 도매회원인 경우 사업자 정보
            if (userInfo?.mem_type === "2") {
                formDataToSend.append('business_name', formData.business_name);
                formDataToSend.append('business_num', formData.business_num);
                formDataToSend.append('business_kind', formData.business_kind);
                formDataToSend.append('business_zipcode', address.zonecode);
                formDataToSend.append('business_address_street', address.roadAddress);
                formDataToSend.append('business_address_detail', address.detailAddress);
                
                if (businessFile) {
                    formDataToSend.append('business_certificate', businessFile);
                }
            }

            // 수신 동의 정보
            formDataToSend.append('mailing', formData.mailing);
            formDataToSend.append('sms', formData.sms);

            const response = await instance.post(MY_INFO_UPDATE_URL, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                setIsPopupLoginError('수정되었습니다.');
            } else {
                setIsPopupLoginError(response.data.message || '회원정보 수정 중 오류가 발생했습니다.');
            }
        } catch (error) {
            console.error('회원정보 수정 오류:', error);
            setIsPopupLoginError('회원정보 수정 중 오류가 발생했습니다.');
        }
    };

    // 팝업 닫기 핸들러 추가
    const handlePopupClose = () => {
        if (isPopupLoginError === '수정되었습니다.') {
            navigate('/');
        }
        setIsPopupLoginError('');
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"회원정보 수정"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">회원정보 수정</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg">
                                    <strong>회원유형</strong>
                                    <div>
                                        <div className="flex flex-vc">
                                            <p className="lh1-5">
                                                {userInfo?.mem_type === "1" ? '일반회원' : 
                                                userInfo?.mem_type === "2" ? '도매회원' : ''}
                                            </p>
                                            <button type="button" className="ml8 ft-lightgray lh1-5 underline" onClick={()=>{setIsPopupUnsubscribe(true)}}>회원 탈퇴하기</button>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-lg">
                                    <strong>아이디</strong>
                                    <div>
                                        <p className="lh1-5">{userInfo.userid}</p>
                                    </div>
                                </li>
                                <li>
                                    <strong>비밀번호 변경</strong>
                                    <div>
                                        <div className="w450 col-lg-12">
                                            <div className="button-box">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-gray f14 fw4 h40"
                                                    onClick={() => setShowPasswordChange(!showPasswordChange)}
                                                >
                                                    비밀번호 변경
                                                </button>
                                            </div>
                                        </div>
                                        {showPasswordChange && (
                                            <div className="w450 col-lg-12">
                                                <div className="input-box">
                                                    <input 
                                                        type="password" 
                                                        placeholder="새 비밀번호 입력" 
                                                        className="h40"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="message-box">
                                                    <p className="error" style={{ display: newPassword && !validatePassword(newPassword) ? 'block' : 'none' }}>
                                                        {ERROR_MESSAGE.PASSWORD}
                                                    </p>
                                                </div>
                                                <div className="input-box mt16">
                                                    <input 
                                                        type="password" 
                                                        placeholder="새 비밀번호를 한 번 더 입력" 
                                                        className="h40"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="message-box">
                                                    <p className="error" style={{ display: confirmPassword && !validatePasswordMatch(newPassword, confirmPassword) ? 'block' : 'none' }}>
                                                        {ERROR_MESSAGE.PASSWORD_NOMATCH}
                                                    </p>
                                                </div>
                                                <div className="button-box mt8">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-gray f14 fw4 h40 flex-1 mr8"
                                                        onClick={() => {
                                                            setShowPasswordChange(false);
                                                            setNewPassword('');
                                                            setConfirmPassword('');
                                                            setPasswordError('');
                                                        }}
                                                    >
                                                        취소
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-black f14 fw4 h40 flex-1"
                                                        onClick={handlePasswordChange}
                                                    >
                                                        변경
                                                    </button>
                                                </div>
                                                {passwordError && (
                                                    <div className="message-box">
                                                        <p className="error">{passwordError}</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <strong>이메일</strong>
                                    <div>
                                        <div className="w450 col-lg-12">
                                            <div className="input-box">
                                                <input 
                                                    type="text" 
                                                    className="h40" 
                                                    name="email"
                                                    value={formData.email} 
                                                    onChange={handleEmailChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {userInfo?.mem_type === "2" && (
                                    <>
                                        <li>
                                            <strong>회사명</strong>
                                            <div>
                                                <div className="w450 col-lg-12">
                                                    <div className="input-box">
                                                        <input 
                                                            type="text" 
                                                            className="h40" 
                                                            name="business_name"
                                                            value={formData.business_name} 
                                                            onChange={handleBusinessInfoChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong>사업자등록증</strong>
                                            <div>
                                                <div className="w450 col-lg-12">
                                                    <div className="file-box">
                                                        <p className="h40 lh40">{businessFileName || "사업자등록증을 첨부해주세요"}</p>
                                                        <label htmlFor="file" className="h40 f14 w100">
                                                            <input 
                                                                type="file" 
                                                                name="file" 
                                                                id="file" 
                                                                onChange={handleFileChange}
                                                                accept=".jpg,.jpeg,.png,.pdf"
                                                            />
                                                            첨부하기
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong>사업자등록번호</strong>
                                            <div>
                                                <div className="w450 col-lg-12">
                                                    <div className="input-box">
                                                        <input 
                                                            type="text" 
                                                            className="h40" 
                                                            name="business_num"
                                                            value={formData.business_num} 
                                                            onChange={handleBusinessInfoChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong>업종</strong>
                                            <div>
                                                <div className="w450 col-lg-12">
                                                    <div className="input-box">
                                                        <input 
                                                            type="text" 
                                                            className="h40" 
                                                            name="business_kind"
                                                            value={formData.business_kind} 
                                                            onChange={handleBusinessInfoChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong>회사 주소</strong>
                                            <div>
                                                <div className="w450 flex flex-vc col-lg-12">
                                                    <div className="input-box flex-1 mr8">
                                                        <input type="text" className="h40" value={address.zonecode} readOnly />
                                                    </div>
                                                    <div className="button-box">
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-gray h40 f14 fw4 px16"
                                                            onClick={handleOpenPostcode}
                                                        >
                                                            우편번호 찾기
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="input-box mt8">
                                                    <input type="text" className="h40" value={address.roadAddress} readOnly />
                                                </div>
                                                <div className="input-box mt8">
                                                    <input 
                                                        type="text" 
                                                        className="h40" 
                                                        placeholder="상세주소 입력" 
                                                        value={address.detailAddress}
                                                        onChange={(e) => setAddress({...address, detailAddress: e.target.value})}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <strong>이벤트 정보</strong>
                                    <div>
                                        <div className="flex flex-vc">
                                            <div className="check-box mr24">
                                                <input 
                                                    type="radio" 
                                                    name="mailing" 
                                                    id="radio01" 
                                                    checked={formData.mailing === 'Y'}
                                                    onChange={handleMailingChange}
                                                />
                                                <label htmlFor="radio01"><p>수신</p></label>
                                            </div>
                                            <div className="check-box">
                                                <input 
                                                    type="radio" 
                                                    name="mailing" 
                                                    id="radio02" 
                                                    checked={formData.mailing === 'N'}
                                                    onChange={handleMailingChange}
                                                />
                                                <label htmlFor="radio02"><p>비수신</p></label>
                                            </div>
                                        </div>
                                        {userInfo?.mem_type === "1" && (
                                        <div className="event-agree-box mt8">
                                            <div className="agree-check">
                                                <div className="check-box">
                                                    <input 
                                                        type="checkbox" 
                                                        name="marketing" 
                                                        id="check1"
                                                        checked={formData.sms === 'Y'}
                                                        onChange={handleMarketingChange}
                                                    />
                                                    <label htmlFor="check1">
                                                        <p>광고성 정보 수신 및 마케팅 활용 동의</p>
                                                        <Link to="" className="link">보기</Link>
                                                    </label>
                                                </div>
                                                <div className="flex flex-vc mt8">
                                                    <div className="check-box mr24">
                                                        <input type="checkbox" name="check" id="check2" />
                                                        <label htmlFor="check2"><p>이메일</p></label>
                                                    </div>
                                                    <div className="check-box">
                                                        <input type="checkbox" name="check" id="check3" />
                                                        <label htmlFor="check3"><p>SMS</p></label>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        )}

                                        <div className="message-box">
                                            <p className="ft-gray before">이메일, SMS 수신에 동의하시면 여러가지 할인해택과 각종 이벤트 정보를 받아보실 수 있습니다.</p>
                                            <p className="ft-gray before">회원가입관련,주문배송관련 등의 정보는 수신동의와 상관없이 구매 회원에게 발송됩니다.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-box mt40 mt-lg-24">
                            <div className="w400 col-lg-12">
                                <Link 
                                    className="btn btn-black"
                                    onClick={handleSubmit}
                                >
                                    확인
                                </Link>
                            </div>
                        </div>

                        <PopupUnsubscribe isPopup={isPopupUnsubscribe} setIsPopup={setIsPopupUnsubscribe}/>
                        
                    </div>
                </div>
            </div>
            <PopupLoginError 
                isPopup={isPopupLoginError} 
                setIsPopup={handlePopupClose}
            />
            {/* 우편번호 검색 팝 */}
            {isPostcodeOpen && (
                <div 
                    className="modal-overlay"
                    onClick={handleModalOutsideClick}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000
                    }}
                >
                    <div 
                        style={{
                            width: '600px',
                            height: '400px',
                            background: 'white',
                            position: 'relative'
                        }}
                    >
                        <DaumPostcodeEmbed
                            onComplete={handleComplete}
                            onClose={handleClosePostcode}
                        />
                    </div>
                </div>
            )}
        </section>
    )
}

export default Create;
