import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

function PopupLoginError({ isPopup, setIsPopup, onConfirm, onCancel }) {

    const location = useLocation();

    const [popupActive, setPopupActive] = useState()

    useEffect(() => {
        const body = document.body;

        body.style.overflow = isPopup ? 'hidden' : 'auto';

        return () => {
            body.style.overflow = 'auto';
        };
    }, [isPopup]);

    useEffect(() => {
        let timeout;
        if (isPopup) {
            timeout = setTimeout(() => {
                setPopupActive(true);
            }, 100);
        } else {
            setPopupActive(false);
        }

        // 타이머 클린업
        return () => clearTimeout(timeout);
    }, [isPopup]);

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, [location]);

    // 외부 영역 클릭 핸들러 추가
    const handleOutsideClick = (e) => {
        if (!e.target.closest('.box')) {
            if (onCancel) {
                onCancel();
            }
            setIsPopup(false);
        }
    };

    // 확인 버튼 클릭 핸들러
    const handleClose = (e) => {
        e.preventDefault();
        if (onConfirm) {
            onConfirm();  // 확인 동작이 있으면 실행
        }
        setIsPopup(false);
    };

    if (isPopup)
        return (
            <div 
                className="popup-box-component no-script" 
                onClick={handleOutsideClick}
            >
                <div className={`box sm ${popupActive ? 'active' : ''}`}>
                    <div className="text-box">
                        <p className="tc">{isPopup}</p>
                    </div>
                    <div className="button-box mt32">
                        <div className="w135">
                            <Link
                                className="btn btn-white f13" 
                                onClick={handleClose}
                            >
                                확인
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default PopupLoginError;
