import React from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';

function ShowExchange() {
    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"취소/교환/반품 관리"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">교환상세내역</h2>
                        </div>
                        <div className="review-list-box">
                            <ul>
                                <li className="gray">
                                    <div className="list-top">
                                        <dl>
                                            <dd>
                                                <p>주문일자:</p>
                                                <b>2024.09.26</b>
                                            </dd>
                                            <dd>
                                                <Link to="" className="link">2024040500011</Link>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="list-bottom">
                                        <div className="bottom-left flex-lg-vt">
                                            <img src="/asset/images/img_item_list_01.png" alt="상품 이미지" />
                                            <div className="inner">
                                                <div className="is-m mb12">
                                                    <div className="sticker-box">
                                                        <span className="red">교환완료</span>
                                                    </div>
                                                </div>
                                                <b>O.Tok</b>
                                                <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                {/* <small>옵션: [O.Tok]국내최초 천연 산호칼슘 과일야채 세정제</small> */}
                                                <small>수량: 1개</small>
                                            </div>
                                        </div>
                                        <div className="bottom-right">
                                            <div className="inner">
                                                <div className="bottom-right-price">
                                                    <p>8,000<em>원</em></p>
                                                </div>
                                                <div className="bottom-right-state">
                                                    <p>교환완료</p>
                                                </div>
                                                <div className="bottom-right-text">
                                                    <p>교환</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-text">
                                        <b>교환사유: 단순변심</b>
                                        <p>생각했던 느낌이 아니었고, 향이 별로였습니다 빠른 반품 처리 요청드립니다.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">교환정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg flex-vt">
                                    <strong>추가결제</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong>+3,000<em>원</em></strong>
                                            <p>교환 배송비: 0원</p>
                                            <p>결제수단: 신용/체크카드</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">수거지 정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg flex-vt">
                                    <strong>보내시는 분</strong>
                                    <div>
                                        <p className="lh1-5">강승혜</p>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>핸드폰 번호</strong>
                                    <div>
                                        <p className="lh1-5">010-1234-5678</p>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>회수 주소</strong>
                                    <div>
                                        <p className="lh1-5">[16824] 경기도 고양시 덕양사 덕은동 519-87, 덕은리버워크 A동 1713호</p>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>회수 방법</strong>
                                    <div>
                                        <div className="button-box w75">
                                            <button className="btn btn-white h32 f12 fw4">배송조회</button>
                                        </div>
                                        <p className="mt8 lh1-5 f12 ft-lightgray">
                                            택배사에 직접 연락하지 않아도 영업일 기준 3일 이내에 방문 합니다.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-box mt32 mt-lg-24">
                            <div className="w200 w-lg-120">
                                <Link to="" className="btn btn-white">목록</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShowExchange;
