import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../components/MypageSidebarMenu';
import PopupDeleteOrder from '../../components/popupComponents/PopupDeleteOrder';
import { MY_INFO_URL, MYPAGE_MAIN_URL, ORDER_CANCEL_URL, ORDER_CONFIRM_URL, ORDER_EXCHANGE_CANCEL_URL } from '../../utils/urls';
import instance from '../../services/services';
import { formatLocalString } from '../../utils/validation';
import PopupLoginError from '../../components/popupComponents/PopupLoginError';

function Index() {
    const [isPopup, setIsPopup] = useState(false);
    const [myInfo, setMyInfo] = useState(null);
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const [mypageMain, setMypageMain] = useState(null);
    console.log(mypageMain, ":::mypageMain");
    const [selectedOrder, setSelectedOrder] = useState({
        order_seq: '',
        item_option_seq: ''
    });
    const [confirmedOrders, setConfirmedOrders] = useState(() => {
        const saved = localStorage.getItem('confirmedOrders');
        return saved ? JSON.parse(saved) : [];
    });
    const [actionType, setActionType] = useState("");

    console.log(mypageMain, "L:::::::mypageMain");

    // 내 정보 리스트
    useEffect(()=> {
        const fetchMyInfo = async () => {
            try{
                const response = await instance.post(MY_INFO_URL);
                setMyInfo(response.data.data);
            } catch (error) {
                console.error('Error fetching my info:', error);
            }
        };
        fetchMyInfo();
    }, []);

    // 마이페이지 메인 리스트
    useEffect(()=> {
        const fetchMypageMain = async () => {
            try{
                const response = await instance.post(MYPAGE_MAIN_URL,{
                    s_date : "",
                    e_date : "",
                    limit : 10,
                    offset : 1
                });
                setMypageMain(response.data.data);
            } catch (error) {
                console.error('Error fetching my info:', error);
            }
        };
        fetchMypageMain();
    }, []);


    const handleCancelClick = async (order_seq, item_option_seq) => {
        setSelectedOrder({
            order_seq: order_seq,
            item_option_seq: item_option_seq
        });
        setActionType("cancel");
        setIsPopupLoginError("주문을 취소하시겠습니까?");
    };

    const handlePopupConfirm = async () => {
        if (actionType === "cancel") {
            try {
                const response = await instance.post(ORDER_CANCEL_URL, {
                    order_seq: selectedOrder.order_seq,
                    item_option_seq: selectedOrder.item_option_seq
                });
                
                if (response.data.success) {
                    alert('주문이 취소되었습니다.');
                    window.location.reload();
                } else {
                    alert(response.data.message || '주문 취소에 실패했습니다.');
                }
            } catch (error) {
                console.error('주문 취소 실패:', error);
                alert('주문 취소 중 오류가 발생했습니다.');
            }
        }
        setIsPopupLoginError(false);
        setActionType("");
    };

    const handlePopupClose = () => {
        setIsPopupLoginError(false);
        setActionType("");
    };

    // 구매확정 클릭 핸들러 수정
    const handleConfirmClick = async (order_seq, item_option_seq) => {
        try {
            const response = await instance.post(ORDER_CONFIRM_URL, {
                order_seq: order_seq,
                item_option_seq: item_option_seq
            });
            
            console.log("구매확정 응답:", response);
            
            if (response.data.success) {
                // 구매확정된 주문을 confirmedOrders 배열과 localStorage에 추가
                const updatedConfirmedOrders = [...confirmedOrders, order_seq];
                setConfirmedOrders(updatedConfirmedOrders);
                localStorage.setItem('confirmedOrders', JSON.stringify(updatedConfirmedOrders));
                
                setIsPopupLoginError("구매확정되었습니다.");
                
                // 마이페이지 메인 리스트를 다시 불러오기
                const mainResponse = await instance.post(MYPAGE_MAIN_URL, {
                    s_date: "",
                    e_date: "",
                    limit: 8,
                    offset: 1
                });
                
                if (mainResponse.data.success) {
                    setMypageMain(mainResponse.data.data);
                }

                // 1.5초 후 페이지 새로고침
                setTimeout(() => {
                }, 1500);
            } else {
                setIsPopupLoginError(response.data.message || '구매확정에 실패했습니다.');
            }
        } catch (error) {
            console.error('구매확정 실패:', error);
            setIsPopupLoginError('구매확정 중 오류가 발생했습니다.');
        }
    };

    // 교환철회 핸들러 추가
    const handleExchangeCancel = async ( return_code) => {
        try {
            // 먼저 확인 팝업을 보여줍니다
            setIsPopupLoginError("교환을 철회하시겠습니까?");
            
            // 선택된 주문 정보와 return_code를 저장
            setSelectedOrder({
                return_code: return_code
            });
        } catch (error) {
            console.error('교환철회 실패:', error);
            setIsPopupLoginError('교환철회 중 오류가 발생했습니다.');
        }
    };

    // useEffect 추가
    useEffect(() => {
        // isPopupLoginError가 false로 변경될 때(팝업이 닫힐 때) API 호출
        if (isPopupLoginError === false && selectedOrder.return_code) {
            const cancelExchange = async () => {
                try {
                    const response = await instance.post(ORDER_EXCHANGE_CANCEL_URL, {
                        return_code: selectedOrder.return_code
                    });
                    
                    if (response.data.success) {
                        alert('교환이 철회되었습니다.');
                        window.location.reload();
                    } else {
                        alert(response.data.message || '교환철회에 실패했습니다.');
                    }
                } catch (error) {
                    console.error('교환철회 실패:', error);
                    alert('교환철회 중 오류가 발생했습니다.');
                }
            };
            
            cancelExchange();
        }
    }, [isPopupLoginError, selectedOrder]);

    // myInfo 데이터가 없을경우, null 일경우
    if (!myInfo || !mypageMain) {
        return (
            <section>
                <div className="container">
                    <div className="sub-box">
                        <MypageSidebarMenu />
                        <div className="sub-right">
                            <div className="loading">로딩중...</div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }



    return (
        <section>
            <div className="container">
                <div className="sub-box main both">
                    <MypageSidebarMenu />
                    <div className="sub-right">
                        <div className="mypage-box">
                            <div className="mypage-left">
                                <p>
                                    오톡에 오신 것을 환영합니다.<br />
                                    <b>{myInfo.user_name}</b> 회원님은 <b className="ft-active">[{myInfo.group_name}]</b> 등급 회원이십니다.
                                </p>
                                <dl>
                                    <dd><Link to="/benefit" className="link">회원 혜택</Link></dd>
                                    <dd><Link to="/mypage/update" className="link">회원정보 수정</Link></dd>
                                </dl>
                            </div>
                            <div className="mypage-right">
                                <dl>
                                    <dd>

                                        <Link to="/mypage/coupon" className="inner">
                                            <b className="coupon">쿠폰</b>
                                            <strong className="ft-active">{myInfo.mem_coupon}</strong>
                                        </Link>
                                    </dd>
                                    <dd>

                                        <Link to="/mypage/point" className="inner">
                                            <b className="point">포인트</b>
                                            <strong className="ft-active">{formatLocalString(myInfo.mem_point)}</strong>
                                        </Link>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div className="mypage-step-box mt40 mt-lg-24">
                            <h2>주문처리 현황 <small>(최근 3개월)</small></h2>
                            <ul>
                                <li>
                                    <Link to="" className="inner">
                                        <p>입금대기</p>
                                        <b className="ft-active">{myInfo.count15}</b>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="" className="inner">
                                        <p>배송대기중</p>
                                        <b className="ft-active">{myInfo.count25}</b>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="" className="inner">
                                        <p>배송중</p>
                                        <b className="ft-active">{myInfo.count35}</b>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="" className="inner">
                                        <p>배송완료</p>
                                        <b className="ft-active">{myInfo.count45}</b>
                                    </Link>
                                </li>
                            </ul>
                        </div>


                        <div className="is-pc">
                            <div className="flex flex-vc flex-tj bb2 pb14 mt40">
                                <div className="title-box mb0">
                                    <h2 className="tl f18">최근 주문 내역</h2>
                                </div>
                            </div>
                            <div className="review-list-box">
                                <ul>
                                    {mypageMain.map((item, index) => (
                                        <li key={index}>
                                            <div className="list-top">
                                                <dl>
                                                    <dd>
                                                        <p>주문일자:</p>
                                                        <b>{item.regist_date.split(' ')[0]}</b>
                                                    </dd>
                                                    <dd>
                                                        <Link 
                                                            to={
                                                                item.return_code || item.step === '85' 
                                                                ? `/mypage/orders-cancel/show-return/${item.order_seq}/${item.items[0].item_option_seq}`
                                                                : `/mypage/Order/show/${item.order_seq}/${item.items[0].item_option_seq}`
                                                            } 
                                                            state={{ step_name: item.step_name }}
                                                            className="link"
                                                        >
                                                            {item.order_seq}
                                                        </Link>
                                                    </dd>
                                                </dl>
                                                <div className="button-box" style={{gap:"8px"}}>
                                                    {item.items[0].step_name === '주문접수' || item.items[0].step_name === '배송대기중' ? (
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-white h32 f12 fw4 px16" 
                                                            onClick={() => handleCancelClick(item.order_seq, item.items[0].item_option_seq)}
                                                        >
                                                            주문취소
                                                        </button>
                                                    ) : null}

                                                    {item.items[0].step_name === '배송중' && !confirmedOrders.includes(item.order_seq) && (
                                                        <>
                                                            <Link to="" className="btn btn-white h32 f12 fw4 px16">배송조회</Link>
                                                            <button 
                                                                type='button' 
                                                                className="btn btn-black h32 f12 fw4 px16"
                                                                onClick={() => handleConfirmClick(item.order_seq, item.items[0].item_option_seq)}
                                                            >
                                                                구매확정
                                                            </button>
                                                        </>
                                                    )}

                                                    {item.items[0].step_name === '배송완료' && !confirmedOrders.includes(item.order_seq) && (
                                                        <>
                                                            <Link to="" className="btn btn-white h32 f12 fw4 px16">배송조회</Link>
                                                            <button 
                                                                type='button' 
                                                                className="btn btn-black h32 f12 fw4 px16"
                                                                onClick={() => handleConfirmClick(item.order_seq, item.items[0].item_option_seq)}
                                                            >
                                                                구매확정
                                                            </button>
                                                            <Link 
                                                                to={`/mypage/order/createReturn/${item.order_seq}/${item.items[0].item_option_seq}`} 
                                                                className="btn btn-white h32 f12 fw4 px16"
                                                            >
                                                                반품신청
                                                            </Link>
                                                        </>
                                                    )}

                                                    {item.items[0].step_name === '구매확정' && (
                                                        <>
                                                            <Link to="" className="btn btn-white h32 f12 fw4 px16">배송조회</Link>
                                                            <Link to="/mypage/review/create" state={{order_seq: item.order_seq, order_goods_seq: item.goods_seq, order_goods_name: item.goods_name, order_goods_image: item.order_goods_image}} className="btn btn-white">후기작성</Link>
                                                        </>
                                                    )}

                                                    {item.items[0].step_name === '교환신청' && (
                                                        <>
                                                            <Link to="" className="btn btn-white h32 f12 fw4 px16">교환상세</Link>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-white h32 f12 fw4 px16"
                                                                onClick={() => handleExchangeCancel(item.return_code)}
                                                            >
                                                                교환철회
                                                            </button>
                                                        </>
                                                    )}

                                                    {item.items[0].step_name === '교환처리중' && (
                                                        <Link to="" className="btn btn-white h32 f12 fw4 px16">교환상세</Link>
                                                    )}

                                                    {item.items[0].step_name === '반품완료' && (
                                                        <Link to="" className="btn btn-white h32 f12 fw4 px16">반품상세</Link>
                                                    )}

                                                    {item.items[0].step_name === '주문취소' && (
                                                        <Link to="" className="btn btn-white h32 f12 fw4 px16">취소상세</Link>
                                                    )}
                                                </div>
                                            </div>
                                            

                                            {item.items.map((subItem, subIndex) => (
                                            <div className="list-bottom" key={subIndex}>
                                                <div className="bottom-left flex-lg-vt">
                                                    <img src={subItem.image} alt="img" />
                                                    <div className="inner">
                                                        <div className="is-m mb12">
                                                            <div className="sticker-box">
                                                                <span className="red">주문접수</span>
                                                            </div>
                                                        </div>
                                                        <b>O.Tok</b>
                                                        <p>{subItem.goods_name}</p>
                                                        <div className="is-pc"><small>수량: {subItem.ea}개</small></div>
                                                    </div>
                                                </div>
                                                <div className="bottom-right">
                                                    <div className="inner">
                                                        <div className="bottom-right-price">
                                                            <p>{formatLocalString(Number(subItem.unit_price)) || 0}<em>원</em></p>
                                                        </div>
                                                        <div className="bottom-right-state">
                                                            <p>{subItem.step_name}</p>
                                                            {subItem.step_name === '교환신청' ? (
                                                                <div className="button-box mt12 col-12">
                                                                    <Link to="" className="btn btn-white h32 f12">버튼내용</Link>
                                                                    <Link to="" className="btn btn-white h32 f12">버튼내용</Link>
                                                                </div>
                                                            ) : (subItem.step_name === '배송중' || subItem.step_name === '배송완료') && (
                                                                <div className="is-pc">
                                                                    <div className="number-box flex-tc">
                                                                        <b>{subItem.delivery_company || 'CJ대한통운'}</b>
                                                                        <Link to="" className="link">{subItem.tracking_number || "681931416292"}</Link>
                                                                    </div>
                                                                    {subItem.step_name === '배송완료' && (
                                                                        <div className="button-box mt12 col-12">
                                                                            {/* <Link to="/mypage/order/createExchange" className="btn btn-white h32 f12 mr4">교환신청</Link> */}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {subItem.step_name === '교환신청' ? (
                                                            <>
                                                                <div className="bottom-right-text is-pc">
                                                                    <p>-</p>
                                                                </div>
                                                                <div className="bottom-right-button is-m">
                                                                    <div className="button-box">
                                                                        <Link to="" className="btn btn-white">버튼내용</Link>
                                                                        <Link to="" className="btn btn-white">버튼내용</Link>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (subItem.step_name === '배송중' || subItem.step_name === '배송완료') && (
                                                            <>
                                                                <div className="bottom-right-text is-m">
                                                                    <div className="number-box">
                                                                        <b>{subItem.delivery_company || 'CJ대한통운'}</b>
                                                                        <Link to="" className="link">{subItem.tracking_number || '681931416292'}</Link>
                                                                    </div>
                                                                </div>
                                                                <div className="bottom-right-button">
                                                                    <div className="button-box">
                                                                        {subItem.step_name === '배송완료' && (
                                                                            <>
                                                                                <Link to="" className="btn btn-white mo">교환신청</Link>
                                                                                <Link to="" className="btn btn-white mo">반품신청</Link>
                                                                            </>
                                                                        )}
                                                                        {/* <Link to="" className="btn btn-black">구매확정</Link> */}
                                                                        {subItem.step_name === '배송완료' && (
                                                                            <>
                                                                            <Link to="/mypage/review/create" state={{order_seq: item.order_seq, order_goods_seq: subItem.goods_seq, order_goods_name: subItem.goods_name, order_goods_image: subItem.image}} className="btn btn-white">후기작성</Link>
                                                                            <Link to={`/mypage/order/createExchange/${item.order_seq}/${subItem.item_option_seq}`} className="btn btn-white">교환신청</Link>
                                                                            </>
                                                                            
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        {subItem.step_name !== '배송중' && subItem.step_name !== '배송완료' && subItem.step_name !== '교환신청' && (
                                                            <div className="bottom-right-text is-pc">
                                                                <p>-</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                        <PopupDeleteOrder isPopup={isPopup} setIsPopup={setIsPopup} />
                    </div>
                </div>
            </div>
            <PopupLoginError 
                isPopup={isPopupLoginError} 
                setIsPopup={handlePopupClose}
                onConfirm={handlePopupConfirm}
            />
        </section>
    )
}

export default Index;
