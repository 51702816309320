import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import PopupPrivate from '../../../components/popupComponents/PopupPrivate';
import instance from '../../../services/services';
import { TAX_LIST_URL } from '../../../utils/urls';

function Index() {
    const [isPopupPrivate, setIsPopupPrivate] = useState(false);
    const [taxList, setTaxList] = useState([]);

    // 세금계산서페이지 리스트
    useEffect(() => {
        const fetchTaxList = async () => {
            const response = await instance.post(TAX_LIST_URL, {
                member_chk : "Y",
                offset: 1
            });
            setTaxList(response.data.data.data);
        };
        fetchTaxList();
    }, []);

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"세금계산서 내역"}/>
                    <div className="sub-right">
                        <div className="button-box flex-tj mb14">
                            <div className="title-box mb0">
                                <h2 className="f18">세금계산서({taxList.length})</h2>
                            </div>
                            <div>
                                <Link to="/mypage/bill/create" className="btn btn-black sm px16 h32">
                                    문의하기
                                </Link>
                            </div>
                        </div>
                        <div className="board-list-box">
                            <ul>


                                {taxList && taxList.map((tax, index) => (
                                <li key={index}>
                                    <Link to="/bill/show" state={{ b_seq: tax.b_seq, taxList: tax }} className="inner">
                                        <div className="list-left order-lg-2">
                                            <div className="subject">
                                                <p>{tax?.subject || '제목 없음'}</p>
                                            </div>
                                            <div className="info">
                                                <dl>
                                                    <dd>{tax?.regist_date.split(' ')[0]}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <div className="list-right order-lg-1">
                                            <div className="state">
                                                {tax?.re_contents ? (
                                                        <span className="active">답변완료</span>
                                                    ) : (
                                                        <span>답변대기</span>
                                                    )}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                ))}




                                {/* <li>
                                    <Link to="" className="inner">
                                        <div className="list-left order-lg-2">
                                            <div className="subject">
                                                <p>세금계산서 요청합니다.</p>
                                            </div>
                                            <div className="info">
                                                <dl>
                                                    <dd>2024.09.26</dd>
                                                    <dd>cak*****</dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <div className="list-right order-lg-1">
                                            <div className="state">
                                                <span>답변대기</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="" className="inner" onClick={()=>{setIsPopupPrivate(true)}}>
                                        <div className="list-left order-lg-2">
                                            <div className="subject">
                                                <p>세금계산서 요청합니다.</p>
                                            </div>
                                            <div className="info">
                                                <dl>
                                                    <dd>2024.09.26</dd>
                                                    <dd>cak*****</dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <div className="list-right order-lg-1">
                                            <div className="state">
                                                <span className="active">답변완료</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li> */}





                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>

                        <PopupPrivate isPopup={isPopupPrivate} setIsPopup={setIsPopupPrivate}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
