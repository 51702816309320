import React from 'react';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';

function Certificate() {

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"배송주소록 관리"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">배송주소록 관리</h2>
                        </div>
                        <div className="update-box">
                            <p>
                                회원님의 안전한 개인정보 보호를 위해 <br className="is-m" />
                                비밀번호를 다시 한번 확인합니다.
                            </p>
                            <div className="inner">
                                <div className="input-box">
                                    <input
                                        type="password"
                                        placeholder="비밀번호를 입력해주세요."
                                    />
                                </div>
                                <div className="button-box">
                                    <button type="button" className="btn btn-black">
                                        확인
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Certificate;
