import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Swiper from "swiper";
import useUserStore from "../../store/userStore";
import { formatLocalString } from '../../utils/validation';
import { createInquiryList, getInquiryList, getInquiryDetailList, getInquiryTypeList } from '../../services/Border/border';
import { PRODUCT_DETAIL_INFO_URL, PRODUCT_REVIEW_URL, CART_ADD_URL, PHOTO_REVIEW_LIST_URL, PRODUCT_DETAIL_URL } from '../../utils/urls';
import instance from '../../services/services';
import PopupCarts from '../../components/popupComponents/PopupCarts';
import PopupPhotoReview from '../../components/popupComponents/PopupPhotoReview';
import PopupInquiry from '../../components/popupComponents/PopupInquiry.js';
import PopupPrivate from '../../components/popupComponents/PopupPrivate.js';
import PopupShare from '../../components/popupComponents/PopupShare.js';
import PopupLoginError from '../../components/popupComponents/PopupLoginError.js';

function Show() {
    const navigate = useNavigate();
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const { userInfo } = useUserStore();
    const { goods_seq } = useParams();
    const location = useLocation();
    console.log(location, ':::location');
    const [productDetail, setProductDetail] = useState(null);
    console.log(productDetail, ':::productDetail');
    const [inquiryContent, setInquiryContent] = useState('');
    const [isSecret, setIsSecret] = useState(false);
    const [inquiryType, setInquiryType] = useState('goods');
    const [inquiryTypeList, setInquiryTypeList] = useState([]);
    const [inquiryData, setInquiryData] = useState([]);
    const [selectedInquiryDetail, setSelectedInquiryDetail] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0); // 초기 가격
    const [totalPrice, setTotalPrice] = useState(0); // 총 구매금액
    const [deliveryPrice] = useState(3000); // 택배 금액
    const [reviewData, setReviewData] = useState([]);
    const [photoReviewData, setPhotoReviewData] = useState([]);
    const [selectedReview, setSelectedReview] = useState(null);

    // 구매후기 사진 리스트
    useEffect(() => {
        const handleGetPhotoReviewList = async () => {
            const response = await instance.post(PHOTO_REVIEW_LIST_URL, {
                offset: 1,
                limit: "8"
            });
            setPhotoReviewData(response.data.data.data || []);
        }
        handleGetPhotoReviewList();
    }, []);


    // 상품 상세 데이터 로딩 후 price 설정
    useEffect(() => {
        if (productDetail?.price) {
            setPrice(productDetail.price); // 상품 가격 업데이트
        }
    }, [productDetail]); // productDetail이 변경될 때마다 실행

    // 수량 변경 시 totalPrice 갱신
    useEffect(() => {
        if (price) {
            // 수량에 따른 금액 계산
            const newTotalPrice = price * quantity;

            // 총 금액에 배송비는 한 번만 추가
            setTotalPrice(newTotalPrice + deliveryPrice);
        }
    }, [price, quantity, deliveryPrice]); // price, quantity, deliveryPrice가 변경될 때마다 실행


    // 수량 증가 함수
    const handleIncrease = () => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
        setTotalPrice(price * newQuantity);
    };

    // 수량 감소 함수
    const handleDecrease = () => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
            setTotalPrice(price * newQuantity);
        }
    };

    // 구매후기 데이터 리스트
    useEffect(() => {
    
        const handleGetInquiryList = async () => {
            try {
                const response = await getInquiryList({
                    boardid : "review",  
                    member_seq: userInfo.member_seq,
                    member_chk : "Y" , 
                    offset : 1
                });
                setReviewData(response.data.data.data)
                

            } catch (error) {
                console.error('문의후기 리스트 조회 실패:', error);
            }
        };
    
        handleGetInquiryList();
    
    }, [userInfo.member_seq]);



    const popupClose = () => {
        document.documentElement.classList.remove("fixed");
        document.body.classList.remove("fixed");
        document.querySelector(".popup-box").classList.remove("fixed");
        document.querySelectorAll(".popup-box .box").forEach(box => box.classList.remove("active"));

        // 뒤로가기를 통한 팝업 닫기라면 히스토리 상태를 되돌림
        if (window.history.state?.popup) window.history.back();
    };

    useEffect(() => {
        const handlePopState = () => popupClose();
        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, []);

    
    // 상세페이지 데이터
    useEffect(() => {
        const fetchProductDetail = async (goodsSeq) => {
            try {
                const response = await instance.post(PRODUCT_DETAIL_INFO_URL, {
                    goods_seq: goodsSeq,
                    member_seq: userInfo.member_seq
                });
                setProductDetail(response.data.data);
            } catch (error) {
                console.error('상품 상세 정보 로딩 실패:', error);
            }
        };

        const storedGoodsSeq = localStorage.getItem('goods_seq');
        const currentGoodsSeq = goods_seq || storedGoodsSeq;

        if (currentGoodsSeq) {
            localStorage.setItem('goods_seq', currentGoodsSeq);
            fetchProductDetail(currentGoodsSeq);
        }
    }, [goods_seq, userInfo.member_seq]);

    useEffect(() => {
        var swiper1 = new Swiper(".big-swiper .swiper-container", {
            spaceBetween: 10,
            speed: 1000,
            touchRatio: 0,
        });

        new Swiper(".thumb-swiper .swiper-container", {
            slidesPerView: 6,
            spaceBetween: 8,
            observer: true,
            observeParents: true,
            watchSlidesProgress: true,
        });

        new Swiper(".photo-view .swiper-container", {
            spaceBetween: 10,
            speed: 1000,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: ".photo-view .swiper-button-next",
                prevEl: ".photo-view .swiper-button-prev",
            },
        });

        document.querySelectorAll('.thumb-swiper button').forEach(function(button) {
            button.addEventListener('click', function() {
                var parents = this.closest('.swiper-slide');
                var index = Array.from(parents.parentElement.children).indexOf(parents);

                parents.classList.add('active');
                Array.from(parents.parentElement.children).forEach(function(sibling) {
                    if (sibling !== parents) {
                        sibling.classList.remove('active');
                    }
                });

                swiper1.slideTo(index);
            });
        });
    }, []);

    // 상품문의 등록
    const handleCreateInquiry = async (event) => {
        event.preventDefault();
        const data = {
            boardid: "goods",
            board_type: inquiryType,
            subject: "",
            contents: inquiryContent,
            secret_flag: isSecret ? 'Y' : 'N',
            goods_seq: goods_seq || localStorage.getItem('goods_seq')
        };
        
        try {
            const response = await createInquiryList(data);
            
            if (response.data.success) {
                setIsPopupLoginError('상품문의가 등록되었습니다.');
                setIsPopupInquiry(false); // popupClose() 대신 상태로 관리
                setInquiryContent('');
                setIsSecret(false);
                
                // 문의 목록 새로고침
                const response = await getInquiryList({
                    boardid: "goods",
                    member_chk: "N",
                    offset: 1,
                    board_type: "goods",
                });
                setInquiryData(response.data.data.data);
            } else {
                throw new Error(response.data.message || '등록에 실패했습니다.');
            }
        } catch (error) {
            console.error('상품문의 등록 실패:', error);
            setIsPopupLoginError('상품문의 등록에 실패했습니다.');
        }
    };

    useEffect(() => {
        let isMounted = true; // 마운트 상태를 추적하는 플래그
    
        const handleGetInquiryList = async () => {
            try {
                const response = await getInquiryList({
                    boardid: "goods",
                    member_chk: "N",
                    offset: 1,
                    board_type: "",
                });
                const inquiryList = response.data.data.data;
                
                // 컴포넌트가 여전히 마운트 상태인 경우에만 상태 업데이트
                if (isMounted) {
                    setInquiryData(inquiryList);
                }
            } catch (error) {
                // 컴포넌트가 여전히 마운트 상태인 경우에만 에러 처리
                if (isMounted) {
                    console.error('상품문의 리스트 조회 실패:', error);
                    setIsPopupLoginError('상품문의 리스트 조회에 실패했습니다.');
                }
            }
        };
    
        handleGetInquiryList();
    
        // 클린업 함수
        return () => {
            isMounted = false; // 컴포넌트 언마운트 시 플래그 설정
        };
    }, []); // 빈 의존성 배열 유지

    // 문의 상세 정보 조회 함수
    const fetchInquiryDetail = async (b_seq) => {
        // 이미 로드된 상세 정보가 있다면 다시 로드하지 않음
        if (selectedInquiryDetail[b_seq]) return;

        try {
            const response = await getInquiryDetailList({ b_seq });
            setSelectedInquiryDetail(prev => ({
                ...prev,
                [b_seq]: response.data.data
            }));
        } catch (error) {
            console.error('문의 상세 정보 조회 실패:', error);
        }
    };

    const loadInquiryTypeList = React.useCallback(async () => {
        // 이미 데이터가 있다면 다시 로드하지 않음
        if (inquiryTypeList.length > 0) return;
        
        try {
            const response = await getInquiryTypeList({ board_type: "goods_type" });
            
            const transformedTypeList = response.data.data.map(item => ({
                code: item.codecd,
                name: item.value
            }));
            
            setInquiryTypeList(transformedTypeList);
        } catch (error) {
            console.error('상품 타입 리스트 조회 실패:', error);
        }
    }, [inquiryTypeList.length]); // 의존성 배열에 inquiryTypeList.length 추가

    // 좋아요 처리 함수 수정
    const handleLikeClick = async (event) => {
        event.preventDefault();
        
        try {
            const goodsSeq = goods_seq || localStorage.getItem('goods_seq');
            
            if (!goodsSeq) {
                setIsPopupLoginError('상품 정보를 찾을 수 없습니다.');
                return;
            }

            const response = await instance.post(PRODUCT_REVIEW_URL, {
                member_seq: userInfo.member_seq,
                goods_seq: goodsSeq,
            });

            if (response.data.success) {
                setProductDetail(prevDetail => {
                    const isCurrentlyLiked = prevDetail.liked || prevDetail.goods_like_seq;
                    const currentCount = parseInt(prevDetail.like_count || 0);
                    
                    return {
                        ...prevDetail,
                        liked: !isCurrentlyLiked,
                        goods_like_seq: isCurrentlyLiked ? null : response.data.data?.goods_like_seq,
                        like_count: isCurrentlyLiked ? 
                            currentCount - 1 : 
                            currentCount + 1
                    };
                });
            }
        } catch (error) {
            console.error("좋아요 처리 오류:", error);
        }
    };


    // 장바구니 추가 함수 수정
    const handleCartAdd = async () => {
        try {
            // location.state가 없을 경우 localStorage에서 goods_seq 가져오기
            const goodsSeq = goods_seq || localStorage.getItem('goods_seq');
            
            if (!goodsSeq) {
                setIsPopupLoginError('상품 정보를 찾을 수 없습니다.');
                return;
            }

            const response = await instance.post(CART_ADD_URL, {
                goods_seq: goodsSeq,
                ea: quantity,
            });

            if (response.data.success) {
                setIsPopupCarts(true)
            } else {
                setIsPopupLoginError('장바구니 추가에 실패했습니다.');
            }
        } catch (error) {
            console.error("장바구니 추가 오류:", error);
            setIsPopupLoginError('장바구니 추가 중 오류가 발생했습니다.');
        }
    };


    const handlePurchase = () => {
        if (!productDetail) {
            setIsPopupLoginError('상품 정보를 불러오는 중입니다.');
            return;
        }
        const orderData = {
            cartList: {
                data: [{
                    goods_seq: productDetail.goods_seq,
                    goods_name: productDetail.goods_name,
                    goods_image: productDetail.goods_image[0],
                    consumer_price: productDetail.consumer_price,
                    price: productDetail.price,
                    sale_percent: productDetail.sale_percent,
                    quantity: quantity,
                    supply_price: productDetail.supply_price,
                    margin_rate: productDetail.margin_rate,
                    goods_status: productDetail.goods_status,
                    banner_image: productDetail.banner_image,
                    main_image: productDetail.main_image,
                    goods_overview: productDetail.goods_overview,
                    goods_guide: productDetail.goods_guide,
                    delivery_recipient_address_street: productDetail.delivery_recipient_address_street,
                    ea : quantity
                }]
            },
            payInfo: {
                settlePrice: Number(productDetail.price) * quantity,
                delivery_price: deliveryPrice,
                sumSalePrice: Number(productDetail.consumer_price - productDetail.price) * quantity,
                sumPointUse: 0,
                reserve: 0
            }
        };
    
        navigate('/orders/create', {
            state: orderData
        });
    };

    // 구매후기 상세 리스트
    const handleReviewClick = async (b_seq) => {
        try {
            const response = await instance.post(PRODUCT_DETAIL_URL, {
                b_seq: b_seq
            });
            
            // 응답 처리 (필요한 경우)
            setSelectedReview(response.data.data.data);
            
        } catch (error) {
            console.error('리뷰 상세 정보 로딩 실패:', error);
        }
    };
        

    // 장바구니 팝업
    const [isPopupCarts, setIsPopupCarts] = useState(false);

    // 포토리뷰
    const [isPopupPhotoReview, setIsPopupPhotoReview] = useState(false)
    // 포토 리뷰 보기모드
    const [isPopupView, setIsPopupView] = useState(true);

    // 문의 팝업
    const [isPopupInquiry, setIsPopupInquiry] = useState(false)

    // 비밀글입니다
    const [isPopupPrivate, setIsPopupPrivate] = useState(false)

    // 공유팝업
    const [isPopupShare, setIsPopupShare] = useState(false)

    return (
        <section className="overflow">
            <div className="container">
                <div className="item-view-box">
                    <div className="view-head">
                        <div className="head-image">
                            <div className="big-swiper">
                                <div className="sticker-box">
                                    {productDetail?.best_label === "Y" && (
                                        <span className="red">추천</span>
                                    )}
                                    {productDetail?.new_label === "Y" && (
                                        <span className="orange">NEW</span>
                                    )}
                                </div>
                                <div className="swiper-container">
                                    <ul className="swiper-wrapper">
                                        {/* 메인 이미지 영역 - 클릭했을 때 보여질 영역 */}
                                        {[...Array(8)].map((_, index) => (
                                            <li key={index} className="swiper-slide"
                                            style={{backgroundImage: `url(${productDetail?.goods_image?.[index % productDetail.goods_image.length]})`}}></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="thumb-swiper">
                                <div className="swiper-container">
                                    <ul className="swiper-wrapper">
                                        {/* 썸네일 영역 - 클릭하는 영역 */}
                                        {[...Array(8)].map((_, index) => (
                                            <li key={index} className={`swiper-slide ${index === 0 ? 'active' : ''}`}>
                                                <button type="button"
                                                        style={{backgroundImage: `url(${productDetail?.goods_image?.[index % productDetail.goods_image.length]})`}}></button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="head-content">
                            <div className="subject">
                                <small>O.tok <button className="share" onClick={()=>{setIsPopupShare(true)}}>공유하기</button></small>
                                <strong>{productDetail?.goods_name}</strong>
                            </div>
                            <div className="price">
                                <small>
                                    <strike>{productDetail?.consumer_price ? formatLocalString(productDetail.consumer_price) : '0'}</strike>원
                                </small>
                                <div>
                                    <strong>{productDetail?.sale_percent}%</strong>
                                    <p>{productDetail?.price ? formatLocalString(productDetail.price) : '0'}<em>원</em></p>
                                </div>
                            </div>
                            <div className="info">
                                <dl>
                                    <dd>
                                        <b>배송방법</b>
                                        <p>택배</p>
                                    </dd>
                                    <dd>
                                        <b>배송비</b>
                                        <p>{formatLocalString(deliveryPrice)}원</p>
                                    </dd>
                                </dl>
                            </div>

                            {/* <div className="option">
                                <h3>상품옵션</h3>
                                <div className="select-box">
                                    <select>
                                        <option>[필수] 옵션을 선택해주세요.</option>
                                    </select>
                                </div>
                                <div className="select-box mt8">
                                    <select>
                                        <option>[필수] 옵션을 선택해주세요.</option>
                                    </select>
                                </div>
                            </div> */}

                            
                            <div className="list">
                                <ul>
                                    <li>
                                        <p>[O.Tok]{productDetail?.goods_name}</p>
                                        <div className="list-left">
                                            <div className="quantity-box">
                                                <button 
                                                    type="button" 
                                                    className="minus" 
                                                    onClick={handleDecrease}
                                                >
                                                    감소
                                                </button>
                                                <input 
                                                    type="text" 
                                                    value={quantity} 
                                                    readOnly
                                                />
                                                <button 
                                                    type="button" 
                                                    className="plus" 
                                                    onClick={handleIncrease}
                                                >
                                                    증가
                                                </button>
                                            </div>
                                        </div>
                                        <div className="list-right">
                                            <strong>
                                                {formatLocalString(totalPrice)}
                                                <em>원</em>
                                            </strong>
                                            <button type="button" className="close">삭제</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="total">
                                <strong>총 상품 금액</strong>
                                <div>
                                    <small>총 수량 {quantity}개</small>
                                    <b>{formatLocalString(totalPrice)}</b>
                                    <em>원</em>
                                </div>
                            </div>
                            <div className="button">
                                <div className="button-box">
                                    <button 
                                        type="button" 
                                        className={`like ${(productDetail?.liked || productDetail?.goods_like_seq) ? 'active' : ''}`} 
                                        onClick={handleLikeClick}
                                    >
                                        <p>{Number(productDetail?.like_count || 0)}</p>
                                    </button>
                                    <button onClick= {handleCartAdd} className="btn btn-gray mr8">장바구니</button>
                                    <button className="btn btn-active" onClick={handlePurchase}>구매하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="view-banner">
                        <img alt='' src={productDetail?.banner_image}/>
                    </div>
                    <div className="view-tab">
                        <div className="tab-container">
                            <div className="tab-box">
                                <ul>
                                    <li className="active">
                                        <button type="button">상세정보</button>
                                    </li>
                                    <li>
                                        <button type="button">구매안내</button>
                                    </li>
                                    <li>
                                        <button type="button">구매후기<em>{reviewData.length}</em></button>
                                    </li>
                                    <li>
                                        <button type="button">상품문의<em>{inquiryData.length}</em></button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content-box">
                                <div className="content active">
                                    <div dangerouslySetInnerHTML={{__html: productDetail?.goods_overview}}/>
                                </div>
                                <div className="content">
                                    <div className="guide-box">
                                        <div dangerouslySetInnerHTML={{__html: productDetail?.goods_guide}}/>
                                    </div>
                                </div>


                                <div className="content">
                                    <div className="item-review-box">
                                        <h3>구매후기({reviewData.length})</h3>
                                        {!reviewData.length && (
                                            <div className="review-none">
                                                <p>등록된 후기글이 없습니다.</p>
                                            </div>
                                        )}
                                        {/* 구매후기 이미지 리스트 */}
                                        <div className="review-image">
                                            <ul>
                                                {photoReviewData.map((photo, index) => {
                                                    // 마지막 항목인 경우
                                                    if (index === photoReviewData.length - 1) {
                                                        return (
                                                            <li key={index} className="more">
                                                                <button 
                                                                    type="button" 
                                                                    onClick={() => {
                                                                        setIsPopupPhotoReview(true);
                                                                        setIsPopupView(false);
                                                                    }}
                                                                    style={{backgroundImage: `url(${photo.new_filepath})`}}
                                                                    className="list"
                                                                >
                                                                    <p>전체보기</p>
                                                                </button>
                                                            </li>
                                                        );
                                                    }

                                                    return (
                                                        <li key={index}>
                                                            <button 
                                                                type="button" 
                                                                onClick={() => {
                                                                    setIsPopupPhotoReview(true);
                                                                    setIsPopupView(true);
                                                                }}
                                                                style={{backgroundImage: `url(${photo.new_filepath})`}}
                                                            >
                                                            </button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>

                                        <div className="review-content">
                                            <ul>
                                                {reviewData.map((review, index) => (
                                                    <React.Fragment key={index}>
                                                        <li 
                                                            onClick={() => handleReviewClick(review.b_seq)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <div className="content-left">
                                                                <div>
                                                                    <div className="score-box">
                                                                        {[1, 2, 3, 4, 5].map((star) => (
                                                                            <span 
                                                                                key={star} 
                                                                                className={star <= review.grade ? "active" : ""}
                                                                            >
                                                                                별점 {star}
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                    <b>{review.userid.slice(0, 3)}{'*'.repeat(5)}</b>
                                                                </div>
                                                                <b>[O.Tok] {review.subject}</b>
                                                                <p>{review.contents}</p>
                                                            </div>
                                                            <div className="content-right">
                                                                <time>{review.regist_date.split(' ')[0]}</time>
                                                                <img alt='' src={review.order_goods_image} />
                                                            </div>
                                                        </li>
                                                        
                                                        {/* 선택된 리뷰의 상세 정보 표시 */}
                                                        {selectedReview && selectedReview.b_seq === review.b_seq && (
                                                            <li className="reply">
                                                                <div className="content-left">
                                                                    <div>
                                                                        <div className="score-box">
                                                                            {[1, 2, 3, 4, 5].map((star) => (
                                                                                <span 
                                                                                    key={star} 
                                                                                    className={star <= selectedReview?.grade ? "active" : ""}
                                                                                >
                                                                                    별점 {star}
                                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                        <b>{selectedReview?.userid ? `${selectedReview.userid.slice(0, 3)}${'*'.repeat(5)}` : '***'}</b>
                                                                    </div>
                                                                    <b>[O.Tok] {selectedReview?.subject}</b>
                                                                    <p>{selectedReview?.contents}</p>
                                                                </div>
                                                                <div className="content-right">
                                                                    <time>{selectedReview?.regist_date?.split(' ')[0]}</time>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="paging-box">
                                        <ul>
                                            <li className="prev"><Link to="">이전</Link></li>
                                            <li className="active"><Link to="">1</Link></li>
                                            <li><Link to="">2</Link></li>
                                            <li className="next"><Link to="">다음</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="item-qa-box">
                                        <h3>
                                            상품문의({inquiryData.length})
                                            <div className="button-box">
                                                <button type="button" onClick={() => setIsPopupInquiry(true)}
                                                        className="btn btn-black h32 px16 f12">문의하기
                                                </button>
                                            </div>
                                        </h3>
                                        {inquiryData.length > 0 ? (
                                            <div className="qa-list-box">
                                                <ul>
                                                    {inquiryData.map((inquiry) => (
                                                        <React.Fragment key={inquiry.b_seq}>
                                                            <li 
                                                                onClick={() => {
                                                                    fetchInquiryDetail(inquiry.b_seq)
                                                                    if (inquiry.secret_flag === 'Y') {
                                                                        setIsPopupPrivate(true)
                                                                    }
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                                className='secret'
                                                            >
                                                                <div className='list-q'>
                                                                    <div className="list-left">
                                                                        <button className='inner'>
                                                                            <div className='content'>
                                                                                <b>
                                                                                    {inquiry.goods_type_name}
                                                                                </b>
                                                                                {inquiry.secret_flag === 'Y' ? (
                                                                                    <p className="secret">비밀글입니다.</p>
                                                                                ) : (
                                                                                    <p className='no-before'>{inquiry.contents}</p>
                                                                                )}
                                                                                <dl>
                                                                                    <dd>{inquiry.regist_date?.split(' ')[0]}</dd>
                                                                                    <dd>{inquiry.userid.slice(0, 3)}{'*'.repeat(5)}</dd>
                                                                                </dl>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div className="list-right">
                                                                        <div className='sticker-box'>
                                                                            <span className={inquiry.detail?.data?.re_contents ? 'red' : 'gray'}>
                                                                                {inquiry.detail?.data?.re_contents ? '답변완료' : '답변대기'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            {/* 답변완료시 */}
                                                            {selectedInquiryDetail[inquiry.b_seq]?.re_contents && (
                                                                <li className="reply">
                                                                    <div className='list-q'>
                                                                        <div className="list-left">
                                                                            <strong>답변 내용</strong>
                                                                            <p>{selectedInquiryDetail[inquiry.b_seq].re_contents}</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className="qa-none">
                                                <p>등록된 문의글이 없습니다.</p>
                                            </div>
                                        )}


                                        
                                        {/* <div className="qa-list">
                                            <ul>
                                                <li>
                                                    <div className="list-left">
                                                        <strong>입금문의</strong>
                                                        <p className="secret">비밀글입니다.</p>
                                                        <dl>
                                                            <dd>2024.09.26</dd>
                                                            <dd>cak******</dd>
                                                        </dl>
                                                    </div>
                                                    <div className="list-right">
                                                        <span>답변대기</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="list-left">
                                                        <strong>입금문의</strong>
                                                        <p>세정제의 천연 성분이 과일의 맛이나 향에 영향을 주지 않나요?</p>
                                                        <dl>
                                                            <dd>2024.09.26</dd>
                                                            <dd>cak******</dd>
                                                        </dl>
                                                    </div>
                                                    <div className="list-right">
                                                        <span>답변대기</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="list-left">
                                                        <strong>입금문의</strong>
                                                        <p>세정제의 천연 성분이 과일의 맛이나 향에 영향을 주지 않나요?</p>
                                                        <dl>
                                                            <dd>2024.09.26</dd>
                                                            <dd>cak******</dd>
                                                        </dl>
                                                    </div>
                                                    <div className="list-right">
                                                        <span className="active">답변완료</span>
                                                    </div>
                                                </li>
                                                <li className="reply">
                                                    <div className="list-left">
                                                        <strong>답변 내용</strong>
                                                        <p>
                                                            안녕하세요 고객님 오톡입니다.<br/><br/>
                                                            네, 천연 산호칼슘 과일야채 세정제는 베리류와 같은 작은 과일이나 잎이 많은 채소에도 안전하게 사용하실 수
                                                            있습니다. <br/>
                                                            산호칼슘 성분이 과일과 채소의 표면을 깨끗하게 세정해주어 잔여 농약이나 오염물질을 효과적으로
                                                            제거해줍니다. <br/>
                                                            세정 후에도 과일과 채소의 본연의 신선함을 유지하면서 잔여물이 남지 않도록 깔끔하게 헹구어져 안심하고 드실 수
                                                            있습니다.<br/><br/>
                                                            감사합니다.
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="paging-box">
                                        <ul>
                                            <li className="prev"><Link to="">이전</Link></li>
                                            <li className="active"><Link to="">1</Link></li>
                                            <li><Link to="">2</Link></li>
                                            <li className="next"><Link to="">다음</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PopupCarts isPopup={isPopupCarts} setIsPopup={setIsPopupCarts} />


                
                <PopupPhotoReview isPopup={isPopupPhotoReview} setIsPopup={setIsPopupPhotoReview} view={isPopupView} />

                <PopupInquiry 
                    isPopup={isPopupInquiry} 
                    setIsPopup={setIsPopupInquiry} 
                    loadInquiryTypeList={loadInquiryTypeList}
                    userInfo={userInfo}
                    isSecret={isSecret}
                    setIsSecret={setIsSecret}
                    inquiryType={inquiryType}
                    setInquiryType={setInquiryType}
                    inquiryTypeList={inquiryTypeList}
                    inquiryContent={inquiryContent}
                    setInquiryContent={setInquiryContent}
                    handleCreateInquiry={handleCreateInquiry}
                />

                <PopupPrivate isPopup={isPopupPrivate} setIsPopup={setIsPopupPrivate}/>

                <PopupShare isPopup={isPopupShare} setIsPopup={setIsPopupShare}/>
            </div>
            <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
        </section>
    )
}

export default Show;
