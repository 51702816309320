import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { ORDER_DETAIL_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import { formatLocalString } from '../../../utils/validation';

function ShowReturn() {
    const { order_seq, item_option_seq } = useParams();
    const location = useLocation();
    console.log(location.state.step_name,'::location')
    const [orderDetail, setOrderDetail] = useState(null);
    console.log(orderDetail, ':::orderDetail');

    // 환불 금액 계산 함수
    const calculateRefund = () => {
        if (!orderDetail?.orderInfo) return { totalRefund: 0, pointRefund: 0, paymentRefund: 0 };

        const settlePrice = Number(orderDetail.orderInfo.settleprice) || 0;
        const returnShippingPrice = Number(orderDetail.orderInfo.return_shipping_price) || 0;
        const usedPoint = Number(orderDetail.orderInfo.point) || 0;

        const totalRefund = settlePrice - returnShippingPrice + usedPoint;
        const paymentRefund = settlePrice - returnShippingPrice;

        return {
            totalRefund,
            pointRefund: usedPoint,
            paymentRefund
        };
    };

    useEffect(()=> {
        const fetchOrderDetail = async () => {
            try {
                const response = await instance.post(ORDER_DETAIL_URL, {
                    order_seq: order_seq,
                    item_option_seq: item_option_seq
                });
                setOrderDetail(response.data.data);
            } catch (error) {
                console.error("주문 상세 조회 실패", error);
            }
        }
        fetchOrderDetail();
    }, [order_seq, item_option_seq]);

    const RETURN_TYPE = {
        '반품신청': '반품',
        '교환신청': '교환',
        '교환완료': '교환',
        '결제취소': '취소',
        '주문취소': '취소',
        '교환철회': '교환',
        '반품철회': '반품',
        '배송완료': '배송',
        '배송대기중': '배송'
    };

    // 데이터가 없을 때
    if (!orderDetail) {
        return (
            <section>
                <div className="container">
                    <div className="sub-box">
                        <MypageSidebarMenu activeItem={"취소/교환/반품 관리"} />
                        <div className="sub-right">
                            <div className="list-none-box">
                                <p>주문 상세 정보를 찾을 수 없습니다.</p>
                            </div>
                            <div className="button-box mt32 mt-lg-24">
                                <div className="w200 w-lg-120">
                                    <Link to="/mypage/orders-cancel" className="btn btn-white">목록</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }


    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"취소/교환/반품 관리"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">반품상세내역</h2>
                        </div>
                        <div className="review-list-box">
                            <ul>
                                <li className="gray">
                                    <div className="list-top">
                                        <dl>
                                            <dd>
                                                <p>주문일자:</p>
                                                <b>{orderDetail.orderInfo.regist_date.split(' ')[0]}</b>
                                            </dd>
                                            <dd>
                                                <Link className="link">{orderDetail.orderInfo.order_seq}</Link>
                                            </dd>
                                        </dl>
                                    </div>
                                    {orderDetail.items.map((item, index) => (
                                    <div className="list-bottom" key={index}>
                                        <div className="bottom-left flex-lg-vt">
                                            <img src={item.image} alt="상품 이미지" />
                                            <div className="inner">
                                                <div className="is-m mb12">
                                                    <div className="sticker-box">
                                                        <span className="red">반품완료</span>
                                                    </div>
                                                </div>
                                                <b>O.Tok</b>
                                                <p>{item.goods_name}</p>
                                                <small>수량: {item.ea}개</small>
                                            </div>
                                        </div>
                                        <div className="bottom-right">
                                            <div className="inner">
                                                <div className="bottom-right-price">
                                                    <p>{formatLocalString(item.unit_price)}<em>원</em></p>
                                                </div>
                                                <div className="bottom-right-state">
                                                    <p>{item.step_name}</p>
                                                </div>
                                                <div className="bottom-right-text">
                                                    <p>{RETURN_TYPE[item.step_name] || item.step_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}

                                    {!['주문취소', '취소완료'].includes(location.state.step_name) && (
                                        <div className="list-text">
                                            <b>반품사유: {orderDetail.items[0].return_reason}</b>
                                            <p>{orderDetail.items[0].return_memo}</p>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>

                        {/* 취소/환불정보 또는 교환정보 조건부 렌더링 */}
                        {['교환완료', '교환철회', '배송완료'].includes(location.state.step_name) ? (
                            <div>
                                <div className="title-box bb2 pb14 mb0 mt32">
                                    <h2 className="tl f18">교환 정보</h2>
                                </div>
                                <div className="board-write-box bt0">
                                    <ul>
                                        <li className="flex-lg flex-vt">
                                            <strong>원결제금액</strong>
                                            <div>
                                                <div className="price-info-box">
                                                    <strong>+ {formatLocalString(Number(orderDetail.orderInfo.return_shipping_price) || 0)}<em>원</em></strong>
                                                    <p>교환 배송비: {formatLocalString(Number(orderDetail.orderInfo.return_shipping_price) || 0)}원</p>
                                                    <p>결제수단: {orderDetail.orderInfo.payment_txt}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : ['반품신청', '반품완료'].includes(location.state.step_name) ? (
                            <div>
                                <div className="title-box bb2 pb14 mb0 mt32">
                                    <h2 className="tl f18">취소/환불정보</h2>
                                </div>
                                <div className="board-write-box bt0">
                                    <ul>
                                        <li className="flex-lg flex-vt">
                                            <strong>원결제금액</strong>
                                            <div>
                                                <div className="price-info-box">
                                                    <strong>{formatLocalString(Number(orderDetail.orderInfo.settleprice) || 0)}<em>원</em></strong>
                                                    <p>상품 금액: {formatLocalString(Number(orderDetail.orderInfo.total_price) || 0)}원</p>
                                                    <p>배송비: {formatLocalString(Number(orderDetail.orderInfo.shipping_cost) || 0)}원</p>
                                                    <p>포인트: {formatLocalString(Number(orderDetail.orderInfo.point) || 0)}원</p>
                                                    <p>쿠폰 할인: {formatLocalString(Number(orderDetail.orderInfo.coupon_sale) || 0)}원</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex-lg flex-vt">
                                            <strong>차감금액</strong>
                                            <div>
                                                <div className="price-info-box">
                                                    <strong>-{formatLocalString(Number(orderDetail.orderInfo.return_shipping_price) || 0)}<em>원</em></strong>
                                                    <p>반품 배송비: {formatLocalString(Number(orderDetail.orderInfo.return_shipping_price) || 0)}원</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex-lg flex-vt">
                                            <strong>환불금액</strong>
                                            <div>
                                                <div className="price-info-box">
                                                    <strong className="ft-active">
                                                        {formatLocalString(calculateRefund().totalRefund)}
                                                        <em className="ft-active">원</em>
                                                    </strong>
                                                    <p>포인트 환불: {formatLocalString(Number(orderDetail.orderInfo.point) || 0)}원</p>
                                                    <p>결제수단 환불: {formatLocalString(calculateRefund().paymentRefund)}원</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : ['주문취소', '취소완료'].includes(location.state.step_name) ? (
                            <div>
                                <div className="title-box bb2 pb14 mb0 mt32">
                                    <h2 className="tl f18">취소/환불정보</h2>
                                </div>
                                <div className="board-write-box bt0">
                                    <ul>
                                        <li className="flex-lg flex-vt">
                                            <strong>원결제금액</strong>
                                            <div>
                                                <div className="price-info-box">
                                                    <strong>{formatLocalString(Number(orderDetail.orderInfo.settleprice) || 0)}<em>원</em></strong>
                                                    <p>상품 금액: {formatLocalString(Number(orderDetail.orderInfo.total_price) || 0)}원</p>
                                                    <p>배송비: {formatLocalString(Number(orderDetail.orderInfo.shipping_cost) || 0)}원</p>
                                                    <p>포인트: {formatLocalString(Number(orderDetail.orderInfo.point) || 0)}원</p>
                                                    <p>쿠폰 할인: {formatLocalString(Number(orderDetail.orderInfo.coupon_sale) || 0)}원</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="flex-lg flex-vt">
                                            <strong>환불금액</strong>
                                            <div>
                                                <div className="price-info-box">
                                                    <strong className="ft-active">
                                                        {formatLocalString(calculateRefund().totalRefund)}
                                                        <em className="ft-active">원</em>
                                                    </strong>
                                                    <p>포인트 환불: {formatLocalString(Number(orderDetail.orderInfo.point) || 0)}원</p>
                                                    <p>결제수단 환불: {formatLocalString(calculateRefund().paymentRefund)}원</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : null}

                        {/* 수거지 정보 */}
                        {!['주문취소', '취소완료'].includes(location.state.step_name) && (
                        <div>
                            <div className="title-box bb2 pb14 mb0 mt32">
                                <h2 className="tl f18">수거지 정보</h2>
                            </div>
                            <div className="board-write-box bt0">
                                <ul>
                                    <li className="flex-lg flex-vt">
                                        <strong>보내시는 분</strong>
                                        <div>
                                            <p className="lh1-5">{orderDetail.orderInfo.recipient_user_name}</p>
                                        </div>
                                    </li>
                                    <li className="flex-lg flex-vt">
                                        <strong>핸드폰 번호</strong>
                                        <div>
                                            <p className="lh1-5">{orderDetail.orderInfo.recipient_cellphone}</p>
                                        </div>
                                    </li>
                                    <li className="flex-lg flex-vt">
                                        <strong>회수 주소</strong>
                                        <div>
                                            <p className="lh1-5">[{orderDetail.orderInfo.recipient_zipcode}] {orderDetail.orderInfo.recipient_address} {orderDetail.orderInfo.recipient_address_detail}</p>
                                        </div>
                                    </li>
                                    <li className="flex-lg flex-vt">
                                        <strong>회수 방법</strong>
                                        <div>
                                            <div className="button-box w75">
                                                <button className="btn btn-white h32 f12 fw4">배송조회</button>
                                            </div>
                                            <p className="mt8 lh1-5 f12 ft-lightgray">택배사에 직접 연락하지 않아도 영업일 기준 3일 이내에 방문 합니다.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}


                        <div className="button-box mt32 mt-lg-24">
                            <div className="w200 w-lg-120">
                                <Link to="/mypage/orders-cancel" className="btn btn-white">목록</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShowReturn;
