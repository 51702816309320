import React from 'react';
import {Link} from "react-router-dom";

export default function Footer() {
  return (
      <footer>
        <div className="container">
          <div className="footer-left">
            <div className="footer-left-link">
              <h2>O-Tok</h2>
              <ul>
                <li><Link to="/terms">이용약관</Link></li>
                <li className="active"><Link to="/privacy">개인정보처리방침</Link></li>
                <li><Link to="/cscenter">고객센터</Link></li>
              </ul>
            </div>
            <div className="footer-left-info">
              <p>(주)아하모먼트 대표자 이정선 서울특별시 강남구 테헤란로 <br className="is-m" />128(역삼동 823-22) 성곡빌딩 3층</p>
              <p>대표번호: 070-8027-8509</p>
              <p>사업자등록번호 326-86-03427 통신판매업 신고번호</p>
              <p>Copy righterⓒ O.Tok ALL RIGHTS RESERVED</p>
            </div>
          </div>
          <div className="footer-right">
            <ul>
              <li className="right-cate-01">
                <div className="inner">우측 영역1</div>
              </li>
              <li className="right-cate-02">
                <div className="inner">우측 영역2</div>
              </li>
              <li className="right-cate-03">
                <div className="inner">우측 영역3</div>
              </li>
            </ul>
          </div>
        </div>
      </footer>
  )
}

