import instance from "../services";
import { PRODUCT_CREATE_URL, PRODUCT_LIST_URL, PRODUCT_DETAIL_URL, PRODUCT_TYPE_URL, PRODUCT_REVIEW_DELETE_URL } from "../../utils/urls";

/**
 * 상품문의 등록
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const createInquiryList = async (params) => {
    try {
        return await instance.post(PRODUCT_CREATE_URL, params,{
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 *  상품문의, 1:1문의, 상품후기 리스트
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getInquiryList = async (params) => {
    try {
        return await instance.post(PRODUCT_LIST_URL, params);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 상품 리스트 상세
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getInquiryDetailList = async (params) => {
    try {
        return await instance.post(PRODUCT_DETAIL_URL, params);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 상품 타입 리스트
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getInquiryTypeList = async (params) => {
    try {
        return await instance.post(PRODUCT_TYPE_URL, params);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 상품 삭제
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const deleteInquiryList = async (params) => {
    try {
        return await instance.post(PRODUCT_REVIEW_DELETE_URL, params);
    } catch (error) {
        console.error(error);
        throw error;
    }
}



