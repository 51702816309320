import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { PRODUCT_CREATE_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import useUserStore from '../../../store/userStore';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';

function Create() {
    const userInfo = useUserStore();
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        subject: '',
        contents: '',
        inquiryType: '',
    });
    const [uploadImages, setUploadImages] = useState([]);
    const [imagePreview, setImagePreview] = useState([]);

    // 입력 필드 변경 핸들러
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // 파일 업로드 핸들러
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        
        if (files.length + uploadImages.length > 5) {
            alert('최대 5개의 파일만 업로드 가능합니다.');
            return;
        }

        files.forEach(file => {
            if (file.size > 10 * 1024 * 1024) {
                alert('파일 크기는 10MB를 초과할 수 없습니다.');
                return;
            }
        });

        setUploadImages(prev => [...prev, ...files]);
        
        // 이미지 미리보기 생성
        files.forEach(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(prev => [...prev, reader.result]);
            };
            reader.readAsDataURL(file);
        });
    };

    // 이미지 삭제 핸들러
    const handleRemoveImage = (index) => {
        setUploadImages(prev => prev.filter((_, i) => i !== index));
        setImagePreview(prev => prev.filter((_, i) => i !== index));
    };

    // 등록 버튼 클릭 핸들러 수정
    const handleClick = async (e) => {
        e.preventDefault();
        
        if (!formData.subject || !formData.contents || !formData.inquiryType) {
            setIsPopupLoginError('모든 필수 항목을 입력해주세요.');
            return;
        }

        const submitFormData = new FormData();
        submitFormData.append('boardid', 'mbqna');
        submitFormData.append('subject', formData.subject);
        submitFormData.append('contents', formData.contents);
        submitFormData.append('board_type', 'member');
        
        // 이미지 파일 추가
        uploadImages.forEach(file => {
            submitFormData.append('upload_images[]', file);
        });

        try {
            const response = await instance.post(PRODUCT_CREATE_URL, submitFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data.success) {
                setIsPopupLoginError('문의가 등록되었습니다.');
                setTimeout(() => {
                    navigate('/mypage/qa');
                }, 1000);
            }
        } catch (error) {
            console.error('문의 등록 실패:', error);
            setIsPopupLoginError('문의 등록에 실패했습니다.');
        }
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"1:1문의"} />
                    <div className="sub-right">
                        <div className="title-box mb14">
                            <h2 className="tl f18">1:1문의</h2>
                        </div>
                        <form onSubmit={handleClick}>
                            <div className="board-write-box">
                                <ul>
                                    <li>
                                        <strong>아이디</strong>
                                        <div>
                                            <div className="w450 col-lg-12">
                                                <div className="input-box">
                                                    <input type="text" className="h40" value={userInfo.userInfo.userid} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>문의유형</strong>
                                        <div>
                                            <div className="w450 col-lg-12">
                                                <div className="select-box">
                                                    <select 
                                                        className="h40"
                                                        name="inquiryType"
                                                        value={formData.inquiryType}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">문의유형 선택</option>
                                                        <option value="member">회원/정보</option>
                                                        <option value="order">주문/결제</option>
                                                        <option value="delivery">배송</option>
                                                        <option value="exchange">교환/반품/환불</option>
                                                        <option value="point">쿠폰/포인트</option>
                                                        <option value="event">이벤트</option>
                                                        <option value="etc">기타</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>제목</strong>
                                        <div>
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    placeholder="제목을 입력해 주세요."
                                                    className="h40"
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>내용</strong>
                                        <div>
                                            <div className="textarea-box lg">
                                                <textarea 
                                                    name="contents"
                                                    value={formData.contents}
                                                    onChange={handleChange}
                                                    placeholder="내용을 입력해 주세요."
                                                ></textarea>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>첨부파일</strong>
                                        <div>
                                            <div className="file-image-box">
                                                <ul className="file-list">
                                                    <li>
                                                        <label htmlFor="file">
                                                            파일찾기
                                                            <input 
                                                                type="file" 
                                                                name="file" 
                                                                id="file" 
                                                                multiple 
                                                                onChange={handleFileChange}
                                                                accept="image/*"
                                                            />
                                                        </label>
                                                    </li>
                                                    {imagePreview.map((preview, index) => (
                                                        <li key={index}>
                                                            <div className="preview-image" style={{padding:"0px"}}>
                                                                <img 
                                                                    src={preview} 
                                                                    alt={`미리보기 ${index + 1}`} 
                                                                />
                                                                <button 
                                                                    type="button" 
                                                                    className="delete" 
                                                                    onClick={() => handleRemoveImage(index)}
                                                                >
                                                                    삭제
                                                                </button>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="message-box">
                                                <p className="error">- 파일당 최대 10MB의 용량 제한이 있습니다.</p>
                                                <p className="ft-lightgray">- 최대 5개까지 등록 가능합니다.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="button-box mt32">
                                <div className="w200 flex-lg-1 mr8">
                                    <Link to="/mypage/qa" className="btn btn-white">
                                        취소
                                    </Link>
                                </div>
                                <div className="w200 flex-lg-1">
                                    <Link 
                                        to="#" 
                                        className="btn btn-black"
                                        onClick={handleClick}
                                    >
                                        등록
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
        </section>
    );
}

export default Create;
