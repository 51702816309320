import React, { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";
import { PHOTO_REVIEW_LIST_URL } from '../../utils/urls';
import instance from '../../services/services';

function PopupPhotoReview({isPopup, setIsPopup, view}) {

    console.log(view)

    const location = useLocation();
    const [popupActive,setPopupActive] = useState()
    const [photoReviewData, setPhotoReviewData] = useState([]);

    useEffect(() => {
        const body = document.body;

        // 팝업 상태에 따른 overflow 설정
        body.style.overflow = isPopup ? 'hidden' : 'auto';

        // 컴포넌트 언마운트 시 overflow 초기화
        return () => {
            body.style.overflow = 'auto';
        };
    }, [isPopup]);

    // 구매후기 사진 리스트
    useEffect(() => {
        const handleGetPhotoReviewList = async () => {
            const response = await instance.post(PHOTO_REVIEW_LIST_URL, {
                offset: 1,
                limit: "18"
            });
            setPhotoReviewData(response.data.data.data || []);
        }
        handleGetPhotoReviewList();
    }, []);


    useEffect(() => {
        let timeout;
        if (isPopup) {
            timeout = setTimeout(() => {
                setPopupActive(true);
            }, 100);
        } else {
            setPopupActive(false);
        }

        // 타이머 클린업
        return () => clearTimeout(timeout);
    }, [isPopup]);

    useEffect(() => {
        // 페이지 경로(location.pathname)가 변경될 때 overflow 초기화
        document.body.style.overflow = 'auto';
    }, [location]);
    
    if(isPopup)

    

    return (
        <div className='popup-box-component'>
            <div className={`box basic lg ${popupActive ? 'active' : ''}`}>
                <div className="popup-head">
                    <button type="button" onClick={() => {setIsPopup(false)}} className="close">닫기</button>
                </div>
                <div className="popup-body pb40 pb-lg-24">
                    <div className={`review-photo-box ${view ? "view" : ""}`}>
                        <h3>
                            사진 후기
                            <div className="button-box">
                                <button type="button"
                                        className="btn btn-white list h32 px16 f12 all-review">전체보기
                                </button>
                            </div>
                        </h3>
                        <div className="photo-list">
                            <ul>
                                {photoReviewData.map((photo, index) => (
                                <li key={index}>
                                    <button type="button"
                                            style={{backgroundImage:`url(${photo.new_filepath})`}}></button>
                                </li>
                                ))}
                                
                            </ul>
                        </div>
                        <div className="photo-view">
                            <div className="view-image">
                                <div className="swiper">
                                    <div className="swiper-container">
                                        <ul className="swiper-wrapper">
                                            <li className="swiper-slide"><img alt=''  src="/asset/images/img_photo.png"/>
                                            </li>
                                            <li className="swiper-slide"><img alt=''  src="/asset/images/img_photo.png"/>
                                            </li>
                                            <li className="swiper-slide"><img alt=''  src="/asset/images/img_photo.png"/>
                                            </li>
                                            <li className="swiper-slide"><img alt=''  src="/asset/images/img_photo.png"/>
                                            </li>
                                        </ul>
                                        <div className="swiper-button-prev"></div>
                                        <div className="swiper-button-next"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-content">
                                <div className="content-top">
                                    <div>
                                        <div className="score-box">
                                            <span className="active">별점 1</span>
                                            <span className="active">별점 2</span>
                                            <span className="active">별점 3</span>
                                            <span>별점 4</span>
                                            <span>별점 5</span>
                                        </div>
                                        <b>cak******</b>
                                    </div>
                                    <time>2024.09.26</time>
                                </div>
                                <div className="content-bottom">
                                    <b>[O.Tok] 국내최초 천연 산호칼슘 과일야채 세정제</b>
                                    <p>
                                        평소 농약이나 화학물질에 민감한 편인데, 이 천연 과일세정제를 사용하고 나서는 걱정 없이 과일을 먹을 수 있게 되었어요.
                                        씻고 나면 과일 표면이 매끈하고 깨끗해져서 더 신선한 느낌이 들고, 천연 성분이라 손에 닿아도 안전하다는 점이 좋습니다.
                                        이 세정제를 사용하면서 과일을 더 자주 먹게 된 것 같아요. 씻는 게 귀찮지 않고 간편하니까 금방금방 준비할 수 있거든요.
                                        베이킹소다나 식초로 씻어도 항상 뭔가 부족하다고 느꼈는데, 천연 과일세정제를 쓰고 나서는 그 걱정이 사라졌어요.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="paging-box">
                        <ul>
                            <li className="prev"><Link to="">이전</Link></li>
                            <li className="active"><Link to="">1</Link></li>
                            <li><Link to="">2</Link></li>
                            <li className="next"><Link to="">다음</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default PopupPhotoReview;
