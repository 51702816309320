import React from "react";
import { Link } from "react-router-dom";

function SidebarMenu({ activeItem }) {
    return (
        <div className="sub-left">
            <h2>고객센터</h2>
            <ul>
                <li className={activeItem === "공지사항" ? "active" : ""}>
                    <Link to="/notice" className="link">
                        공지사항
                    </Link>
                </li>
                <li className={activeItem === "Q&A" ? "active" : ""}>
                    <Link to="/qna" className="link">
                        Q&A
                    </Link>
                </li>
                <li className={activeItem === "세금계산서" ? "active" : ""}>
                    <Link to="/bill" className="link">
                        세금계산서
                    </Link>
                </li>
            </ul>
            <h2 className="mt32">About us</h2>
            <ul>
                <li className={activeItem === "회사소개" ? "active" : ""}>
                    <Link to="/intro" className="link">
                        회사소개
                    </Link>
                </li>
                <li className={activeItem === "오시는길" ? "active" : ""}>
                    <Link to="/location" className="link">
                        오시는길
                    </Link>
                </li>
                <li className={activeItem === "회원혜택" ? "active" : ""}>
                    <Link to="/benefit" className="link">
                        회원혜택
                    </Link>
                </li>
                {/* <li className={activeItem === "이용안내" ? "active" : ""}>
                    <Link to="/guide" className="link">
                        이용안내
                    </Link>
                </li> */}
            </ul>
        </div>
    );
}

export default SidebarMenu;
