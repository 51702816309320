import React, { useState, useEffect } from 'react';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { ADDRESS_LIST_URL, ADDRESS_DELETE_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber } from '../../../utils/validation';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';


function Index() {
    const navigate = useNavigate();
    const [addressList, setAddressList] = useState([]);
    console.log(addressList, ":::addressList");
    const [selectedAddresses, setSelectedAddresses] = useState([]);
    const [isPopupLoginError, setIsPopupLoginError] = useState("");


    // 배송지 리스트
    useEffect(() => {
        const fetchLikeList = async () => {
            const response = await instance.post(ADDRESS_LIST_URL);
            setAddressList(response.data.data);
        };
            fetchLikeList();
    }, []);

    // 체크박스 선택 핸들러
    const handleCheckbox = (mde_seq) => {
        setSelectedAddresses(prev => {
            if (prev.includes(mde_seq)) {
                return prev.filter(id => id !== mde_seq);
            } else {
                return [...prev, mde_seq];
            }
        });
    };

    // 배송지 삭제
    const handleDeleteAddress = async () => {
        try {
            if (selectedAddresses.length === 0) {
                setIsPopupLoginError('삭제할 주소를 선택해주세요.');
                return;
            }

            // 선택된 각 주소에 대해 삭제 요청
            for (const mde_seq of selectedAddresses) {
                const response = await instance.post(ADDRESS_DELETE_URL, { mde_seq: mde_seq });
                if (response.data.result === 'success') {
                    setAddressList(prev => prev.filter(item => item.mde_seq !== mde_seq));
                }
            }
            // 선택 목록 초기화
            setSelectedAddresses([]);
            setIsPopupLoginError('삭제되었습니다.');
            window.location.reload();
        } catch (error) {
            console.error('주소 삭제 실패:', error);
            setIsPopupLoginError('주소 삭제에 실패했습니다.');
        }
    };

    const handleChangeAddress = (item) => {
        navigate('/mypage/address/create',{
            state: {item: item}
        });
    }

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"배송주소록 관리"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">배송주소록 관리</h2>
                        </div>
                        {!addressList.length && (
                        <div className="list-none-box">
                            <p>등록된 주소가 없습니다.</p>
                        </div>
                        )}
                        <div className="address-list-box">
                            <ul>
                                {addressList.map((item, index) => (
                                <li key={item.mde_seq}>
                                    <div className="list-left">
                                        <div className="check-box f0">
                                            <input 
                                                type="checkbox" 
                                                name="check" 
                                                id={`check_${item.mde_seq}`}
                                                checked={selectedAddresses.includes(item.mde_seq)}
                                                onChange={() => handleCheckbox(item.mde_seq)}
                                            />
                                            <label htmlFor={`check_${item.mde_seq}`}>
                                                <p>선택</p>
                                            </label>
                                        </div>
                                        <div className="inner">
                                            <div className="subject">
                                                <div className="sticker-box">
                                                    {item.default_flag === 'Y' && (
                                                        <span className="red">기본</span>
                                                    )}
                                                </div>
                                                <p>{item.mde_title} ({item.recipient_name})</p>
                                            </div>
                                            <div className="content">
                                                <p>[{item.recipient_zipcode}] {item.recipient_address_street} {item.recipient_address_detail}</p>
                                                <p>{formatPhoneNumber(item.recipient_phone)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-right">
                                        <div className="button-box">
                                            <button type="button" className="btn btn-white h32 f14 fw4" onClick={()=>{handleChangeAddress(item)}}>수정</button>
                                        </div>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                        <div className="button-box mt16 flex-tj">
                            <div className="w120 flex flex-vc">
                                <button 
                                    type="button" 
                                    className="btn btn-white f14" 
                                    onClick={handleDeleteAddress}
                                >
                                    선택 주소 삭제
                                </button>
                            </div>
                            <div className="w120 flex flex-vc">
                                <button type="button" className="btn btn-black f14" onClick={()=>{navigate("/mypage/address/create")}}>배송지 등록</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopupLoginError 
                isPopup={isPopupLoginError} 
                setIsPopup={setIsPopupLoginError}
            />
        </section>
    )
}

export default Index;
