import React from 'react';
// import { Link } from 'react-router-dom';
function Show() {
    return (
        <section>
        <div className="container">
          <div className="agree-box">
            <h2>오톡 개인정보처리방침</h2>
            <ul>
              <li>
                <p>
                  (주)아하모먼트의 브랜드 ‘오톡’(이하 ‘회사’라 한다)는 이용자의
                  개인정보를 중요시하며, 정보통신망 이용촉진 및 정보보호에 관한 법률,
                  개인정보보호법에 따라 정보주체의 개인정보를 보호하고 이와 관련한
                  고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
                  개인정보 처리지침을 수립 공개합니다. <br />
                  <br />
                  총칙 
                  <br />
                  “개인정보”란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는
                  성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는
                  정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와
                  용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 회사는
                  이용자의 개인정보보호를 매우 중요시하며, 「정보통신망 이용촉진 및
                  정보보호 등에 관한 법률」, 「개인정보보호법」 및 행정안전부가 제정한
                  "개인정보보호지침" 등을 준수하고 있습니다.
                </p>
              </li>
              <li>
                <h3>제1조(수집하는 개인정보의 항목 및 수집방법)</h3>
                <p className="mb24">
                  (1)수집하는 개인정보의 항목 
                  <br />
                  회사는 최초 회원 가입 또는 서비스 이용 시 이용자로부터 아래와 같은
                  개인정보를 수집하고 있습니다. 회사는 이용자의 개인정보 수집시 서비스
                  제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.0 특히
                  계정 생성(회원 가입)을 위해 필수적으로 수집되는 개인정보는 ID,
                  비밀번호, 성명, 생년월일, CI(Connecting Information :
                  본인인증기관으로부터의 인증결과값), 법정대리인 정보(14세 미만인
                  경우에 한하여 수집됨)이고, 그 외의 정보는 개별 서비스 이용시 필요에
                  따라 고객의 동의를 받아 수집합니다.
                  <br />
                  회사는 계정 생성시 및 개별 서비스 이용시 필수적으로 필요한 항목이
                  아닌 개인정보의 수집 이용에 관한 고객의 동의 거절을 이유로 서비스
                  제공을 제한하거나 거절하지 않습니다.
                </p>
                <p className="mb24">
                  귀하께서는 필수항목 수집 이용에 대한 동의를 거부하실 수 있으나, 이는
                  서비스 제공에 필수적으로 제공되어야 하는 정보이므로, 동의를 거부하실
                  경우 회원 가입, 서비스 이용, 홈페이지 이용 등을 하실 수 없습니다.
                </p>
                <div>
                  <em>①</em>
                  <p>회원가입을 위해 필요한 개인정보</p>
                </div>
                <p className="dot">
                  휴대폰 전화번호로 가입 하는 경우 : 휴대폰 전화번호, 비밀번호, 성명,
                  성별, 생년월일, 국가정보, 중복가입확인정보(DI), 암호화된 동일인
                  식별정보(CI), 만 14세 아동의 경우 법정대리인 정보(법정대리인의 이름,
                  생년월일, CI, DI)
                  <br />
                </p>
                <div>
                  <em>②</em>
                  <p>
                    각 상품이용을 위해 필요한 정보, 상품 구매 기록 또는 상품
                    문의사항 관련 정보
                  </p>
                </div>
                <p className="dot">
                  각 서비스 이용 기록/ 구매 또는 상품 문의  관련 정보
                </p>
                <p className="dot">
                  로그데이터, 쿠키 및 웹비콘, 이용시간, 이용자가 입력한 검색어 등
                </p>
                <p className="dot mb24">
                  제품 정보 [모델정보, 무상 3년 A/S 서비스 신청 정보
                  등, 접속IP정보, 환경설정정보 등]
                </p>
                <p>
                  귀하께서는 선택항목 수집 이용에 대한 동의를 거부하실 수 있으며, 이는
                  서비스 제공에 필수적으로 제공되어야 하는 정보가 아니므로, 동의를
                  거부하시더라도 회원 가입, 서비스 이용, 홈페이지 이용 등이
                  가능합니다. 서비스 이용과정에서 선택항목 수집, 이용이 필요할 경우
                  별도로 안내하고 동의 받도록 하겠습니다.
                  <br />
                </p>
                <div>
                  <em>①</em>
                  <p>
                    회원가입 시 성명, 휴대폰번호, 유선전화번호(선택가능), 주소,
                    이메일주소
                  </p>
                </div>
                <div>
                  <em>②</em>
                  <p>뉴스레터 및 기타정보 수신동의</p>
                </div>
                <div>
                  <em>③</em>
                  <p>
                    상품구매 관련 정보 [상품명, 구입일자, 구입처, 구입가격,
                    구입방법 등]
                  </p>
                </div>
                <div>
                  <em>④</em>
                  <p>마케팅, 이벤트 내역</p>
                </div>
                <p className="dot">
                  마케팅 활동ㆍ이벤트 관련 정보[마케팅활동 내용 및 결과, 이벤트참여
                  내용 및 결과 등]
                </p>
                <p className="dot">쿠폰/이벤트코드 보유 및 사용, 경품선택/수령결과</p>
                <div>
                  <em>⑤</em>
                  <p>제휴사 내역</p>
                </div>
                <p className="dot">
                  제휴사 및 제휴가맹점 관련정보 [제휴사명, 제휴사 회원번호, 제휴사
                  회원가입일, 이벤트 참여일, 제휴사 관련 혜택 및 마케팅활동 정보 등]
                </p>
                <div>
                  <em>⑥</em>
                  <p>
                     기타 계약의 체결ㆍ유지ㆍ이행ㆍ관리ㆍ행사참여 등과 관련하여 본인이
                    제공한 정보
                  </p>
                </div>
                <p>
                  (2) 개인정보 수집방법
                  <br />
                    홈페이지, 서면양식, 팩스/전화/이메일/상담게시판 등을 통한
                  수집, 경품행사 응모 및 이벤트 참여
                  <br />
                  비회원 고객문의, 구매상담 문의 정보 등 
                  <br />
                  생성정보 수집 툴을 통한 수집
                  <br />
                  ※ 회사는 이용자께서 회사의 개인정보 수집 이용 동의서 각각의 내용에
                  대해 "동의" 또는 "동의하지 않음"을 선택할 수 있는 절차를 마련하고
                  있습니다.
                </p>
              </li>
              <li>
                <h3>제2조(개인정보의 수집 및 이용목적)</h3>
                <p>
                  회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
                  개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
                  변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할
                  예정입니다.
                  <br />
                  <br />
                  (1)홈페이지 회원 가입 및 관리 
                  <br />
                  회원 가입의사 확인, 회원제 서비스 제공에 따른 본인
                  식별 인증, 회원자격 유지관리, 제한적 본인확인제 시행에 따른
                  본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리시
                  법정대리인의 동의여부 확인, 각종 고지통지, 고충처리 등을 목적으로
                  개인정보를 처리합니다.
                  <br />
                  (2)재화 또는 서비스 제공 물품배송, 서비스 제공, 계약서 청구서
                  발송, 콘텐츠 제공, 맞춤서비스
                  제공, 본인인증, 연령인증, 요금결제 정산, 채권추심 등을 목적으로
                  개인정보를 처리합니다.
                  <br />
                  (3)비회원 구매상담 및 고객문의 응대
                </p>
              </li>
              <li>
                <h3>제3조(개인정보의 처리 및 보유기간)</h3>
                <p className="mb24">
                  (1)회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
                  정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할
                  필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간
                  동안 회원정보를 보관합니다.
                </p>
                <p>(2)각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
                <div>
                  <em>①</em>
                  <p>
                    홈페이지 회원 가입 및 관리는 홈페이지 탈퇴 시까지 보관하며, 다만,
                    다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 보관합니다.
                  </p>
                </div>
                <p>
                  (3) 재화 또는 서비스 제공 : 재화 서비스 공급완료 및 요금결제 정산
                  완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간
                  종료시까지
                </p>
                <p className="hypen">
                  계약 또는 청약철회 등에 관련 기록: 5년 (전자상거래 등에서의
                  소비자보호에 관한 법률)
                </p>
                <p className="hypen">
                  대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의
                  소비자보호에 관한 법률)
                </p>
                <p className="hypen">
                  소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의
                  소비자보호에 관한 법률)
                </p>
                <p className="hypen">
                  신용정보의 수집/처리 및 이용 등에 관한 기록: 3년 (신용정보의 이용 및
                  보호에 관한 법률)
                </p>
                <p className="hypen">
                  표시/광고에 관한 기록: 6개월 (전자상거래 등에서의 소비자보호에 관한
                  법률)
                </p>
                <p className="hypen">
                  이용자의 인터넷 등 로그기록/이용자의 접속지 추적자료: 3개월
                  (통신비밀보호법)
                </p>
                <p className="hypen">
                  그 외의 통신사실 확인자료: 12개월 (통신비밀보호법)
                </p>
              </li>
              <li>
                <h3> 제4조(개인정보의 제3자 제공)</h3>
                <p>
                  (1) 회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지
                  않습니다. 다만, 아래의 경우에는 예외로 합니다.
                  <br />
                  1.법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
                  방법에 따라 수사기관의 요구가 있는 경우
                  <br />
                  2.유료 서비스 제공에 따른 요금 정산을 위하여 필요한 경우
                  <br />
                  3.통계작성, 학술연구나 시장조사를 위해 특정 개인을 식별할 수 없는
                  형태로 가공하여 제공하는 경우
                  <br />
                  4.이용자들이 사전에 동의한 경우
                  <br />
                  <br />
                  (2) 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                  <br />
                   * 제공받는자 : (주)케이스랩
                  <br />
                </p>
                <p className="hypen">
                  제공받는자의 이용목적 :  오톡 구매정보, 문의 서비스 신청
                  회원, 전용사이트 운영 및 대행 - 제공항목 : 성명, 휴대번호, 연락처,
                  메일, 주소 등
                </p>
                <p className="hypen">보유 및 이용기간 : 목적달성시 까지</p>
              </li>
              <li>
                <h3>제5조(개인정보처리의 위탁)</h3>
                <p>
                  (1)회사는 서비스 이행을 위해 아래와 같이 개인정보 처리 업무를 외부
                  전문업체에 위탁하여 처리하고 있습니다. 개인정보 처리위탁은 개별
                  서비스 별로 그 이행을 위해 필요한 경우에 한해 각 위탁업체에 대해
                  이루어집니다.
                  <br />
                  개인정보의 처리를 위탁하는 경우에는 개인정보 보호의 안전을 기하기
                  위하여 개인정보보호 관련 지시엄수, 개인정보에 대한 비밀유지, 제3자
                  제공의 금지 및 사고시의 책임부담, 위탁기간, 처리 종료 후의
                  개인정보의 반환 또는 파기 등을 명확히 규정하고, 위탁업체가
                  개인정보를 안전하게 처리하도록 감독합니다.
                  <br />
                  업체, 위탁하는 업무의 내용이 변경될 경우, 웹사이트 공지사항(또는
                  서면 이메일,전화,SMS 등의 방법으로 개별공지)을 통하여
                  알려드리겠습니다.
                  <br />
                  <br />
                  (2) 회사는 위탁계약 체결시 관련법령에 따라 위탁업무 수행목적 외
                  개인정보 처리금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에
                  대한 관리 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
                  명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
                  있습니다.
                  <br />
                  (3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
                  개인정보 처리방침을 통하여 공개하도록 하겠습니다. .
                </p>
              </li>
              <li>
                <h3>제6조(정보주체의 권리 의무 및 행사방법)</h3>
                <p>
                  (1) 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련
                  권리를 행사할 수 있습니다.
                  <br />
                  1. 개인정보 열람요구 
                  <br />
                  2. 오류 등이 있을 경우 정정 요구 
                  <br />
                  3. 삭제요구 
                  <br />
                  4. 처리정지 요구 
                  <br />
                  (2) 제1항에 따른 권리 행사는 회사에 대해
                  서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며
                  회사는 이에 대해 지체없이 조치하겠습니다.
                  <br />
                  (3) 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
                  경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
                  이용하거나 제공하지 않습니다.
                  <br />
                  (4) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
                  자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법
                  시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                  <br />
                  (5) 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가
                  처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을
                  침해하여서는 아니됩니다.
                </p>
              </li>
              <li>
                <h3>제7조(처리하는 개인정보 항목)</h3>
                <p>
                  회사는 다음의 개인정보 항목을 처리하고 있습니다.
                  <br />
                  (1) 홈페이지 회원 가입 및 관리
                  <br />
                  필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소
                  <br />
                  (2) 재화 또는 서비스 제공
                  <br />
                  필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 이메일주소, 아이핀번호, 신용카드번호, 은행계좌정보
                  등 결제정보
                  <br />
                  선택항목 : 관심분야, 과거 구매내역
                  <br />
                  (3) 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로
                  생성되어 수집될 수 있습니다.
                  <br />
                  IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
                  <br />
                  (4) 쿠키(Cookie)의 운영
                  <br />
                  A. 회사는 회원인증을 위하여 Cookie 방식을 사용하고 있습니다.
                  Cookie방식을 사용하는 이유는 로그아웃 시에 자동으로 PC에 저장되지
                  않고 삭제되도록 되어있으므로 공공장소나 타인이 사용할 수 있는 PC를
                  사용하실 경우에는 로그인 후 서비스 이용이 끝나시면 반드시 로그
                  아웃을 해주시기 바랍니다.
                  <br />
                  B. 쿠키(cookie)는 HTTP서버가 사용자 브라우저에게 보내는 작은
                  정보데이터로서 인터넷 웹 페이지와 사용자의 컴퓨터 사이에서 정당한
                  사용자임을 확인합니다. 이 정보는 고객님의 컴퓨터의 하드디스크에
                  저장됩니다.
                  <br />
                  C. 회사는 고객님께 적합하고 보다 유용한 서비스를 제공하기 위해서
                  쿠키를 이용하여 ID에 관한 정보를 찾아 냅니다. 이러한 쿠키정보를
                  바탕으로 회원님께 보다 유용하고 편리한 맞는 서비스를 제공해
                  드립니다.
                  <br />
                  D. 쿠키는 고객님이 회사의 회원님으로 등록되어서 '로그인' 시 생성되어
                  브라우저의 종료 시나 '로그아웃' 시 만료됩니다.
                  <br />
                  E. 사용자는 쿠키에 대해서 사용여부를 선택할 수 있습니다. 웹
                  브라우저의 설정을 변경시켜 모든 쿠키를 허용할 수 있고 쿠키가
                  저장되지 않게 확인하거나 모든 쿠키의 저장을 거부할
                  경우에는 '로그인'이 필요한 일부서비스를 이용할 수 없습니다.
                </p>
              </li>
              <li>
                <h3>제8조(개인정보의 파기)</h3>
                <p>
                  (1)회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                  불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
                  <br />
                  (2)정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
                  달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
                  하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
                  보관장소를 달리하여 보존합니다.
                  <br />
                  (3)개인정보 파기의 절차 및 방법은 다음과 같습니다.
                  <br />
                  1. 파기절차 
                  <br />
                  회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
                  보호책임자의 승인을 받아 개인정보를 파기합니다.
                  <br />
                  2. 파기방법 
                  <br />
                  회사는 전자적 파일 형태로 기록 저장된 개인정보는 기록을 재생할 수
                  없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여
                  파기하며, 종이 문서에 기록 저장된 개인정보는 분쇄기로 분쇄하거나
                  소각하여 파기합니다.
                </p>
              </li>
              <li>
                <h3>제9조(기타 개인정보 취급에 관한 방침)</h3>
                <p>
                  (1) 개인정보의 안전성 확보조치
                  <br />
                  회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
                  있습니다.
                  <br />
                  1. 관리적 조치 : 내부관리계획 수립 시행, 정기적 직원 교육 등 
                  <br />
                  2. 기술적 조치 : 개인정보처리시스템 등의 접근권한
                  관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램
                  설치 
                  <br />
                  3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제 
                  <br />
                  (2) 게시물 운영 방침 
                  <br />
                  회사는 이용자의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지
                  않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지
                  아니합니다.
                  <br />
                </p>
                <p className="hypen">스팸(spam)성 게시물</p>
                <p className="hypen">
                  타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는
                  게시물
                </p>
                <p className="hypen">동의없는 타인의 신상공개 게시물</p>
                <p className="hypen">
                  회사 또는 제3자의 지적재산권 등 권리를 침해하는 내용의 게시물
                </p>
                <p className="hypen">기타 게시판 주제와 다른 내용의 게시물</p>
                <p>
                  회사는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는 타인의
                  신상 공개시 특정 부분을 삭제하거나 기호 등으로 수정하여 게시할 수
                  있으며, 다른 주제의 게시판으로 이동 가능한 내용일 경우 해당 게시물에
                  이동 경로를 밝혀 오해가 없도록 하고 있습니다.
                  <br />
                  그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.
                  <br />
                  근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게
                  있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호받기
                  어려우므로 정보 공개 전에 심사 숙고하시기 바랍니다.
                </p>
              </li>
              <li>
                <h3>제10조(개인정보처리의 위탁)</h3>
                <p>
                  (1) 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
                  처리업무를 위탁하고 있습니다.
                </p>
                <div>
                  <em>①</em>
                  <p>쇼핑몰 운영</p>
                </div>
                <p className="hypen">위탁받는 자 : (주)케이스랩</p>
                <p className="hypen">
                  위탁하는 업무의 내용 : 쇼핑몰 운영과 관련된 제반업무
                </p>
                <div>
                  <em>②</em>
                  <p>고객 배송 정보 취급을 위한 전산시스템 구축/운영</p>
                </div>
                <p className="hypen">위탁받는 자 : (주)케이스랩</p>
                <p className="hypen">
                  위탁하는 업무의 내용 : 고객 배송 정보 처리를 위한 제반시스템
                  유지/관리
                </p>
                <div>
                  <em>③</em>
                  <p>실명확인, 본인확인, 아이핀(I-Pin) 등 : NICE 평가정보</p>
                </div>
                <div>
                  <em>④</em>
                  <p>
                    신용카드, 실시간 계좌이체, 무통장입금, 에스크로서비스 등 :
                    NHN한국사이버결제 주식회사
                  </p>
                </div>
                <div>
                  <em>⑤</em>
                  <p>상품 배송 등의 물류 업무 : CJ택배, 롯데택배, 경동택배</p>
                </div>
                <div>
                  <em>⑥</em>
                  <p>
                    상품광고 및 주문안내를 위한 SMS발송대행 : 네이버클라우드 플랫폼
                    Simple & Easy Notification Service
                  </p>
                </div>
                <p>
                  (2) 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무
                  수행목적 외 개인정보 처리금지, 기술적,관리적 보호조치, 재위탁 제한,
                  수탁자에 대한 관리,감독, 손해배상 등 책임에 관한 사항을 계약서 등
                  문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
                  있습니다.
                  <br />
                  (3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
                  개인정보 처리방침을 통하여 공개하도록 하겠습니다.
                </p>
              </li>
              <li>
                <h3>제11조(개인정보 보호책임자)</h3>
                <p>
                  (1) 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
                  처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
                  같이 개인정보 보호책임자를 지정하고 있습니다.
                  <br />
                    ▶ 개인정보 보호책임자 
                  <br />
                  부서명 : 오톡 사업부 
                  <br />
                  담당자 : 이정선 
                  <br />
                  연락처 : 070-8027-8509, otok@ahamoment.co.kr
                  <br />
                  <br />
                  ▶ 개인정보 보호 담당부서 
                  <br />
                  부서명 : 오톡 사업부 
                  <br />
                  담당자 : 이정선 
                  <br />
                  연락처 : 070-8027-8509, otok@ahamoment.co.kr
                  <br />
                  <br />
                  (2) 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한
                  모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
                  개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는
                  정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
                </p>
              </li>
              <li>
                <h3>제12조(개인정보 열람청구)</h3>
                <p>
                  정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를
                  아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가
                  신속하게 처리되도록 노력하겠습니다.
                  <br />
                  <br />
                  ▶ 개인정보 열람청구 접수 처리 부서 
                  <br />
                  부서명 : 오톡 사업부 
                  <br />
                  담당자 : 이정선 연락처 : 070-8027-8509, otok@ahamoment.co.kr
                </p>
              </li>
              <li>
                <h3>제13조(권익침해 구제방법)</h3>
                <p>
                  정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
                  등을 문의하실 수 있습니다.
                  <br />
                  {`							 <아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다>`}
                  <br />
                   개인정보침해신고센터 (https://privacy.kisa.or.kr/main.do) 전화번호
                  119 
                  <br />
                  {`							 대검찰청 인터넷범죄수사센터 (www.spo.go.kr / 02-3480-3600) br>							경찰청 사이버테러대응센터 (www.cyber.go.kr/index.do / 02-392-0330)						`}
                </p>
              </li>
              <li>
                <h3>제14조 부칙</h3>
                <p>
                  (1) 이 개인정보 처리방침은 2024년 12월 12일부터 적용됩니다.
                  <br />
                  (2) 이 개인정보 처리방침은 2024년12월 12일부터 적용됩니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    )
}

export default Show;
