import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import PopupDeleteOrder from '../../../components/popupComponents/PopupDeleteOrder';
import { MYPAGE_MAIN_URL, ORDER_CANCEL_URL, ORDER_CONFIRM_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import { formatLocalString } from '../../../utils/validation';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';

function Index() {
    const [isPopup, setIsPopup] = useState(false);
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const [mypageMain, setMypageMain] = useState(null);
    console.log(mypageMain, ':::mypageMain');
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [activePeriod, setActivePeriod] = useState(""); // 선택된 기간 버튼
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOrder, setSelectedOrder] = useState({
        order_seq: '',
        item_option_seq: ''
    });
    const [confirmedOrders, setConfirmedOrders] = useState(() => {
        const saved = localStorage.getItem('confirmedOrders');
        return saved ? JSON.parse(saved) : [];
    });
    const [actionType, setActionType] = useState(""); // 추가: 현재 액션 타입을 저장

    // 날짜 계산 함수
    const calculateDate = (period) => {
        const today = new Date();
        const end = today.toISOString().split('T')[0];
        
        let start = new Date();
        switch(period) {
            case 'today':
                break;
            case '1month':
                start.setMonth(today.getMonth() - 1);
                break;
            case '3month':
                start.setMonth(today.getMonth() - 3);
                break;
            case '6month':
                start.setMonth(today.getMonth() - 6);
                break;
            case '1year':
                start.setFullYear(today.getFullYear() - 1);
                break;
            default:
                break;
        }
        return {
            start: start.toISOString().split('T')[0],
            end: end
        };
    };

    // 마이페이지 메인 리스트 조회
    const fetchMypageMain = useCallback(async () => {
        try {
            const response = await instance.post(MYPAGE_MAIN_URL, {
                sdate: startDate,
                edate: endDate,
                limit: 10,
                offset: currentPage
            });
            setMypageMain(response.data.data);
        } catch (error) {
            console.error('Error fetching my info:', error);
        }
    }, [startDate, endDate, currentPage]);

    // 초기 데이터 로드
    useEffect(() => {
        fetchMypageMain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    // 기간 버튼 클릭 핸들러
    const handlePeriodClick = (period) => {
        setActivePeriod(period);
        const dates = calculateDate(period);
        setStartDate(dates.start);
        setEndDate(dates.end);
    };

    // 날짜 직접 입력 핸들러
    const handleDateChange = (type, value) => {
        if (type === 'start') {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
        setActivePeriod(''); // 기간 버튼 선택 해제
    };

    // 페이지 클릭 핸들러 추가
    const handlePageClick = async (page) => {
        setCurrentPage(page);
        try {
            const response = await instance.post(MYPAGE_MAIN_URL, {
                s_date: startDate,
                e_date: endDate,
                limit: 15,
                offset: page
            });
            setMypageMain(response.data.data);
        } catch (error) {
            console.error("페이지 데이터 조회 실패", error);
        }
    };

    // 주문취소 클릭 핸들러
    const handleCancelClick = async (order_seq, item_option_seq) => {
        setSelectedOrder({
            order_seq: order_seq,
            item_option_seq: item_option_seq
        });
        setActionType("cancel"); // 주문 취소 액션 설정
        setIsPopupLoginError("주문을 취소하시겠습니까?");
    };

    // 팝업 확인 버튼 클릭 핸들러
    const handlePopupConfirm = async () => {
        if (actionType === "cancel") {
            try {
                const response = await instance.post(ORDER_CANCEL_URL, {
                    order_seq: selectedOrder.order_seq,
                    item_option_seq: selectedOrder.item_option_seq
                });
                
                if (response.data.success) {
                    setIsPopupLoginError('주문이 취소되었습니다.');
                    fetchMypageMain(); // 주문 목록 새로고침
                } else {
                    setIsPopupLoginError(response.data.message || '주문 취소에 실패했습니다.');
                }
            } catch (error) {
                console.error('주문 취소 실패:', error);
                setIsPopupLoginError('주문 취소 중 오류가 발생했습니다.');
            }
        }
        // 상태 초기화
        setSelectedOrder({
            order_seq: '',
            item_option_seq: ''
        });
        setActionType("");
    };

    // 팝업 닫기 핸들러
    const handlePopupClose = () => {
        setIsPopupLoginError(false);
        setActionType(""); // 액션 타입 초기화
        setSelectedOrder({
            order_seq: '',
            item_option_seq: ''
        });
    };

    // 구매확정 클릭 핸들러 수정
    const handleConfirmClick = async (order_seq, item_option_seq) => {
        try {
            const response = await instance.post(ORDER_CONFIRM_URL, {
                order_seq: order_seq,
                item_option_seq: item_option_seq
            });
            
            if (response.data.success) {
                // 구매확정된 주문을 confirmedOrders 배열과 localStorage에 추가
                const updatedConfirmedOrders = [...confirmedOrders, order_seq];
                setConfirmedOrders(updatedConfirmedOrders);
                localStorage.setItem('confirmedOrders', JSON.stringify(updatedConfirmedOrders));
                
                setIsPopupLoginError("구매확정되었습니다.");
                
                // 마이페이지 메인 리스트를 다시 불러오기
                fetchMypageMain();
            } else {
                setIsPopupLoginError(response.data.message || '구매확정에 실패했습니다.');
            }
        } catch (error) {
            console.error('구매확정 실패:', error);
            setIsPopupLoginError('구매확정 중 오류가 발생했습니다.');
        }
    };


    // myInfo 데이터가 없을경우, null 일 경우
    if (!mypageMain) {
        return (
            <section>
                <div className="container">
                    <div className="sub-box">
                        <MypageSidebarMenu />
                        <div className="sub-right">
                            <div className="loading">로딩중...</div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"주문/배송 관리"} />
                    <div className="sub-right">
                        <div className="filter-box">
                            <div className="date-select-box">
                                <ul>
                                    <li className={activePeriod === 'today' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('today')}
                                        >
                                            오늘
                                        </button>
                                    </li>
                                    <li className={activePeriod === '1month' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('1month')}
                                        >
                                            1개월
                                        </button>
                                    </li>
                                    <li className={activePeriod === '3month' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('3month')}
                                        >
                                            3개월
                                        </button>
                                    </li>
                                    <li className={activePeriod === '6month' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('6month')}
                                        >
                                            6개월
                                        </button>
                                    </li>
                                    <li className={activePeriod === '1year' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('1year')}
                                        >
                                            1년
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="date-box">
                                <div className="date">
                                    <input 
                                        type="date" 
                                        value={startDate}
                                        onChange={(e) => handleDateChange('start', e.target.value)}
                                    />
                                </div>
                                <div className="date">
                                    <input 
                                        type="date" 
                                        value={endDate}
                                        onChange={(e) => handleDateChange('end', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="button-box">
                                <button 
                                    type="button"
                                    className="btn btn-black h40 f14 fw4"
                                    onClick={fetchMypageMain}
                                >
                                    조회
                                </button>
                            </div>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt40">
                            <h2 className="tl f18">주문배송 관리</h2>
                        </div>
                        <div className="review-list-box">
                            <ul>
                            {mypageMain.map((order, index) => (
                                <li key={index}>
                                    <div className="list-top">
                                        <dl>
                                            <dd>
                                                <p>주문일자:</p>
                                                <b>{order.regist_date.split(' ')[0]}</b>
                                            </dd>
                                            <dd>
                                                <Link to={`/mypage/Order/show/${order.order_seq}/${order.items[0].item_option_seq}`} className="link">{order.order_seq}</Link>
                                            </dd>
                                        </dl>
                                        {(order.items[0].step_name === '주문접수' || order.items[0].step_name === '배송대기중') && (
                                            <div className="button-box" style={{gap:"8px"}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-white h32 f12 fw4 px16" 
                                                    onClick={() => handleCancelClick(order.order_seq, order.items[0].item_option_seq)}
                                                >
                                                    주문취소
                                                </button>
                                            </div>
                                        )}
                                        {(order.items[0].step_name === '배송중' || order.items[0].step_name === '배송완료') && 
                                            !confirmedOrders.includes(order.order_seq) && (
                                            <div className="button-box" style={{gap:"8px"}}>
                                                <Link className="btn btn-white h32 f12 fw4 px16">배송조회</Link>
                                                <Link to={`/mypage/order/createReturn/${order.order_seq}/${order.items[0].item_option_seq}`} className="btn btn-white h32 f12 fw4 px16">반품신청</Link>
                                                <button
                                                    type='button' 
                                                    className="btn btn-black h32 f12 fw4 px16"
                                                    onClick={() => handleConfirmClick(order.order_seq, order.items[0].item_option_seq)}
                                                >
                                                    구매확정
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {order.items && order.items.map((item, itemIndex) => (
                                        <div key={itemIndex} className="list-bottom">
                                            <div className="bottom-left flex-lg-vt">
                                                <img src={item.image} alt="img" />
                                                <div className="inner">
                                                    <div className="is-m mb12">
                                                        <div className="sticker-box">
                                                            <span className="red">주문접수</span>
                                                        </div>
                                                    </div>
                                                    <b>O.Tok</b>
                                                    <p>{item.goods_name}</p>
                                                    <div className="is-pc"><small>수량: {item.ea}개</small></div>
                                                </div>
                                            </div>
                                            <div className="bottom-right">
                                                <div className="inner">
                                                    <div className="bottom-right-price">
                                                        <p>{formatLocalString(item.price || item.unit_price)}<em>원</em></p>
                                                    </div>
                                                    <div className="bottom-right-state">
                                                        <p>{item.step_name}</p>
                                                        {item.step === "45" ? (
                                                            <div className="is-pc">
                                                                <div className="number-box flex-tc">
                                                                    <b>{order.delivery_company_name || 'CJ대한통운'}</b>
                                                                    <Link to="" className="link">{order.delivery_number || "681931416292"}</Link>
                                                                </div>
                                                            </div>
                                                        ) : item.step === "교환신청" && (
                                                            <div className="button-box mt12 col-12">
                                                                <Link to="" className="btn btn-white h32 f12">버튼내용</Link>
                                                                <Link to="" className="btn btn-white h32 f12">버튼내용</Link>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {item.step === "45" ? (
                                                        <>
                                                            <div className="bottom-right-text is-m">
                                                                <div className="number-box">
                                                                    <b>{order.delivery_company_name || 'CJ대한통운'}</b>
                                                                    <Link to="" className="link">{order.delivery_number || "681931416292"}</Link>
                                                                </div>
                                                            </div>
                                                            <div className="bottom-right-button">
                                                                <div className="button-box">
                                                                    <Link to="" className="btn btn-white mo">교환신청</Link>
                                                                    <Link to="" className="btn btn-white mo">반품신청</Link>
                                                                    <Link 
                                                                        to="/mypage/review/create" 
                                                                        state={{
                                                                            order_seq: order.order_seq,
                                                                            order_goods_seq: item.goods_seq,
                                                                            order_goods_name: item.goods_name,
                                                                            order_goods_image: item.image
                                                                        }} 
                                                                        className="btn btn-white"
                                                                    >
                                                                        후기작성
                                                                    </Link>
                                                                    <Link to={`/mypage/order/createExchange/${order.order_seq}/${item.item_option_seq}`} className="btn btn-white h32 f12">교환신청</Link>

                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : item.step === "교환신청" ? (
                                                        <>
                                                            <div className="bottom-right-text is-pc">
                                                                <p>-</p>
                                                            </div>
                                                            <div className="bottom-right-button is-m">
                                                                <div className="button-box">
                                                                    <Link to="" className="btn btn-white">버튼내용</Link>
                                                                    <Link to="" className="btn btn-white">버튼내용</Link>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="bottom-right-text is-pc">
                                                            <p>-</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            ))}
                            </ul>
                        </div>
                        <div className="paging-box mt32">
                            <ul>
                                <li className="first">
                                    <button type="button" onClick={() => handlePageClick(1)}>처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button" onClick={() => handlePageClick(Math.max(1, currentPage - 1))}>이전</button>
                                </li>
                                {[1, 2, 3, 4, 5].map((page) => (
                                    <li key={page} className={currentPage === page ? 'active' : ''}>
                                        <button type="button" onClick={() => handlePageClick(page)}>
                                            {page}
                                        </button>
                                    </li>
                                ))}
                                <li className="next">
                                    <button type="button" onClick={() => handlePageClick(Math.min(5, currentPage + 1))}>다음</button>
                                </li>
                                <li className="last">
                                    <button type="button" onClick={() => handlePageClick(5)}>맨끝</button>
                                </li>
                            </ul>
                        </div>
                        <PopupDeleteOrder isPopup={isPopup} setIsPopup={setIsPopup} />
                    </div>
                </div>
            </div>
            <PopupDeleteOrder isPopup={isPopup} setIsPopup={setIsPopup} />
            <PopupLoginError 
                isPopup={isPopupLoginError} 
                setIsPopup={handlePopupClose}
                onConfirm={handlePopupConfirm}
            />
        </section>
    )
}

export default Index;
