import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Swiper from "swiper";
import { EVENT_LIST_URL } from "../../utils/urls";
import instance from "../../services/services";


function Index() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state?.activeTab || 'ongoing');
    const [eventList, setEventList] = useState([]);

    useEffect(() => {
        let swiper;

        const initializeSwiper2 = () => {
            if (swiper) {
                swiper.destroy(true, true);
            }
            swiper = new Swiper(".event-box .swiper-container", {
                loop: true,
                spaceBetween: 10,
                speed: 1500,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                observer: true,
                observeParents: true,
                pagination: {
                    el: ".event-box .swiper-pagination",
                },
            });
        };

        initializeSwiper2();

        return () => {
            if (swiper) swiper.destroy(true, true);
        };
    }, []);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const response = await instance.post(EVENT_LIST_URL, {
                    ing_date_check: activeTab === 'ongoing' ? 'Y' : 'N',
                    offset: 1,
                    limit: ""
                });
                console.log(response.data.data.data, '::restpnezdaszdasdasd');
                setEventList(response.data.data.data || []);
            } catch (error) {
                console.error('이벤트 데이터 로딩 실패:', error);
            }
        };

        fetchEventData();
    }, [activeTab]);

    useEffect(() => {
        if (location.state?.activeTab) {
            setActiveTab(location.state.activeTab);
        }
    }, [location.state]);

    return (
        <section>
            <div className="event-box">
                <div className="event-banner">
                    <div className="swiper-container">
                        <ul className="swiper-wrapper">
                            <li className="swiper-slide">
                                <img src="/asset/images/img_event_banner.png" className="is-pc" alt="Event Banner" />
                                <img src="/asset/images/img_event_banner_m.png" className="is-m" alt="Event Banner Mobile" />
                            </li>
                            <li className="swiper-slide">
                                <img src="/asset/images/img_event_banner.png" className="is-pc" alt="Event Banner" />
                                <img src="/asset/images/img_event_banner_m.png" className="is-m" alt="Event Banner Mobile" />
                            </li>
                            <li className="swiper-slide">
                                <img src="/asset/images/img_event_banner.png" className="is-pc" alt="Event Banner" />
                                <img src="/asset/images/img_event_banner_m.png" className="is-m" alt="Event Banner Mobile" />
                            </li>
                        </ul>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
                <div className="title-box mb16">
                    <h2>EVENT</h2>
                </div>
                <div className="event-tab">
                    <div className="container">
                        <ul>    
                            <li>
                                <Link 
                                    className={`link ${activeTab === 'ongoing' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTab('ongoing');
                                    }}
                                    style={{
                                        borderBottom: activeTab === 'ongoing' ? '2px solid #C62D26' : 'none',
                                        color: activeTab === 'ongoing' ? '#C62D26' : '#777777'
                                    }}
                                >
                                    진행중 이벤트
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    className={`link ${activeTab === 'ended' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTab('ended');
                                    }}
                                    style={{
                                        borderBottom: activeTab === 'ended' ? '2px solid #C62D26' : 'none',
                                        color: activeTab === 'ended' ? '#C62D26' : '#777777'
                                    }}
                                >
                                    종료된 이벤트
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="event-list">
                    <div className="container">
                        <ul className="list">
                            {eventList.map((event, index) => (
                                <li key={index}>
                                    <Link className="inner" to="/events/show">
                                        <div
                                            className="list-image"
                                            style={{
                                                backgroundImage: `url('${event.image_url}')`
                                            }}
                                        ></div>
                                        <div className="list-content">
                                            <p>{event.event_title}</p>
                                            <time>{event.start_date.split(' ')[0]} ~ {event.end_date.split(' ')[0]}</time>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Index;
