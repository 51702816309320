import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import PopupDeleteOrder from '../../../components/popupComponents/PopupDeleteOrder';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';
import { ORDER_DETAIL_URL, ORDER_CANCEL_URL, ORDER_CONFIRM_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import { formatLocalString } from '../../../utils/validation';

function Show() {
    const [isPopup, setIsPopup] = useState(false)
    const [isPopupLoginError, setIsPopupLoginError] = useState("");
    const [selectedOrder, setSelectedOrder] = useState({
        order_seq: '',
        item_option_seq: ''
    });
    const { order_seq, item_option_seq } = useParams();
    const [orderDetail, setOrderDetail] = useState(null);
    const [confirmedOrders, setConfirmedOrders] = useState(() => {
        const saved = localStorage.getItem('confirmedOrders');
        return saved ? JSON.parse(saved) : [];
    });
    const [actionType, setActionType] = useState("");



    // 주문금액과 결제금액을 계산하는 함수
    const calculateOrderAmount = () => {
        if (!orderDetail?.orderInfo) return '0';
        
        const totalPrice = Number(orderDetail.orderInfo.total_price || 0);
        const shippingCost = Number(orderDetail.orderInfo.shipping_cost || 0);
        
        // origin_order_seq 있는 경우 total_price만 반환
        if (orderDetail.orderInfo.origin_order_seq) {
            return formatLocalString(totalPrice);
        }
        
        // 일반적인 경우 total_price + shipping_cost
        return formatLocalString(totalPrice + shippingCost);
    };

    useEffect(()=> {
        const fetchOrderDetail = async () => {
            try {
                const response = await instance.post(ORDER_DETAIL_URL, {
                    order_seq: order_seq,
                    item_option_seq: item_option_seq
                });
                setOrderDetail(response.data.data);
            } catch (error) {
                console.error("주문 상세 조회 실패", error);
            }
        }
        fetchOrderDetail();
    }, [order_seq, item_option_seq]);


    // 주문 취소 클릭
    const handleCancelClick = (order_seq, item_option_seq) => {
        setSelectedOrder({
            order_seq: order_seq,
            item_option_seq: item_option_seq
        });
        setActionType("cancel");
        setIsPopupLoginError("주문을 취소하시겠습니까?");
    };

    // 팝업 확인 버튼 클릭 핸들러
    const handlePopupConfirm = async () => {
        if (actionType === "cancel") {
            try {
                const response = await instance.post(ORDER_CANCEL_URL, {
                    order_seq: selectedOrder.order_seq,
                    item_option_seq: selectedOrder.item_option_seq
                });
                
                if (response.data.success) {
                    alert('주문이 취소되었습니다.');
                    window.location.reload();
                } else {
                    alert(response.data.message || '주문 취소에 실패했습니다.');
                }
            } catch (error) {
                console.error('주문 취소 실패:', error);
                alert('주문 취소 중 오류가 발생했습니다.');
            }
        }
        setIsPopupLoginError(false);
        setActionType("");
    };

    // 팝업 닫기 핸들러
    const handlePopupClose = () => {
        setIsPopupLoginError(false);
        setActionType("");
        setSelectedOrder({
            order_seq: '',
            item_option_seq: ''
        });
    };

    // 구매확정 클릭 핸들러 수정
    const handleConfirmClick = async (order_seq, item_option_seq) => {
        try {
            const response = await instance.post(ORDER_CONFIRM_URL, {
                order_seq: order_seq,
                item_option_seq: item_option_seq
            });
            
            if (response.data.success) {
                // 구매확정된 주문을 confirmedOrders 배열과 localStorage에 추가
                const updatedConfirmedOrders = [...confirmedOrders, order_seq];
                setConfirmedOrders(updatedConfirmedOrders);
                localStorage.setItem('confirmedOrders', JSON.stringify(updatedConfirmedOrders));
                
                setIsPopupLoginError("구매확정되었습니다.");
                
                // 마이페이지 메인 리스트를 다시 불러오기
                // fetchOrderDetail();
            } else {
                setIsPopupLoginError(response.data.message || '구매확정에 실패했습니다.');
            }
        } catch (error) {
            console.error('구매확정 실패:', error);
            setIsPopupLoginError('구매확정 중 오류가 발생했습니다.');
        }
    };

    // 데이터가 없을 때
    if (!orderDetail) {
        return (
            <section>
                <div className="container">
                    <div className="sub-box">
                        <MypageSidebarMenu activeItem={"취소/교환/반품 관리"} />
                        <div className="sub-right">
                            <div className="list-none-box">
                                <p>주문 상세 정보를 찾을 수 없습니다.</p>
                            </div>
                            <div className="button-box mt32 mt-lg-24">
                                <div className="w200 w-lg-120">
                                    <Link to="/mypage/order" className="btn btn-white">목록</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"주문/배송 관리"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">주문상세 내역</h2>
                        </div>
                        <div className="review-list-box">
                            <ul>
                                <li>
                                    <div className="list-top">
                                        <dl>
                                            <dd>
                                                <p>주문일자:</p>
                                                <b>{orderDetail.orderInfo.regist_date.split(' ')[0]}</b>
                                            </dd>
                                            <dd>
                                                <Link to="" className="link">{orderDetail.orderInfo.order_seq}</Link>
                                            </dd>
                                        </dl>
                                        <div className="button-box">
                                            {/* {orderDetail.items.map((item, index) => ( */}
                                                <div style={{display: 'flex', gap: '8px'}}>
                                                    {(orderDetail.orderInfo.step_txt === '주문접수' || orderDetail.orderInfo.step_txt === '배송대기중') && (
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-white h32 f12 fw4 px16"
                                                            onClick={() => handleCancelClick(orderDetail.orderInfo.order_seq, orderDetail.items[0].item_option_seq)}
                                                        >
                                                            주문취소
                                                        </button>
                                                    )}
                                                    {orderDetail.orderInfo.step_txt === '배송중' && (
                                                        <>
                                                            <Link to="" className="btn btn-white h32 f12 fw4 px16">배송조회</Link>
                                                            <button 
                                                                type='button' 
                                                                className="btn btn-black h32 f12 fw4 px16"
                                                            >
                                                                구매확정
                                                            </button>
                                                        </>
                                                    )}
                                                    {orderDetail.orderInfo.step_txt === '배송완료' && (
                                                        <>
                                                            <Link to="" className="btn btn-white h32 f12 fw4 px16">배송조회</Link>
                                                            <button 
                                                                type='button' 
                                                                className="btn btn-black h32 f12 fw4 px16"
                                                                onClick={() => handleConfirmClick(orderDetail.orderInfo.order_seq, orderDetail.items[0].item_option_seq)}
                                                            >
                                                                구매확정
                                                            </button>
                                                            <Link 
                                                                to={`/mypage/order/createReturn/${orderDetail.orderInfo.order_seq}/${item_option_seq}`} 
                                                                className="btn btn-white h32 f12 fw4 px16"
                                                            >
                                                                반품신청
                                                            </Link>
                                                        </>
                                                    )}
                                                </div>
                                            {/* ))} */}
                                        </div>
                                    </div>

                                    {orderDetail.items.map((item, index) => (
                                    <div className="list-bottom" key={index}>
                                        <div className="bottom-left flex-lg-vt">
                                            <img src={item.image} alt="상품 이미지" />
                                            <div className="inner">
                                                <div className="is-m mb12">
                                                    <div className="sticker-box">
                                                        <span className="red">주문접수</span>
                                                    </div>
                                                </div>
                                                <b>O.Tok</b>
                                                <p>{item.goods_name}</p>
                                                <div className="is-pc"><small>수량: {item.ea}개</small></div>
                                            </div>
                                        </div>
                                        <div className="bottom-right">
                                            <div className="inner">
                                                <div className="bottom-right-price">
                                                    <p>{formatLocalString(item.unit_price)}<em>원</em></p>
                                                </div>
                                                <div className="bottom-right-state">
                                                    <p>{item.step_name}</p>
                                                    {(item.step_name === '배송중' || item.step_name === '배송완료') && (
                                                        <div className="is-pc">
                                                            <div className="number-box flex-tc">
                                                                <b>{item.delivery_company || 'CJ대한통운'}</b>
                                                                <Link to="" className="link">{item.tracking_number || "681931416292"}</Link>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="bottom-right-text">
                                                    <p className="is-pc">-</p>
                                                    {(item.step_name === '배송중' || item.step_name === '배송완료') && (
                                                        <div className="is-m">
                                                            <div className="number-box">
                                                                <b>{item.delivery_company || 'CJ대한통운'}</b>
                                                                <Link to="" className="link">{item.tracking_number || '681931416292'}</Link>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="bottom-right-button">
                                                    <div className="button-box">
                                                        {item.step_name === '배송완료' && (
                                                            <>
                                                                <Link to="" className="btn btn-white mo">교환신청</Link>
                                                                <Link to="" className="btn btn-white mo">반품신청</Link>
                                                                <Link 
                                                                    to="/mypage/review/create" 
                                                                    state={{
                                                                        order_seq: item.order_seq,
                                                                        order_goods_seq: item.goods_seq,
                                                                        order_goods_name: item.goods_name,
                                                                        order_goods_image: item.image
                                                                    }} 
                                                                    className="btn btn-white"
                                                                >
                                                                    후기작성
                                                                </Link>
                                                                <Link 
                                                                    to={`/mypage/order/createExchange/${item.order_seq}/${item.item_option_seq}`} 
                                                                    className="btn btn-white"
                                                                >
                                                                    교환신청
                                                                </Link>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">결제정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg flex-vt">
                                    <strong>주문금액</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong>
                                                {calculateOrderAmount()}<em>원</em>
                                            </strong>
                                            <p>상품 금액: {formatLocalString(Number(orderDetail?.orderInfo?.total_price || 0))}원</p>
                                            <p>배송비: {formatLocalString(Number(orderDetail?.orderInfo?.shipping_cost || 0))}원</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>할인금액</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong>-{formatLocalString(Number(orderDetail.orderInfo.return_shipping_price) + Number(orderDetail.orderInfo.point))}<em>원</em></strong> 
                                            <p>포인트: {orderDetail?.orderInfo?.point ? formatLocalString(Number(orderDetail.orderInfo.point)): '0'}원</p>
                                            <p>쿠폰할인: {orderDetail?.orderInfo?.coupon_sale ? formatLocalString(Number(orderDetail.orderInfo.coupon_sale)): '0'}</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>결제금액</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong className="ft-active">
                                                {calculateOrderAmount()}<em className="ft-active">원</em>
                                            </strong>
                                            <p>결제수단: {orderDetail.orderInfo.payment_txt} (입금자명: 강승혜)</p>
                                            <p>결제일시: {orderDetail.orderInfo.payment_flag === "Y" ? '결제완료' : '입금대기'}</p>
                                        </div>
                                    </div>
                                    {/* origin_order_seq 가 있을경우에는 결제금액과 주문금액을 맞춰야함 total_price + shipping_cost 이 금액과 assName="ft-active">14,000 이 금액을 맞춰야함 */}
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">주문자 정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg flex-vt">
                                    <strong>주문하시는 분</strong>
                                    <div>
                                        <p className="lh1-5">{orderDetail.orderInfo.user_name}</p>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>핸드폰 번호</strong>
                                    <div>
                                        <p className="lh1-5">{orderDetail.orderInfo.mem_phone}</p>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>이메일 주소</strong>
                                    <div>
                                        <p className="lh1-5">{orderDetail.orderInfo.email}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">배송지 정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg flex-vt">
                                    <strong>받으시는분 분</strong>
                                    <div>
                                        <p className="lh1-5">{orderDetail.orderInfo.recipient_user_name}</p>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>핸드폰 번호</strong>
                                    <div>
                                        <p className="lh1-5">010-1234-5678</p>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>배송 주소</strong>
                                    <div>
                                        <p className="lh1-5">[{orderDetail.orderInfo.recipient_zipcode}] {orderDetail.orderInfo.recipient_address} {orderDetail.orderInfo.recipient_address_detail}</p>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>배송 메세지</strong>
                                    <div>
                                        <p className="lh1-5">{orderDetail.orderInfo.recipient_memo || '전송 메시지가 없습니다.'}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-box mt40 mt-lg-24">
                            <div className="w200 w-lg-120 flex flex-vc">
                                <Link to="/mypage/order" type="button" className="btn btn-white f14">목록</Link>
                            </div>
                        </div>
                        <PopupDeleteOrder isPopup={isPopup} setIsPopup={setIsPopup} />
                        <PopupLoginError 
                            isPopup={isPopupLoginError} 
                            setIsPopup={handlePopupClose}
                            onConfirm={handlePopupConfirm}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Show;
