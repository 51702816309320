import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

function PopupShare({ isPopup, setIsPopup }) {
    const location = useLocation();
    const [popupActive, setPopupActive] = useState();
    const currentUrl = window.location.href; // 현재 페이지 URL

    // URL 복사하기
    const handleCopyUrl = async () => {
        try {
            await navigator.clipboard.writeText(currentUrl);
            alert('URL이 복사되었습니다.');
            setIsPopup(false); // 복사 후 팝업 닫기
        } catch (err) {
            // 클립보드 API를 지원하지 않는 브라우저를 위한 대체 방법
            const textarea = document.createElement('textarea');
            textarea.value = currentUrl;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            alert('URL이 복사되었습니다.');
            setIsPopup(false); // 복사 후 팝업 닫기
        }
    };

    useEffect(() => {
        const body = document.body;
        body.style.overflow = isPopup ? 'hidden' : 'auto';
        return () => {
            body.style.overflow = 'auto';
        };
    }, [isPopup]);

    useEffect(() => {
        let timeout;
        if (isPopup) {
            timeout = setTimeout(() => {
                setPopupActive(true);
            }, 100);
        } else {
            setPopupActive(false);
        }
        return () => clearTimeout(timeout);
    }, [isPopup]);

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, [location]);

    if (isPopup)
        return (
            <div className="popup-box-component no-script">
                <div className={`box sm pt0 px0 ${popupActive ? 'active' : ''}`}>
                    <div className="popup-head">
                        <button type="button" className="close" onClick={()=>{setIsPopup(false)}}>닫기</button>
                    </div>
                    <div className="text-box">
                        <p className="tc">공유하기</p>
                    </div>
                    <div className="share-box mt24">
                        <ul>
                            <li>
                                <button type="button" className="btn kakao"><p>카카오톡</p></button>
                            </li>
                            <li>
                                <button 
                                    type="button" 
                                    className="btn copy"
                                    onClick={handleCopyUrl}
                                >
                                    <p>URL 복사</p>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
}

export default PopupShare;