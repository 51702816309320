// 토큰 요청 URL
export const TOKEN_URL = "/api/auth";

// 로그인 요청 URL
export const LOGIN_INFO_URL =  "/api/login";

// 회원가입 요청 URL
export const JOIN_INFO_URL = "/api/register";

// 아이디 사용가능한지 체크
export const ID_CHECK_URL = "/api/myIdCheck";

// 아이디 찾기
export const ID_FIND_URL = "/api/idFind";

// 비밀번호 찾기
export const PASSWORD_FIND_URL = "/api/passwordFind";

// 비밀번호 재설정
export const PASSWORD_CHANGE_URL = "/api/passwordChange";

// MAIN 페이지 리스트
export const MAIN_LIST_URL = "api/main/banner/list";

// 상품문의 등록
export const PRODUCT_CREATE_URL = "api/board/common/upload";

// 상품문의, 1:1문의, 상품후기 리스트
export const PRODUCT_LIST_URL = "api/board/common/list";

// 상품 상세 리스트
export const PRODUCT_DETAIL_URL = "api/board/common/detail";

// 상품 타입 리스트
export const PRODUCT_TYPE_URL = "api/board/common/type";



// 공지사항 API
export const NOTICE_LIST_URL = "api/board/notice/list"; // 공지사항 리스트
export const NOTICE_DETAIL_URL = "api/board/notice/detail"; // 공지사항 상세

// FAQ API
export const FAQ_LIST_URL = "api/board/faq/list"; // FAQ 리스트
export const FAQ_DETAIL_URL = "api/board/faq/detail"; // FAQ 상세
export const FAQ_TYPE_URL = "api/board/faq/type"; // FAQ 타입 리스트

// 1:1 문의 API
export const INQUIRY_DETAIL_URL = "api/board/common/detail"; // 1:1 문의 상세
export const INQUIRY_TYPE_URL = "api/board/common/type"; // 1:1 문의 타입 리스트
export const INQUIRY_CREATE_URL = "api/board/common/upload"; // 1:1 문의 등록
export const INQUIRY_UPDATE_URL = "api/board/common/modify"; // 1:1 문의 수정
export const INQUIRY_DELETE_URL = "api/board/common/delete"; // 1:1 문의 삭제

// 세금계산서 API
export const TAX_LIST_URL = "api/board/tax/list"; // 세금계산서 리스트
export const TAX_DETAIL_URL = "api/board/tax/detail"; // 세금계산서 상세
export const TAX_CREATE_URL = "api/board/tax/upload"; // 세금계산서 등록
export const TAX_UPDATE_URL = "api/board/tax/modify"; // 세금계산서 수정
export const TAX_DELETE_URL = "api/board/tax/delete"; // 세금계산서 삭제

// 상품후기 API
export const PRODUCT_REVIEW_DETAIL_URL = "api/board/credit/detail"; // 상품후기 상세
export const PRODUCT_REVIEW_CREATE_URL = "api/board/common/upload"; // 상품후기 등록
export const PRODUCT_REVIEW_UPDATE_URL = "api/board/common/modify"; // 상품후기 수정
export const PRODUCT_REVIEW_DELETE_URL = "api/board/common/delete"; // 상품후기 삭제
export const PRODUCT_REVIEW_LIST_URL = "api/board/common/review"; // 작성 가능한 후기 리스트


// 상품페이지 API
export const PRODUCT_LISTPAGE_URL = "api/getGoodsList"; // 제품 리스트
export const PRODUCT_DETAIL_INFO_URL = "api/goods/detail"; // 제품 상세
export const PRODUCT_REVIEW_URL = "api/main/goods/like"; // 상품 좋아요 및 취소

// 장바구니 API
export const CART_ADD_URL = "api/goods/cart"; // 장바구니 추가
export const CART_LIST_URL = "api/goods/cartList"; // 장바구니 리스트


// 배송지 주소 리스트 API
export const ADDRESS_LIST_URL = "api/mypage/deliveryList"; // 배송지 주소 리스트
export const ADDRESS_CREATE_URL = "api/mypage/deliveryRegist"; // 배송지 주소 등록
export const ADDRESS_UPDATE_URL = "api/mypage/deliveryModify"; // 배송지 주소 수정
export const ADDRESS_DELETE_URL = "api/mypage/deliveryDelete"; // 배송지 주소 삭제

// 결제금액 계산 API
export const ORDER_PRICE_URL = "api/pay/calculate"; // 결제금액 계산

// 쿠폰 리스트 API
export const COUPON_LIST_URL = "api/mypage/couponList"; // 쿠폰 리스트

// 결제 요청 API
export const ORDER_REQUEST_URL = "api/pay/request"; // 결제 요청
export const ORDER_RESULT_URL = "api/pay/complete"; // 결제 완료페이지

// 점유인증 URL
export const INFORMATION_VERIFY_URL = "api/certification";

// 점유인증 확인
export const CERTIFICATION_CHECK = "api/certification_check";

// 이벤트 리스트 URL
export const EVENT_LIST_URL = "api/board/event/list";

// 작성한 사진 후기 리스트 URL
export const PHOTO_REVIEW_LIST_URL = "api/board/common/reviewImage";

// 관심 상품 리스트 URL
export const LIKE_LIST_URL = "api/mypage/goodsLikeList";

// 내 정보 URL
export const MY_INFO_URL = "api/myInfo";

// 마이페이지 메인 URL
export const MYPAGE_MAIN_URL = "api/mypage/myOrderList";

// 포인트 리스트 URL
export const POINT_LIST_URL = "api/mypage/pointTypeList";

// 나의 취소/교환/반품 리스트 URL
export const CANCEL_LIST_URL = "api/mypage/myOrderCancelList";

// 주문/배송/취소/교환/반품 관리 상세
export const ORDER_DETAIL_URL = "api/mypage/myOrderDetail";

// 주문 취소 URL
export const ORDER_CANCEL_URL = "api/pay/payCancel";

// 구매 확전 URL
export const ORDER_CONFIRM_URL = "api/mypage/payOrderDecide";

// 반품 신청 URL
export const ORDER_RETURN_URL = "api/mypage/payReturn";

// 교환 신청 URL
export const ORDER_EXCHANGE_URL = "api/mypage/payExchange";

// 교환 철회 URL
export const ORDER_EXCHANGE_CANCEL_URL = "api/mypage/payReturnCancel";

// 비밀번호 확인 URL
export const PASSWORD_CHECK_URL = "api/passwordConfirm";

// 내 정보 수정 URL
export const MY_INFO_UPDATE_URL = "api/myInfoUpdate";