import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";

function CreateStep1() {
    const navigate = useNavigate();
    const [mailing, setMailing] = useState('N');
    const [sms, setSms] = useState('N');
    const [checkboxes, setCheckboxes] = useState({
        all: false,
        terms: false,
        privacy: false,
        marketing: false
    });

    // 모든 필수 항목이 체크되었는지 확인
    const isAllChecked = checkboxes.terms && checkboxes.privacy;

    // 개별 체크박스 핸들러
    const handleSingleCheck = (name) => {
        setCheckboxes(prev => {
            const newCheckboxes = {
                ...prev,
                [name]: !prev[name]
            };
            // 마케팅 동의 체크박스 처리
            if (name === 'marketing') {
                setMailing(!prev.marketing ? 'Y' : 'N');
                setSms(!prev.marketing ? 'Y' : 'N'); 
            }
            return newCheckboxes;
        });
    };

    // 전체 동의 핸들러
    const handleAllCheck = () => {
        const newValue = !checkboxes.all;
        setCheckboxes({
            all: newValue,
            terms: newValue,
            privacy: newValue,
            marketing: newValue
        });
        setMailing(newValue ? 'Y' : 'N');
        setSms(newValue ? 'Y' : 'N');
    };

    const handleNextStep = () => {
        // 필수 항목이 모두 체크되었을 때만 다음 페이지로 이동
        if (isAllChecked) {
            navigate("/users/createStep3", { state: { mailing, sms } });
        }
    };

    return (
        <section>
            <div className="container">
                <div className="member-box">
                    <div className="title-box">
                        <h2>회원가입</h2>
                    </div>
                    <form>
                        <div className="check-total-box">
                            <div className="title-box">
                                <h3>서비스 이용약관 동의</h3>
                            </div>
                            <div className="check-total">
                                <div className="check-box">
                                    <input 
                                        type="checkbox" 
                                        id="checkAll" 
                                        checked={checkboxes.all}
                                        onChange={handleAllCheck}
                                    />
                                    <label htmlFor="checkAll"><p>전체 동의 (선택 정보 포함)</p></label>
                                </div>
                            </div>
                            <div className="check-list">
                                <div className="check-box mb16">
                                    <input 
                                        type="checkbox" 
                                        id="terms"
                                        checked={checkboxes.terms}
                                        onChange={() => handleSingleCheck('terms')}
                                    />
                                    <label htmlFor="terms">
                                        <p>[필수] 이용약관 동의</p>
                                        <Link 
                                            to="/terms" 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                        >보기</Link>
                                    </label>
                                </div>
                                <div className="check-box mb16">
                                    <input 
                                        type="checkbox" 
                                        id="privacy"
                                        checked={checkboxes.privacy}
                                        onChange={() => handleSingleCheck('privacy')}
                                    />
                                    <label htmlFor="privacy">
                                        <p>[필수] 개인정보 수집 및 이용 동의</p>
                                        <Link 
                                            to="/privacy" 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                        >보기</Link>
                                    </label>
                                </div>
                                <div className="check-box">
                                    <input 
                                        type="checkbox" 
                                        id="marketing"
                                        checked={checkboxes.marketing}
                                        onChange={() => handleSingleCheck('marketing')}
                                    />
                                    <label htmlFor="marketing">
                                        <p>[선택] 쇼핑정보 수신 동의</p>
                                        <Link 
                                            to="/receive" 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                        >보기</Link>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="button-box mt24">
                            <button 
                                type="button" 
                                className={`btn btn-black ${!isAllChecked ? 'disabled' : ''}`}
                                onClick={handleNextStep}
                                style={{
                                    background: isAllChecked ? '#000' : '#AAA',
                                    color: isAllChecked ? '#FFFFFF' : '#FFF',
                                    cursor: isAllChecked ? 'pointer' : 'default'
                                }}
                            >
                                동의하고 가입하기
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default CreateStep1;
