import React, { useState, useEffect } from 'react';
import SidebarMenu from "../../components/SidebarMenu";
import { Link } from 'react-router-dom';
import { NOTICE_LIST_URL } from '../../utils/urls';
import instance from '../../services/services';

function Index() {
    const [keyword, setKeyword] = useState(''); // 검색어 상태값
    const [topData, setTopData] = useState([]);  // 탑데이터 상태값
    console.log(topData, ':::topData');
    const [noticeList, setNoticeList] = useState([]);
    console.log(noticeList, ':::noticeList');

    // 데이터 로딩 함수
    const fetchMainData = async (searchKeyword = '') => {
        try {
            const response = await instance.post(NOTICE_LIST_URL, {
                boardid: "notice",
                keyword: searchKeyword,
                offset: 1,
                limit: 5
            });
            setTopData(response.data.data.topData);
            setNoticeList(response.data.data.data);
        } catch (error) {
            console.error('메인 페이지 데이터 로딩 실패:', error);
        }
    };

    // 초기 데이터 로딩
    useEffect(() => {
        fetchMainData();
    }, []);

    // 검색어 입력 핸들러
    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
    };

    // 검색 버튼 클릭 핸들러
    const handleSearch = () => {
        fetchMainData(keyword);
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="공지사항"/>
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>공지사항</h2>
                        </div>
                        <div className="search-box">
                            <form onSubmit={(e) => e.preventDefault()}>
                                    <input 
                                        type="text" 
                                        placeholder="제목, 내용 검색"
                                        value={keyword}
                                        onChange={handleKeywordChange}
                                    />
                                    <button 
                                        type="button" 
                                        className="search"
                                        onClick={handleSearch}
                                    >
                                        검색
                                    </button>
                            </form>
                        </div>
                        <div className="board-list-box">
                            <ul>
                                {topData && topData.subject && (
                                    <li>
                                            <Link 
                                                to="/notice/show" 
                                                state={{ Data: topData.b_seq }} 
                                                className="inner"
                                            >
                                            <div className="list-left">
                                                <div className="subject">
                                                    <b className="notice">공지</b>
                                                    <p>[O.Tok] {topData.subject}</p>
                                                </div>
                                            </div>
                                            <div className="list-right">
                                                <div className="date">
                                                    <time>{topData?.modify_date?.split(' ')[0] || '-'}</time>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                )}
                                {noticeList.map((notice) => (
                                    <li key={notice.b_seq}>
                                            <Link 
                                                to="/notice/show" 
                                                state={{ b_seq: notice.b_seq }} 
                                                className="inner"
                                            >
                                            <div className="list-left">
                                                <div className="subject">
                                                    <p>[O.Tok] {notice.subject}</p>
                                                </div>
                                            </div>
                                            <div className="list-right">
                                                <div className="date">
                                                    <time>{notice.regist_date.split(' ')[0]}</time>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}



                                {/* <li>
                                    <a href="/notice/show" className="inner">
                                        <div className="list-left">
                                            <div className="subject">
                                                <p>[O.Tok] 새롭게 변경된 환불 및 교환 정책 변경 안내</p>
                                            </div>
                                        </div>
                                        <div className="list-right">
                                            <div className="date">
                                                <time>2024.09.26</time>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="/notice/show" className="inner">
                                        <div className="list-left">
                                            <div className="subject">
                                                <p>[O.Tok] 새롭게 변경된 환불 및 교환 정책 변경 안내</p>
                                            </div>
                                        </div>
                                        <div className="list-right">
                                            <div className="date">
                                                <time>2024.09.26</time>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <Link className="inner">
                                        <div className="list-left">
                                            <div className="subject">
                                                <p>[O.Tok] 새롭게 변경된 환불 및 교환 정책 변경 안내</p>
                                            </div>
                                        </div>
                                        <div className="list-right">
                                            <div className="date">
                                                <time>2024.09.26</time>
                                            </div>
                                        </div>
                                    </Link>
                                </li> */}




                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Index;
