import React, { useState, useEffect } from 'react';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { COUPON_LIST_URL } from '../../../utils/urls';
import instance from '../../../services/services';

function Index() {
    const [couponList, setCouponList] = useState([]);
    console.log(couponList, "::::couponList");

    useEffect(()=> {
        const fetchCouponList = async () => {
            try{
                const response = await instance.post(COUPON_LIST_URL, {
                    offset: 1
                });
                setCouponList(response.data.data);
            } catch (error) {
                console.error('쿠폰 리스트 조회 실패:', error);
            }
        };
        fetchCouponList();
    }, []);

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"쿠폰"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">쿠폰</h2>
                        </div>
                        {couponList.length === 0 &&(
                        <div className="list-none-box">
                            <p>사용가능한 쿠폰이 없습니다.</p>
                        </div>
                        )}
                        <div className="coupon-list-box mt24">
                            <ul>
                                {couponList.map((item, index) => (
                                <li key={index}>
                                    <div className="list-head">
                                        {item.price_type === '1' ? (
                                            <strong>{item.coupon_val}% 쿠폰</strong>
                                        ) : item.price_type === '2' ? (
                                            <strong>{item.coupon_val}원 쿠폰</strong>
                                        ) : null}
                                    </div>
                                    <div className="list-body">
                                        <p>{item.coupon_name}</p>
                                        <time>{item.use_end_date} 까지</time>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
