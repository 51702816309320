import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

function PopupUnsubscribe({ isPopup, setIsPopup }) {

    const location = useLocation();

    const [popupActive, setPopupActive] = useState()

    useEffect(() => {
        const body = document.body;

        body.style.overflow = isPopup ? 'hidden' : 'auto';

        return () => {
            body.style.overflow = 'auto';
        };
    }, [isPopup]);

    useEffect(() => {
        let timeout;
        if (isPopup) {
            timeout = setTimeout(() => {
                setPopupActive(true);
            }, 100);
        } else {
            setPopupActive(false);
        }

        // 타이머 클린업
        return () => clearTimeout(timeout);
    }, [isPopup]);

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, [location]);

    if (isPopup)
        return (
            <div className="popup-box-component no-script">
                <div className={`box basic pb40 ${popupActive ? 'active' : ''}`}>
                    <div className="popup-head">
                        <button type="button" className="close" onClick={() => {setIsPopup(false)}}>
                            닫기
                        </button>
                    </div>
                    <div className="popup-body">
                        <div className="title-box bb2 pb14">
                            <h2 className="f18 tl">회원탈퇴</h2>
                        </div>
                        <div className="text-box">
                            <p className="f15">회원님, 정말 떠나실 건가요?</p>
                            <p className="f15 ft-lightgray mt12">
                                저희 서비스를 이용해 주셔서 진심으로 감사드립니다.<br />
                                이용하시면서 느끼셨던 점이나 소중한 의견을 공유해 주신다면, <br />
                                더욱 풍성하고 만족스러운 서비스를 제공할 수 있도록 <br />
                                최선을 다하겠습니다. 앞으로도 더 나은 경험을 선사하기 위해 <br />
                                끊임없이 노력하겠습니다.
                            </p>
                        </div>
                        <div className="w300 col-lg-12 mt20">
                            <div className="check-box">
                                <input type="radio" name="radio01" id="radio01_01" />
                                <label htmlFor="radio01_01"><p>사용빈도가 낮아서</p></label>
                            </div>
                            <div className="check-box mt12">
                                <input type="radio" name="radio01" id="radio01_02" />
                                <label htmlFor="radio01_02"><p>가격 불만</p></label>
                            </div>
                            <div className="check-box mt12">
                                <input type="radio" name="radio01" id="radio01_03" />
                                <label htmlFor="radio01_03"><p>서비스 불편</p></label>
                            </div>
                            <div className="check-box mt12">
                                <input type="radio" name="radio01" id="radio01_04" />
                                <label htmlFor="radio01_04"><p>다른 사이트가 더 좋아서</p></label>
                            </div>
                            <div className="check-box mt12">
                                <input type="radio" name="radio01" id="radio01_05" />
                                <label htmlFor="radio01_05"><p>개인정보 보안</p></label>
                            </div>
                            <div className="flex flex-vc mt12">
                                <div className="check-box mt12">
                                    <input type="radio" name="radio01" id="radio01_06" />
                                    <label htmlFor="radio01_06"><p>기타</p></label>
                                </div>
                                <div className="input-box flex-1 ml12">
                                    <input type="text" className="h40" />
                                </div>
                            </div>
                        </div>
                        <div className="mt24 button-box">
                            <Link to="" className="btn btn-black">탈퇴하기</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default PopupUnsubscribe;
