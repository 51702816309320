import React, {useEffect, useState} from 'react';
import { Link, useLocation, Navigate } from "react-router-dom";
import { formatLocalString } from '../../utils/validation';
import { ORDER_RESULT_URL } from '../../utils/urls';
import instance from '../../services/services';

function Result() {
    const location = useLocation();
    const { paymentInfo, isPaymentComplete } = location.state || {};
    // orderId값 추출
    const { orderId } = paymentInfo;
    const [orderData, setOrderData] = useState();
    console.log(orderData, ':::orderData');

    // 결제 완료 처리
    useEffect(() => {
        const handleOrderResult = async () => {
            try {
                const response = await instance.post(ORDER_RESULT_URL, {
                    order_seq: orderId, 
                });
                setOrderData(response.data.data);
            } catch (error) {
                console.error('결제 완료 처리 실패:', error);
            }
        }
        handleOrderResult();
    }, [orderId])


    // 직접 접근 방지
    if (!location.state || !isPaymentComplete) {
        return <Navigate to="/" replace />;
    }

    // orderData가 없을 때 로딩 표시
    if (!orderData) {
        return <div>로딩중...</div>;
    }

    return (
        <section>
            <div className="container">
                <div className="title-box mb40 mb-lg-24">
                    <h2>주문/결제</h2>
                </div>
                <div className="item-cart-box order">
                    <div className="cart-left">
                        <div className="cart-head py28 py-lg-0">
                            &nbsp;
                        </div>
                        <div className="cart-body">
                            <ul>
                                {orderData.items.map((item, index) => (
                                    <li key={index}>
                                        <div className="item">
                                            <div className="inner">
                                            <div className="list-image"
                                                style={{backgroundImage:`url(${item.image})`}}></div>
                                            <div className="list-content">
                                                <div className="subject">
                                                    <b>O.Tok</b>
                                                    <p>{item.goods_name}</p>
                                                </div>
                                                <div className="price">
                                                    <small><strike>{formatLocalString(item.consumer_price)}</strike>원</small>
                                                    <div>
                                                        <strong>{item.sale_percent}%</strong>
                                                        <p>{formatLocalString(item.price)}<em>원</em></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cell">
                                        <p>배송비 별도</p>
                                    </div>
                                    <div className="cell">
                                        <p><b>{formatLocalString(item.price)}</b>원</p>
                                    </div>
                                </li>
                                ))}
                                
                            </ul>
                        </div>
                    </div>
                    <div className="cart-middle mt-lg-24">
                        <div className="write-list-box space">
                            <h3>배송지 정보</h3>
                            <ul>
                                <li>
                                    <strong className="form-title">받으시는 분</strong>
                                    <div className="form-content flex flex-vc">
                                        <p>{orderData.orderInfo.recipient_user_name}</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">핸드폰 번호</strong>
                                    <div className="form-content flex flex-vc">
                                        <p>{orderData.orderInfo.recipient_cellphone}</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">배송 주소</strong>
                                    <div className="form-content">
                                        <p>[{orderData.orderInfo.recipient_zipcode}] {orderData.orderInfo.recipient_address} {orderData.orderInfo.recipient_address_detail}</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">배송 메세지</strong>
                                    <div className="form-content">
                                        <p>배송 전에 연락주세요.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="write-list-box space mt40 mt-lg-24">
                            <h3 className="flex flex-vc">
                                결제 정보
                                <div className="is-m ml8">
                                    <div className="button-box">
                                        <Link to="" className="btn btn-white h20 px6 f10">거래명세서</Link>
                                    </div>
                                </div>
                            </h3>
                            <ul>
                                <li>
                                    <strong className="form-title">주문번호</strong>
                                    <div className="form-content flex flex-vc">
                                        <p>{orderData.orderInfo.order_seq}</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">주문일자</strong>
                                    <div className="form-content flex flex-vc">
                                        <p>{orderData.orderInfo.regist_date.split(' ')[0]}</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">결제일시</strong>
                                    <div className="form-content">
                                        <p>{orderData.orderInfo.step_txt}</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">결제수단</strong>
                                    <div className="form-content">
                                        <p>{orderData.orderInfo.payment_txt} (입금자명: {orderData.orderInfo.user_name})</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">결제금액</strong>
                                    <div className="form-content">
                                        <p>{formatLocalString(orderData.orderInfo.settleprice)}원</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="cart-right is-pc">
                        <ul>
                            <li>
                                <p>상품금액</p>
                                <p>{orderData.orderInfo.total_price ? formatLocalString(orderData.orderInfo.total_price) : 0}<em>원</em></p>
                            </li>
                            <li>
                                <p>배송비</p>
                                <p>+{orderData.orderInfo.shipping_cost ? formatLocalString(orderData.orderInfo.shipping_cost) : 0}<em>원</em></p>
                            </li>
                            <li>
                                <p>할인금액</p>
                                <p>-{orderData.orderInfo.coupon_sale ? formatLocalString(orderData.orderInfo.coupon_sale) : 0}<em>원</em></p>
                            </li>
                            <li className="border">
                                <p>포인트 사용</p>
                                <p>-{orderData.orderInfo.point ? formatLocalString(orderData.orderInfo.point) : 0}<em>원</em></p>
                            </li>
                            <li>
                                <b>총 결제금액</b>
                                <b>{orderData.orderInfo.settleprice ? formatLocalString(orderData.orderInfo.settleprice) : 0}
                                <em>원</em></b>
                            </li>
                            <li>
                                <small>적립예정 포인트</small>
                                <small>{orderData.orderInfo?.reserve ? formatLocalString(orderData.orderInfo.reserve) : 0}<em>p</em></small>
                            </li>
                        </ul>
                        <div className="button-box">
                            <div className="col-12 flex"><Link to="" className="btn btn-active f13">주문내역</Link></div>
                            <div className="col-12 flex mt8"><Link to="/products" className="btn btn-white f13">쇼핑 계속하기</Link></div>
                        </div>
                    </div>
                    <div className="cart-button">
                        <div className="button-box">
                            <Link to="/" className="btn btn-white f13 mr8">쇼핑 계속하기</Link>
                            <Link to="" className="btn btn-active f13">결제하기</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Result;
