import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Main from './pages/Main/Main';
import Login from './pages/Login/Login';
import Find from "./pages/Users/Find";

import ScrollToTop from './components/ScrollToTop';

import UsersCreateStep1 from "./pages/Users/CreateStep1";
import UsersCreateStep2 from "./pages/Users/CreateStep2";
import UsersCreateStep3 from "./pages/Users/CreateStep3";
import UsersCreateStep4 from "./pages/Users/CreateStep4";
import UsersCreateStep5 from "./pages/Users/CreateStep5";
import CartsIndex from './pages/Carts/Index';
import OrdersCreate from './pages/Orders/Create';
import OrdersResult from './pages/Orders/Result';
import ProductsIndex from './pages/Products/Index';
import ProductsShow from './pages/Products/Show';
import IntroCompany from "./pages/Contents/Brands/IntroCompany";
import useUserStore from './store/userStore';
import BenefitIndex from './pages/Benefit/Index'

import EventsIndex from './pages/Events/Index'
import EventsShow from './pages/Events/Show'

import CscenterIndex from './pages/Cscenter/Index'
import NoticeIndex from './pages/Notice/Index'
import NoticeShow from './pages/Notice/Show'
import QnaIndex from './pages/Qna/Index'
import BillIndex from './pages/Bill/Index'
import BillShow from './pages/Bill/Show'
import BillCreate from './pages/Bill/Create'
import IntroIndex from './pages/Intro/Index'
import LocationIndex from './pages/Location/Index'
import GuideIndex from './pages/Guide/Index'
import PaymentResult from './pages/Payment/PaymentResult';

import MypageIndex from './pages/Mypage/Index'

import MypageOrderIndex from './pages/Mypage/Order/Index'
import MypageOrderShow from './pages/Mypage/Order/Show'
import MypageOrderCreateReturn from './pages/Mypage/Order/CreateReturn'
import MypageOrderCreateExchange from './pages/Mypage/Order/CreateExchange'

import MypageOrdersCancelindex from './pages/Mypage/OrdersCancel/Index'
import MypageOrdersCancelShowCancel from './pages/Mypage/OrdersCancel/ShowCancel'
import MypageOrdersCancelShowExchange from './pages/Mypage/OrdersCancel/ShowExchange'
import MypageOrdersCancelShowReturn from './pages/Mypage/OrdersCancel/ShowReturn'

import MypageLikeIndex from './pages/Mypage/Like/Index'

import MypageAddressIndex from './pages/Mypage/Address/Index'
import MypageAddressCreate from './pages/Mypage/Address/Create'
import MypageAddressCertificate from './pages/Mypage/Address/Certificate'

import MypageUpdateIndex from './pages/Mypage/Update/Index'
import MypageUpdateCreate from './pages/Mypage/Update/Create'
import MypageCouponIndex from './pages/Mypage/Coupon/Index'
import MypagePointIndex from './pages/Mypage/Point/Index'


import MypageBillIndex from './pages/Mypage/Bill/Index'
import MypageBillCreate from './pages/Mypage/Bill/Create'

import MypageReviewAbleIndex from './pages/Mypage/Review/Able/Index'
import MypageReviewMyIndex from './pages/Mypage/Review/My/Index'
import MypageReviewCreate from './pages/Mypage/Review/Create'

import MypageQAproductIndex from './pages/Mypage/QAproduct/Index'

import MypageQAIndex from './pages/Mypage/QA/Index'
import MypageQACreate from './pages/Mypage/QA/Create'


import PrivacyShow from './pages/Privacy/Show'
import ReceiveShow from './pages/Receive/Show'
import TermsShow from './pages/Terms/Show'

import { getCookie, removeCookie } from './utils/cookies';

function App() {
  const { updateUserInfo, memberTokens } = useUserStore();

  useEffect(() => {
    const initializeAuth = () => {
        const accessToken = getCookie('access_token');
        const storedUserInfo = localStorage.getItem('userInfo');

        if (accessToken && storedUserInfo) {
            try {
              const parsedUserInfo = JSON.parse(storedUserInfo);
              updateUserInfo(parsedUserInfo);
              useUserStore.getState().updateTokens(accessToken); // memberTokens 업데이트 추가
            } catch (error) {
                console.error('사용자 정보 파싱 에러:', error);
                removeCookie('access_token');
                localStorage.removeItem('userInfo');
            }
        }
    };

    initializeAuth();
}, [updateUserInfo]);
  return (
    <Router>
      <ScrollToTop/>
      <div className="App">
        <Header />

        <Routes>
          <Route path='/' element={<Main />}/>

          {/*브랜드*/}
          <Route path='/brands/introCompany' element={<IntroCompany />}/>

          {/*로그인*/}
          <Route path='/login' element={memberTokens ? <Navigate to="/" replace /> : <Login />}/>

          {/*아이디 비번찾기*/}
          <Route path='/users/find' element={<Find />}/>

          {/*회원가입*/}
          <Route path='/users/createStep1' element={<UsersCreateStep1 />}/>
          <Route path='/users/createStep2' element={<UsersCreateStep2 />}/>
          <Route path='/users/createStep3' element={<UsersCreateStep3 />}/>
          <Route path='/users/createStep4' element={<UsersCreateStep4 />}/>
          <Route path='/users/createStep5' element={<UsersCreateStep5 />}/>

          {/*장바구니*/}
          <Route path='/carts' element={<CartsIndex />}/>

          {/*결제*/}
          <Route path='/orders/create' element={<OrdersCreate />}/>
          <Route path='/orders/result' element={<OrdersResult />}/>

          {/*상품*/}
          <Route path='/products' element={<ProductsIndex />}/>
          <Route path='/products/show/:goods_seq' element={<ProductsShow />}/>

          {/* 이벤트 */}
          <Route path='/events' element={<EventsIndex />}/>
          <Route path='/events/show' element={<EventsShow />}/>

          {/* cscenter */}
          <Route path='/cscenter' element={<CscenterIndex />}/>
          {/* 공지사항 */}
          <Route path='/notice' element={<NoticeIndex />}/>
          <Route path='/notice/show' element={<NoticeShow />}/>
          {/* qna */}
          <Route path='/qna' element={<QnaIndex />}/>
          {/* 세금계산서 */}
          <Route path='/bill' element={<BillIndex />}/>
          <Route path='/bill/create' element={<BillCreate />}/>
          <Route path='/bill/show' element={<BillShow />}/>
          {/* 회사소개 */}
          <Route path='/intro' element={<IntroIndex />}/>
          {/* 오시는길 */}
          <Route path='/location' element={<LocationIndex />}/>
          {/* 회원회택 */}
          <Route path='/benefit' element={<BenefitIndex />}/>
          {/* 이용안내 */}
          <Route path='/guide' element={<GuideIndex />}/>

          <Route path='/payment/result' element={<PaymentResult />}></Route>
          
          {/* 마이페이지======================= */}
          <Route path='/mypage' element={<MypageIndex />}/>

          {/* 주문/배송 괸리 */}
          <Route path='/mypage/order' element={<MypageOrderIndex />}/>
          <Route path='/mypage/order/show/:order_seq/:item_option_seq' element={<MypageOrderShow />}/>
          <Route path='/mypage/order/createReturn/:order_seq/:item_option_seq' element={<MypageOrderCreateReturn />}/>
          <Route path='/mypage/order/createExchange/:order_seq/:item_option_seq' element={<MypageOrderCreateExchange />}/>

          {/* 취소/교환/반품 관리 */}
          <Route path='/mypage/orders-cancel' element={<MypageOrdersCancelindex />}/>
          <Route path='/mypage/orders-cancel/show-cancel' element={<MypageOrdersCancelShowCancel />}/>
          <Route path='/mypage/orders-cancel/show-exchange' element={<MypageOrdersCancelShowExchange />}/>
          <Route path='/mypage/orders-cancel/show-return/:order_seq/:item_option_seq' element={<MypageOrdersCancelShowReturn />}/>


          {/* 관심상품 */}
          <Route path='/mypage/like' element={<MypageLikeIndex />}/>

          {/* 배송주소록 관리 */}
          <Route path='/mypage/address' element={<MypageAddressIndex />}/>
          <Route path='/mypage/address/create' element={<MypageAddressCreate />}/>
          <Route path='/mypage/address/certificate' element={<MypageAddressCertificate />}/>


          {/* 회원정보 수정 */}
          <Route path='/mypage/update' element={<MypageUpdateIndex />}/>
          <Route path='/mypage/update/create' element={<MypageUpdateCreate />}/>

          {/* 쿠폰 */}
          <Route path='/mypage/coupon' element={<MypageCouponIndex />}/>
          {/* 포인트 */}
          <Route path='/mypage/point' element={<MypagePointIndex />}/>


          {/* 세금계산서 */}
          <Route path='/mypage/bill' element={<MypageBillIndex />}/>
          <Route path='/mypage/bill/create' element={<MypageBillCreate />}/>

          {/* 구매후기 */}
          <Route path='/mypage/review/able' element={<MypageReviewAbleIndex />}/>
          <Route path='/mypage/review/my' element={<MypageReviewMyIndex />}/>
          <Route path="/mypage/review/create" element={<MypageReviewCreate />}/>

          {/* 상품문의 */}
          <Route path='/mypage/QA-product' element={<MypageQAproductIndex />}/>
          {/* 1:1문의 */}
          <Route path='/mypage/qa' element={<MypageQAIndex />}/>
          <Route path='/mypage/qa/create' element={<MypageQACreate />}/>


          {/* 개인정보 처리방침 */}
          <Route path='/privacy' element={<PrivacyShow />}></Route>
          {/* 오톡 쇼핑수신동의  */}
          <Route path='/receive' element={<ReceiveShow />}></Route>
          {/* 오톡 서비스 이용약관 */}
          <Route path='/terms' element={<TermsShow />}></Route>
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
