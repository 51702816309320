import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { getInquiryList, deleteInquiryList } from '../../../services/Border/border';
import useUserStore from '../../../store/userStore';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';



function Index() {
    const { userInfo } = useUserStore();
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const [inquiryData, setInquiryData] = useState([]);
    console.log(inquiryData, ":::inquiryData");

    useEffect(() => {
    
        const handleGetInquiryList = async () => {
            try {
                const response = await getInquiryList({
                    boardid : "mbqna",  
                    member_seq: userInfo.member_seq,
                    member_chk : "Y" , 
                    board_type : "",
                    offset : 1
                });
                setInquiryData(response.data.data.data)
                

            } catch (error) {
                console.error('문의후기 리스트 조회 실패:', error);
            }
        };
    
        handleGetInquiryList();
    
    }, [userInfo.member_seq]);

    const handleDeleteInquiryList = async (b_seq) => {
        try {
            if (window.confirm('정말 삭제하시겠습니까?')) {
                await deleteInquiryList({
                    b_seq: b_seq
                });
                
                // 삭제 후 목록 새로고침
                const response = await getInquiryList({
                    boardid : "mbqna",  
                    member_seq: userInfo.member_seq,
                    member_chk : "Y" , 
                    board_type : "",
                    offset : 1
                });
                setInquiryData(response.data.data.data);
                setIsPopupLoginError('삭제되었습니다.');
            }
        } catch (error) {
            console.error('구매후기 삭제 실패:', error);
            setIsPopupLoginError('삭제 중 오류가 발생했습니다.');
        }
    }


    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"1:1문의"} />
                    <div className="sub-right">
                        <div class="flex flex-vc flex-tj bb2 pb14">
                            <div class="title-box mb0">
                                <h2 class="tl f18">1:1문의({inquiryData.length})</h2>
                            </div>
                            <div class="button-box">
                                <Link to="/mypage/qa/create" class="btn btn-black h32 f12 px16">문의하기</Link> 
                            </div>
                        </div>
                        <div className="qa-list-box">
                            <ul>
                                {inquiryData.map((data, index) => (
                                <li key={index}>
                                    <div className="list-q">
                                        <div className="list-left">
                                            <button type="button" className="inner">
                                            <div className="sticker-box">
                                                {data.re_contents ? (
                                                    <span className="red">답변완료</span>
                                                ) : (
                                                    <span className="gray">답변대기</span>
                                                )}
                                            </div>
                                                <div className="content">
                                                    <b>{}</b>
                                                    <p>{data.subject}</p>
                                                    <time>{data.regist_date.split(' ')[0]}</time>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="list-right">
                                            <div className="button-box">
                                                <button className="btn btn-white sm h32" onClick={() => handleDeleteInquiryList(data.b_seq)}>
                                                    삭제
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                ))}





                                {/* <li>
                                    <div className="list-q">
                                        <div className="list-left">
                                            <button type="button" className="inner">
                                                <div className="sticker-box">
                                                    <span className="red">답변완료</span>
                                                </div>
                                                <div className="content">
                                                    <b>입금문의</b>
                                                    <p>향도 은은하고 사용 후 과일 맛에도 변화가 없어요.</p>
                                                    <time>2024.09.26</time>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="list-right">
                                            <div className="button-box">
                                                <a href="##" className="btn btn-white sm h32">
                                                    삭제
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-a">
                                        <b>답변 내용</b>
                                        <p>
                                            안녕하세요 고객님 오톡입니다. <br />
                                            <br />
                                            네, 천연 산호칼슘 과일야채 세정제는 베리류와 같은 작은 과일이나
                                            잎이 많은 채소에도 안전하게 사용하실 수 있습니다.
                                            <br />
                                            산호칼슘 성분이 과일과 채소의 표면을 깨끗하게 세정해주어 잔여
                                            농약이나 오염물질을 효과적으로 제거해줍니다.
                                            <br />
                                            세정 후에도 과일과 채소의 본연의 신선함을 유지하면서 잔여물이
                                            남지 않도록 깔끔하게 헹구어져 안심하고 드실 수 있습니다.
                                            <br />
                                            <br />
                                            감사합니다.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-q">
                                        <div className="list-left">
                                            <button type="button" className="inner">
                                                <div className="sticker-box">
                                                    <span className="red">답변완료</span>
                                                </div>
                                                <div className="content">
                                                    <b>입금문의</b>
                                                    <p>향도 은은하고 사용 후 과일 맛에도 변화가 없어요.</p>
                                                    <time>2024.09.26</time>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="list-right">
                                            <div className="button-box">
                                                <a href="##" className="btn btn-white sm h32">
                                                    삭제
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-a">
                                        <b>답변 내용</b>
                                        <p>
                                            안녕하세요 고객님 오톡입니다. <br />
                                            <br />
                                            네, 천연 산호칼슘 과일야채 세정제는 베리류와 같은 작은 과일이나
                                            잎이 많은 채소에도 안전하게 사용하실 수 있습니다.
                                            <br />
                                            산호칼슘 성분이 과일과 채소의 표면을 깨끗하게 세정해주어 잔여
                                            농약이나 오염물질을 효과적으로 제거해줍니다.
                                            <br />
                                            세정 후에도 과일과 채소의 본연의 신선함을 유지하면서 잔여물이
                                            남지 않도록 깔끔하게 헹구어져 안심하고 드실 수 있습니다.
                                            <br />
                                            <br />
                                            감사합니다.
                                        </p>
                                    </div>
                                </li> */}




                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
        </section>
    )
}

export default Index;
