import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { ORDER_DETAIL_URL, ORDER_RETURN_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import { formatLocalString } from '../../../utils/validation';
import useUserStore from '../../../store/userStore';


function CreateReturn() {
    const { userInfo } = useUserStore();
    const { order_seq, item_option_seq } = useParams();
    const [orderData, setOrderData] = useState(null);
    console.log(orderData, ':::orderData');
    const [isLoading, setIsLoading] = useState(true);
    
    // 추가할 상태들
    const [returnReason, setReturnReason] = useState('');
    const [returnMemo, setReturnMemo] = useState('');
    const [returnMethod, setReturnMethod] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);
    const [uploadImages, setUploadImages] = useState([]);
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);

    // 반품 사유 코드 매핑
    const returnReasonCode = {
        '단순변심': '1',
        '선택오류': '2',
        '상품불량': '3',
        '오배송': '4',
        '상품상이': '5',
        '기타': '6'
    };

    // 이미지 업로드 처리
    const handleImageUpload = (e) => {
        const uploadFiles = Array.from(e.target.files);
        const totalFiles = files.length + uploadFiles.length;
        
        if (totalFiles > 5) {
            alert('최대 5개까지만 업로드 가능합니다.');
            return;
        }
        
        // 이미지 미리보기 URL 생성
        const newPreviews = uploadFiles.map(file => URL.createObjectURL(file));
        
        setFiles(prev => [...prev, ...uploadFiles]);
        setPreviews(prev => [...prev, ...newPreviews]);
        setUploadImages(prev => [...prev, ...uploadFiles]); // 기존 uploadImages 상태도 유지
    };

    // 파일 삭제 핸들러 추가
    const handleFileDelete = (index) => {
        // 미리보기 URL 해제
        URL.revokeObjectURL(previews[index]);
        
        setFiles(prev => prev.filter((_, i) => i !== index));
        setPreviews(prev => prev.filter((_, i) => i !== index));
        setUploadImages(prev => prev.filter((_, i) => i !== index));
    };

    // 컴포넌트 언마운트 시 미리보기 URL 정리
    useEffect(() => {
        return () => {
            previews.forEach(url => URL.revokeObjectURL(url));
        };
    }, [previews]);

    // 반품신청 처리
    const handleReturnSubmit = async () => {
        if (!returnReason) {
            alert('반품 사유를 선택해주세요.');
            return;
        }
        if (!returnMemo) {
            alert('반품 사유를 입력해주세요.');
            return;
        }
        if (!returnMethod) {
            alert('회수 방법을 선택해주세요.');
            return;
        }
        if (!isAgreed) {
            alert('반품신청 내역 확인 및 동의가 필요합니다.');
            return;
        }

        try {
            // FormData 객체 생성
            const formData = new FormData();
            
            // 기본 데이터 추가
            formData.append('member_seq', userInfo.member_seq);
            formData.append('order_seq', order_seq);
            formData.append('return_reason', returnReasonCode[returnReason]);
            formData.append('return_memo', returnMemo);
            formData.append('return_method', returnMethod);
            
            // 발신자 정보 추가
            formData.append('sender_name', orderData.orderInfo.recipient_user_name);
            formData.append('cellphone', orderData.orderInfo.recipient_cellphone);
            formData.append('sender_zipcode', orderData.orderInfo.recipient_zipcode);
            formData.append('sender_address_street', orderData.orderInfo.recipient_address_street);
            formData.append('sender_address_detail', orderData.orderInfo.recipient_address_detail);

            // 이미지 파일들 추가
            uploadImages.forEach((file, index) => {
                formData.append(`upload_images[]`, file);
            });

            const response = await instance.post(ORDER_RETURN_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                alert('반품신청이 완료되었습니다.');
                window.location.href = '/mypage/orders-cancel';
            } else {
                alert(response.data.message || '반품신청에 실패했습니다.');
            }
        } catch (error) {
            console.error('반품신청 실패:', error);
            alert('반품신청 중 오류가 발생했습니다.');
        }
    };

    // 데이터 조회
    useEffect(() => {
        const fetchOrderData = async () => {
            try { 
                const response = await instance.post(ORDER_DETAIL_URL, {
                    order_seq: order_seq,
                    item_seq: item_option_seq
                });
                setOrderData(response.data.data);
            } catch (error) {
                console.error('주문 데이터 로드 실패:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchOrderData();
    }, [order_seq, item_option_seq]);

    // 로딩 중이거나 데이터가 없을 때
    if (isLoading || !orderData || !orderData.orderInfo) {
        return <div>로딩중...</div>;
    }


    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"주문/배송 관리"} />
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>반품신청</h2>
                        </div>
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">주문 상품</h2>
                        </div>
                        <div className="review-list-box">
                            <ul>
                                {orderData?.items?.map((item, index) => (
                                    <li key={index}>
                                        <div className="list-top">
                                            <dl>
                                                <dd>
                                                    <p>주문일자:</p>
                                                    <b>{orderData.orderInfo.regist_date}</b>
                                                </dd>
                                                <dd>
                                                    <Link to="" className="link">{item.order_seq}</Link>
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="list-bottom">
                                            <div className="bottom-left flex-lg-vt">
                                                <img src={item.image} alt="상품 이미지" />
                                                <div className="inner">
                                                    <div className="is-m mb12">
                                                        <div className="sticker-box">
                                                            <span className="red">{item.step_name}</span>
                                                        </div>
                                                    </div>
                                                    <b>O.Tok</b>
                                                    <p>{item.goods_name}</p>
                                                    <small>수량: {item.ea}개</small>
                                                </div>
                                            </div>
                                            <div className="bottom-right">
                                                <div className="inner">
                                                    <div className="bottom-right-price">
                                                        <p>{formatLocalString(item.unit_price)}<em>원</em></p>
                                                    </div>
                                                    <div className="bottom-right-state">
                                                        <p>{item.step_name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">반품 사유</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li>
                                    <strong>반품사유</strong>
                                    <div>
                                        <div className="w500 col-lg-12">
                                            <div className="select-box">
                                                <select 
                                                    className="h40"
                                                    value={returnReason}
                                                    onChange={(e) => setReturnReason(e.target.value)}
                                                >
                                                    <option value="">반품 사유 선택</option>
                                                    <option value="단순변심">단순변심</option>
                                                    <option value="선택오류">선택오류</option>
                                                    <option value="상품불량">상품불량</option>
                                                    <option value="오배송">오배송</option>
                                                    <option value="상품상이">상품상이</option>
                                                    <option value="기타">기타</option>
                                                </select>
                                            </div>
                                            <div className="textarea-box sm mt8">
                                                <textarea 
                                                    placeholder="반품 사유를 입력해주세요."
                                                    value={returnMemo}
                                                    onChange={(e) => setReturnMemo(e.target.value)}
                                                />
                                            </div>
                                            <div className="file-image-box mt8">
                                                <ul className="file-list">
                                                    <li>
                                                        {files.length < 5 && (
                                                            <label htmlFor="file">
                                                                파일찾기
                                                                <input 
                                                                    type="file" 
                                                                    name="file" 
                                                                    id="file" 
                                                                    multiple 
                                                                    onChange={handleImageUpload}
                                                                    accept="image/*"
                                                                />
                                                            </label>
                                                        )}
                                                    </li>
                                                    {previews.map((preview, index) => (
                                                        <li key={index}>
                                                            <div className="preview-image" style={{padding:"0px"}}>
                                                                <img src={preview} alt={`미리보기 ${index + 1}`} />
                                                                <button 
                                                                    type="button" 
                                                                    className="delete" 
                                                                    onClick={() => handleFileDelete(index)}
                                                                >
                                                                    삭제
                                                                </button>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="message-box">
                                                <p className="error">- 파일당 최대 10MB 의 용량 제한이 있습니다.</p>
                                                <p className="ft-lightgray">- 최대 5개까지 등록 가능합니다.</p>
                                            </div>
                                            <div className="guide-text-box mt8">
                                                <p>상품불량, 상품상이 및 오배송의 경우 왕복 운송료는 모두 업체(판매자)에서 부담합니다.</p>
                                                <p>단순 변심, 선택 오류 및 기타 사유인 경우 구매자가 택배비용을 부담합니다.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>


                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">환불정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg flex-vt">
                                    <strong>원결제금액</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong>27,000<em>원</em></strong>
                                            <p>상품 금액: 24,000원</p>
                                            <p>배송비: 3,000원</p>
                                            <p>포인트: 0원</p>
                                            <p>쿠폰 할인: 0원</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>차감금액</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong>0<em>원</em></strong>
                                            <p>반품 배송비: 0원</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>환불금액</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong className="ft-active">14,000<em className="ft-active">원</em></strong>
                                            <p>포인트 환불: 0원</p>
                                            <p>결제수단 환불: 0원</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>


                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">수거지 정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li>
                                    <strong>회수 방법</strong>
                                    <div>
                                        <div className="flex flex-vc">
                                            <div className="check-box mr24">
                                                <input 
                                                    type="radio" 
                                                    name="radio01" 
                                                    id="radio01_01"
                                                    checked={returnMethod === 'shop'}
                                                    onChange={() => setReturnMethod('shop')}
                                                />
                                                <label htmlFor="radio01_01"><p>회수신청</p></label>
                                            </div>
                                            <div className="check-box">
                                                <input 
                                                    type="radio" 
                                                    name="radio01" 
                                                    id="radio01_02"
                                                    checked={returnMethod === 'user'}
                                                    onChange={() => setReturnMethod('user')}
                                                />
                                                <label htmlFor="radio01_02"><p>직접반품</p></label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>





                        <div className="check-box mt16">
                            <input 
                                type="checkbox" 
                                name="check" 
                                id="check"
                                checked={isAgreed}
                                onChange={(e) => setIsAgreed(e.target.checked)}
                            />
                            <label htmlFor="check">
                                <p className="fw7">반품신청 내역을 확인하였으며, 반품 신청에 동의합니다. (필수)</p>
                                <button className="link">보기</button>
                            </label>
                        </div>
                        <div className="button-box mt40 mt-lg-24">
                            <div className="w200 flex flex-vc flex-lg-1 mr8">
                                <button type="button" className="btn btn-white f14">취소</button>
                            </div>
                            <div className="w200 flex flex-vc flex-lg-1">
                                <button 
                                    type="button" 
                                    className="btn btn-black f14"
                                    onClick={handleReturnSubmit}
                                >
                                    반품 신청
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateReturn;
