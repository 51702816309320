import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../../components/MypageSidebarMenu';
import useUserStore from '../../../../store/userStore';
import { getInquiryList, deleteInquiryList } from '../../../../services/Border/border';
import instance from '../../../../services/services';
import { PRODUCT_REVIEW_LIST_URL } from '../../../../utils/urls';
import PopupLoginError from '../../../../components/popupComponents/PopupLoginError';


function Index() {
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const { userInfo } = useUserStore();
    const [inquiryData, setInquiryData] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    // 내 후기 리스트
    useEffect(() => {
    
        const handleGetInquiryList = async () => {
            try {
                const response = await getInquiryList({
                    boardid : "review",  
                    member_seq: userInfo.member_seq,
                    member_chk : "Y" , 
                    offset : currentPage
                    
                });
                setInquiryData(response.data.data.data)
                setTotalPages(Math.ceil(response.data.data.total / response.data.data.limit));

            } catch (error) {
                console.error('구매후기 리스트 조회 실패:', error);
            }
        };
    
        handleGetInquiryList();
    
    }, [userInfo.member_seq, currentPage]);

    // 작성가능한 후기 리스트
    useEffect(() => {

        const handleGetReviewList = async () => {
            try {
                const response = await instance.post(PRODUCT_REVIEW_LIST_URL, {
                    offset : 1
                });
                setReviewData(response.data.data.data)
                

            } catch (error) {
                // 컴포넌트가 여전히 마운트 상태인 경우에만 에러 처리
                console.error('구매후기 리스트 조회 실패:', error);
            }
        };
    
        handleGetReviewList();
    
    }, []); // 빈 의존성 배열 유지

    const handleDeleteInquiryList = async (b_seq) => {
        try {
            if (window.confirm('정말 삭제하시겠습니까?')) {
                await deleteInquiryList({
                    b_seq: b_seq
                });
                
                // 삭제 후 목록 새로고침
                const response = await getInquiryList({
                    boardid: "review",
                    member_seq: userInfo.member_seq,
                    member_chk: "Y",
                    offset: 1
                });
                setInquiryData(response.data.data.data);
                setIsPopupLoginError('삭제되었습니다.');
            }
        } catch (error) {
            console.error('구매후기 삭제 실패:', error);
            setIsPopupLoginError('삭제 중 오류가 발생했습니다.');
        }
    }

    // 페이지 변경 핸들러
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // 페이지 번호 배열 생성
    const getPageNumbers = () => {
        const pageNumbers = [];
        let startPage = Math.floor((currentPage - 1) / 5) * 5 + 1;
        let endPage = startPage + 4;

        if (endPage > totalPages) {
            endPage = totalPages;
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"구매후기"} />
                    <div className="sub-right">
                        <div className="tab-box mb0">
                            <ul>
                                <li>
                                    <Link to="/mypage/review/able" className="link">
                                        작성 가능한 후기<em>{reviewData.length}</em>
                                    </Link>
                                </li>
                                <li className="active">
                                    <Link to="/mypage/review/my" className="link">
                                        내 후기<em>{inquiryData.length}</em>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="review-list-my-box">
                            <ul>
                            {inquiryData.map((data, index) => (
                                    <li key={index}>
                                        <div className="list-left">
                                            <div className="left-item">
                                                <img
                                                    src={data.order_goods_image}
                                                    alt="상품 이미지"
                                                />
                                                <div className="inner">
                                                    <b>O.Tok</b>
                                                    <p>{data.subject}</p>
                                                    <small>수량: 1개</small>
                                                </div>
                                            </div>
                                            <div className="left-review">
                                                <div className="left-review-top">
                                                    <div className="score-box">
                                                        {[1, 2, 3, 4, 5].map((star) => (
                                                            <span 
                                                                key={star} 
                                                                className={star <= data.grade ? "active" : ""}
                                                            >
                                                                별점
                                                            </span>
                                                        ))}
                                                    </div>
                                                    <time>{data.regist_date.split(' ')[0]}</time>
                                                </div>
                                                <div className="left-review-content">
                                                    <p>{data.contents}</p>
                                                    <dl>
                                                        {data.files.map((file, index) => (
                                                            <dd key={index}>
                                                                <img
                                                                    src={file.new_filepath}
                                                                    alt="리뷰 이미지"
                                                                />
                                                            </dd>
                                                        ))}
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-right">
                                            <div className="button-box">
                                                <Link 
                                                    to="/mypage/review/create" 
                                                    state={{
                                                        isEdit: true,
                                                        b_seq: data.b_seq,
                                                        order_goods_image: data.order_goods_image,
                                                        order_goods_name: data.goods_name,
                                                        goods_seq: data.goods_seq,
                                                        order_seq: data.order_seq,
                                                        grade: Number(data.grade),
                                                        subject: data.subject,
                                                        contents: data.contents,
                                                        files: data.files || []
                                                    }} 
                                                    className="btn btn-white sm h32 mr4"
                                                >
                                                    수정
                                                </Link>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-white sm h32"
                                                    onClick={() => handleDeleteInquiryList(data.b_seq)}
                                                >
                                                    삭제
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button 
                                        type="button"
                                        onClick={() => handlePageChange(1)}
                                        disabled={currentPage === 1}
                                    >
                                        처음
                                    </button>
                                </li>
                                <li className="prev">
                                    <button 
                                        type="button"
                                        onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                                        disabled={currentPage === 1}
                                    >
                                        이전
                                    </button>
                                </li>
                                {getPageNumbers().map((pageNum) => (
                                    <li 
                                        key={pageNum} 
                                        className={currentPage === pageNum ? 'active' : ''}
                                    >
                                        <button 
                                            type="button"
                                            onClick={() => handlePageChange(pageNum)}
                                        >
                                            {pageNum}
                                        </button>
                                    </li>
                                ))}
                                <li className="next">
                                    <button 
                                        type="button"
                                        onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                                        disabled={currentPage === totalPages}
                                    >
                                        다음
                                    </button>
                                </li>
                                <li className="last">
                                    <button 
                                        type="button"
                                        onClick={() => handlePageChange(totalPages)}
                                        disabled={currentPage === totalPages}
                                    >
                                        맨끝
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
        </section>
    )
}

export default Index;
