import React, { useEffect, useState } from 'react';
import SidebarMenu from "../../components/SidebarMenu";
import { Link, useLocation } from 'react-router-dom';
import { NOTICE_DETAIL_URL } from '../../utils/urls';
import instance from '../../services/services';

function Show() {
    const location = useLocation();
    const seq = location.state?.Data || location.state?.b_seq;
    const [seqData, setSeqData] = useState([]);
    console.log(seqData, '::::seqData');
    const [fileData, setFileData] = useState([]);
    console.log(fileData, ':::');

    useEffect(() => {
        const fetchMainData = async () => {
            try {
                const response = await instance.post(NOTICE_DETAIL_URL, {
                    b_seq: seq
                });

                // 응답 데이터 상태에 저장
                setSeqData(response.data.data.data);
                setFileData(response.data.data.files);
            } catch (error) {
                console.error('메인 페이지 데이터 로딩 실패:', error);
            }
        };
        fetchMainData();
    }, [seq]); 

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="공지사항"/>
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>공지사항</h2>
                        </div>
                        <div className="board-view-box">
                            <div className="view-subject">
                                <div className="left-subject">
                                    {location.state?.Data && (  
                                            <b className="notice">공지</b>
                                        )}
                                    <h3>[O.Tok] {seqData?.subject}</h3>
                                </div>
                                <div className="right-subject">
                                    {seqData?.regist_date && (
                                        <time>{seqData.regist_date.split(' ')[0]}</time>
                                    )}
                                </div>
                            </div>
                            <div className="view-content">
                                <p>{seqData?.contents}</p>
                            </div>
                            <div className="view-file">
                                {fileData.map((file, index) => (
                                <Link className="link" key={index}>
                                    {file.new_filepath}
                                </Link>
                                ))}

                            </div>
                            <div className="view-button">
                                <div className="button-box">
                                    <Link to="/notice" className="btn btn-white">
                                        목록
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Show;
