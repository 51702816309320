import React from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';

function ShowCancel() {
    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"취소/교환/반품 관리"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">취소상세내역</h2>
                        </div>
                        <div className="review-list-box">
                            <ul>
                                <li className="gray">
                                    <div className="list-top">
                                        <dl>
                                            <dd>
                                                <p>주문일자:</p>
                                                <b>2024.09.26</b>
                                            </dd>
                                            <dd>
                                                <Link to="" className="link">2024040500011</Link>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="list-bottom">
                                        <div className="bottom-left flex-lg-vt">
                                            <img src="/asset/images/img_item_list_01.png" alt="상품 이미지" />
                                            <div className="inner">
                                                <div className="is-m mb12">
                                                    <div className="sticker-box">
                                                        <span className="red">취소완료</span>
                                                    </div>
                                                </div>
                                                <b>O.Tok</b>
                                                <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                {/* <small>옵션: [O.Tok]국내최초 천연 산호칼슘 과일야채 세정제</small> */}
                                                <small>수량: 1개</small>
                                            </div>
                                        </div>
                                        <div className="bottom-right">
                                            <div className="inner">
                                                <div className="bottom-right-price">
                                                    <p>8,000<em>원</em></p>
                                                </div>
                                                <div className="bottom-right-state">
                                                    <p>취소완료</p>
                                                </div>
                                                <div className="bottom-right-text">
                                                    <p>취소</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="title-box bb2 pb14 mb0 mt32">
                            <h2 className="tl f18">취소/환불정보</h2>
                        </div>
                        <div className="board-write-box bt0">
                            <ul>
                                <li className="flex-lg flex-vt">
                                    <strong>원결제금액</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong>49,500<em>원</em></strong>
                                            <p>상품 금액: 55,000원</p>
                                            <p>배송비: 0원</p>
                                            <p>포인트: 5,500원</p>
                                            <p>쿠폰 할인: 5,500원</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-lg flex-vt">
                                    <strong>환불금액</strong>
                                    <div>
                                        <div className="price-info-box">
                                            <strong className="ft-active">46,500<em className="ft-active">원</em></strong>
                                            <p>포인트 환불: 1,060원</p>
                                            <p>결제수단 환불: 45,440원</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-box mt32 mt-lg-24">
                            <div className="w200 w-lg-120">
                                <Link to="" className="btn btn-white">목록</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShowCancel;
