import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { PRODUCT_CREATE_URL, PRODUCT_REVIEW_UPDATE_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';

function Create() {
    const navigate = useNavigate();
    const[isPopupLoginError, setIsPopupLoginError] = useState("")
    const location = useLocation();
    console.log(location.state, ':::::ndasdqwe33')
    const isEdit = location.state?.isEdit;

    const [starCount, setStarCount] = useState(Number(location.state?.grade) || 0);
    const [title, setTitle] = useState(location.state?.subject || '');
    const [content, setContent] = useState(location.state?.contents || '');
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [pageState, setPageState] = useState(location.state);

    // location.state가 없을 때의 처리 추가
    useEffect(() => {
        if (!location.state && !isPopupLoginError) {  
            // state 데이터와 함께 리다이렉트
            navigate('/mypage/review/create', { 
                state: pageState 
            });
            return;
        }
        // location.state가 있으면 상태 업데이트
        if (location.state) {
            setPageState(location.state);
        }
    }, [location.state, isPopupLoginError, navigate, pageState]); 


    // 별점 증가
    const handleIncrease = () => {
        if (starCount < 5) {
            setStarCount(prev => prev + 1);
        }
    };

    // 별점 감소
    const handleDecrease = () => {
        if (starCount > 1) {
            setStarCount(prev => prev - 1);
        }
    };

    // 별점 직접 클릭
    const handleStarClick = (index) => {
        setStarCount(index + 1);
    };

    // 수정 모드일 때 기존 데이터 로드
    useEffect(() => {
        if (isEdit && location.state) {
            setStarCount(Number(location.state.grade));
            setTitle(location.state.subject);
            setContent(location.state.contents);
            if (location.state.files) {
                setPreviews(location.state.files.map(file => file.url));
            }
        }
    }, [isEdit, location.state]);

    // 파일 업로드 처리
    const handleFileChange = (e) => {
        const uploadFiles = Array.from(e.target.files);
        const totalFiles = files.length + uploadFiles.length;
        
        if (totalFiles > 5) {
            setIsPopupLoginError('최대 5개까지만 업로드 가능합니다.');
            return;
        }
        
        // 이미지 미리보기 URL 생성
        const newPreviews = uploadFiles.map(file => URL.createObjectURL(file));
        
        setFiles(prev => [...prev, ...uploadFiles]);
        setPreviews(prev => [...prev, ...newPreviews]);
    };


    // 파일 삭제 처리
    const handleFileDelete = (index) => {
        // 미리보기 URL 해제
        URL.revokeObjectURL(previews[index]);
        
        setFiles(prev => prev.filter((_, i) => i !== index));
        setPreviews(prev => prev.filter((_, i) => i !== index));
    };

    // 컴포넌트 언마운트 시 미리보기 URL 정리
    useEffect(() => {
        return () => {
            previews.forEach(url => URL.revokeObjectURL(url));
        };
    }, [previews]);

    // 등록, 수정 버튼
    const handleClick = async (e) => {
        e.preventDefault();
    
        if (!title) {
            setIsPopupLoginError('제목을 입력해주세요.');
            return;
        }
        if (!content) {
            setIsPopupLoginError('내용을 입력해주세요.');
            return;
        }
        if (starCount === 0) {
            setIsPopupLoginError('평점을 선택해주세요.');
            return;
        }
    
        const formData = new FormData();
        
        if (isEdit) {
            // 수정 시 필요한 데이터
            formData.append('b_seq', location.state.b_seq);
            formData.append('subject', title);
            formData.append('contents', content);
            formData.append('board_type', 'member');
            formData.append('grade', starCount);
            
            // 새로 업로드하는 파일이 있는 경우
            if (files.length > 0) {
                files.forEach(file => {
                    formData.append('upload_images[]', file);
                });
            }
    
            // 삭제할 파일 ID가 있는 경우 (이 부분은 삭제할 파일 ID 관리 로직에 따라 수정 필요)
            if (location.state.deleteFileIds) {
                formData.append('delete_file_id', location.state.deleteFileIds);
            }
        } else {
            // 신규 등록 시 필요한 데이터
            formData.append('boardid', 'review');
            formData.append('subject', title);
            formData.append('contents', content);
            formData.append('board_type', 'member');
            formData.append('goods_seq', location.state.order_goods_seq);
            formData.append('order_seq', location.state.order_seq);
            formData.append('grade', starCount);
    
            if (files.length > 0) {
                files.forEach(file => {
                    formData.append('upload_images[]', file);
                });
            }
        }
    
        try {
            const response = await instance.post(
                isEdit ? PRODUCT_REVIEW_UPDATE_URL : PRODUCT_CREATE_URL, 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
    
            if (response.data.success) {
                setIsPopupLoginError(isEdit ? '후기가 수정되었습니다.' : '후기가 등록되었습니다.');
                setTimeout(() => {
                    navigate('/mypage/review/my');
                }, 1000); // 1초 후 이동
            } else {
                setIsPopupLoginError(response.data.message || '처리에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error details:', error.response?.data || error);
            setIsPopupLoginError('처리 중 오류가 발생했습니다.');
        }
    };

    // location.state가 없을 때의 early return
    if (!location.state && !isPopupLoginError) {
        return null;
    }

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"구매후기"} />
                    <div className="sub-right">
                        <div className="board-write-box">
                            <ul>
                                <li>
                                    <strong>후기상품</strong>
                                    <div>
                                        <div className="thumb-box">
                                            <div className="inner">
                                                <img src={location.state.order_goods_image} alt="상품 이미지" />
                                                <div>
                                                    <b>O.Tok</b>
                                                    <p>{location.state.order_goods_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>평점</strong>
                                    <div>
                                        <div className="score-write-box">
                                            <button type="button" className="btn minus" onClick={handleDecrease}>감소</button>
                                            <div className="star">
                                                {[...Array(5)].map((_, index) => (
                                                    <span 
                                                        key={index}
                                                        className={index < starCount ? 'active' : ''}
                                                        onClick={() => handleStarClick(index)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        별점
                                                    </span>
                                                ))}
                                            </div>
                                            <button type="button" className="btn plus" onClick={handleIncrease}>증가</button>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>제목</strong>
                                    <div>
                                        <div className="input-box">
                                        <input 
                                            type="text" 
                                            placeholder="제목을 입력해 주세요." 
                                            className="h40"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)} 
                                        />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>내용</strong>
                                    <div>
                                        <div className="textarea-box lg">
                                            <textarea 
                                                    placeholder="내용을 입력해 주세요."
                                                    value={content}
                                                    onChange={(e) => setContent(e.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>첨부파일</strong>
                                    <div>
                                        <div className="file-image-box">
                                            <ul className="file-list">
                                                <li>
                                                {files.length < 5 && (
                                                    <label htmlFor="file">
                                                        파일찾기
                                                        <input 
                                                            type="file" 
                                                            name="file" 
                                                            id="file" 
                                                            multiple
                                                            onChange={handleFileChange}
                                                            accept="image/*"
                                                        />
                                                    </label>
                                                )}
                                                </li>
                                                {previews.map((preview, index) => (
                                                    <li key={index}>
                                                        <div className="preview-image" style={{padding:"0px"}}>
                                                            <img src={preview} alt={`미리보기 ${index + 1}`} />
                                                            <button 
                                                                type="button" 
                                                                className="delete" 
                                                                onClick={() => handleFileDelete(index)}
                                                            >
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))}
                                                
                                            </ul>
                                        </div>
                                        <div className="message-box">
                                            <p className="error">- 파일당 최대 10MB 의 용량 제한이 있습니다.</p>
                                            <p className="ft-lightgray">- 최대 5개까지 등록 가능합니다.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-box mt32">
                            <div className="w200 flex-lg-1 mr8">
                                <Link
                                    to="#"
                                    className="btn btn-white"
                                    onClick={(e) => {
                                        e.preventDefault(); // 기본 동작 방지
                                        navigate(-1); // 뒤로 가기
                                    }}
                                >
                                    취소
                                </Link>
                            </div>
                            <div className="w200 flex-lg-1">
                                <Link className="btn btn-black" onClick={handleClick}>
                                    {isEdit ? '수정' : '등록'}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
            </div>
        </section>
    );
}

export default Create;
