import React from 'react';
import { Link } from 'react-router-dom';
function Show() {
    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <div className="sub-left">
                        <h2>이벤트</h2>
                        <ul>
                            <li className="active">
                                <Link to="/events/ongoing" className="link">진행중 이벤트</Link>
                            </li>
                            <li>
                                <Link to="/events/ended" className="link">종료된 이벤트</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>이벤트</h2>
                        </div>
                        <div className="board-view-box">
                            <div className="view-subject">
                                <div className="left-subject">
                                    <div className="state">
                                        <span className="active">진행중</span>
                                    </div>
                                    <h3 className="flex-0">[O.Tok] 새롭게 변경된 환불 및 교환 정책 변경 안내</h3>
                                </div>
                                <div className="right-subject">
                                    <time>2024.09.16 ~ 2024.09.26</time>
                                </div>
                            </div>
                            <div className="view-content">
                                <p>
                                    안녕하세요. 오톡입니다.<br /><br />
                                    안녕하세요, 천연 과일세정제를 사랑해주시는 고객님들께 감사의 말씀을 드립니다.<br />
                                    저희 제품에 대한 지속적인 관심과 신뢰에 보답하기 위해, 더 나은 서비스 제공을 위해 환불 및 교환 정책을 새롭게 변경하게 되었습니다.<br />
                                    고객님께 보다 만족스러운 쇼핑 경험을 드리기 위해 아래와 같이 환불 및 교환 정책을 강화하고, 세부 절차를 명확히 안내드리니, 변경된 내용을 꼭 확인해 주시길 바랍니다.<br /><br />
                                    <strong>새롭게 변경된 환불 및 교환 정책 (2024년 10월 15일부터 적용)</strong><br /><br />
                                    1. 환불 및 교환 가능 기간<br />
                                    - 미개봉 제품: 제품 수령 후 14일 이내에 환불 또는 교환이 가능합니다.<br />
                                    단, 제품 및 포장이 훼손되지 않은 경우에 한해 가능하며, 왕복 배송비는 고객님 부담입니다.<br />
                                    - 개봉 후 환불: 개봉 후 제품에 하자가 있을 경우, 제품 수령 후 7일 이내에 고객센터로 연락 주시면 교환 및 환불이 가능합니다.<br />
                                    제품의 기능적 결함이나 변질 등 품질에 문제가 있을 경우 배송비는 저희가 부담합니다.<br /><br />
                                    2. 교환/환불 절차<br />
                                    교환 또는 환불을 원하시는 경우, 고객센터(080-123-4567)로 먼저 문의해주시면 교환 및 환불 절차에 대한 안내를 받으실 수 있습니다.<br />
                                    고객센터에서 안내된 절차에 따라 제품을 발송해주시면, 제품 상태 확인 후 환불이나 교환 처리를 진행해드립니다.<br /><br />
                                    3. 부분 환불 안내<br />
                                    제품을 여러 개 구매하신 경우, 사용하지 않은 미개봉 제품에 한해 부분 환불이 가능합니다.<br />
                                    개봉 후 제품은 위의 기준에 따라 교환만 가능하오니, 이 점 참고 부탁드립니다.<br /><br />
                                    4. 환불 불가 사유<br />
                                    다음의 경우에는 환불 및 교환이 제한될 수 있으니 주의해주시기 바랍니다.<br />
                                    - 제품을 이미 사용하였거나, 개봉 후 14일이 경과한 경우<br />
                                    - 고객 부주의로 인해 제품 및 포장이 훼손된 경우<br />
                                    - 재판매가 어려울 정도로 제품의 가치가 훼손된 경우<br />
                                    - 천연 성분 특성상 개인의 사용감 차이에 의한 불만족(향, 세정력 등)은 환불 및 교환 사유에 해당되지 않습니다.<br /><br />
                                    5. 리뉴얼 제품 관련 교환/환불<br />
                                    2024년 11월 출시 예정인 리뉴얼 제품에 대한 교환/환불은 동일하게 적용되며, 리뉴얼 후에도 기존 제품에 대해 동일한 환불 및 교환 정책이 유지됩니다.<br />
                                    리뉴얼로 인한 제품의 성능이나 향 등의 변경 사항은 사전 공지된 내용에 따라 이루어진 것이며, 이는 환불 사유로 인정되지 않습니다.<br /><br />
                                    6. 교환 시 추가 혜택<br />
                                    제품 하자가 있는 경우 교환 시 무료 교환은 물론, 추가 샘플 증정 혜택을 드립니다.<br />
                                    고객님의 불편함을 최소화하기 위해 더욱 노력하겠습니다.<br />
                                </p>
                            </div>
                            <div className="view-button">
                                <div className="button-box">
                                    <Link to="/events" className="btn btn-white">목록</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Show;
