import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { LIKE_LIST_URL, PRODUCT_REVIEW_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import { formatLocalString } from '../../../utils/validation';
import useUserStore from '../../../store/userStore';


function Index() {
    const userInfo = useUserStore();
    console.log(userInfo.userInfo.member_seq, ':::::userInfo');
    const [likeList, setLikeList] = useState([]);
    console.log(likeList, ':::::likeList'); 

    useEffect(() => {
        const fetchLikeList = async () => {
            const response = await instance.post(LIKE_LIST_URL);
            setLikeList(response.data.data);
        };
            fetchLikeList();
    }, []);

    // 좋아요 토글 핸들러
    const handleLikeToggle = async (goods_seq) => {
        try {
            const response = await instance.post(PRODUCT_REVIEW_URL, {
                member_seq: userInfo.userInfo.member_seq,
                goods_seq: goods_seq
            });

            if (response.data.success) {
                // 좋아요 목록 새로고침
                const updatedResponse = await instance.post(LIKE_LIST_URL);
                setLikeList(updatedResponse.data.data);
            } else {
                alert(response.data.message || '처리 중 오류가 발생했습니다.');
            }
        } catch (error) {
            console.error('좋아요 처리 실패:', error);
            alert('처리 중 오류가 발생했습니다.');
        }
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"관심상품"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">관심상품({likeList.length})</h2>
                        </div>
                        {!likeList.length && (
                        <div className="list-none-box">
                            <p>관심상품이 없습니다.</p> 
                        </div>
                        )}
                        <div className="item-list-box mt24">
                            <div className="list-body">
                                <ul className="grid-4">
                                    {likeList.map((item, index) => (
                                            <li key={index}>
                                                <div className="inner">
                                                    <div
                                                        className="list-image"
                                                        style={{
                                                            backgroundImage: `url(${item.goods_image})`,
                                                        }}
                                                    >
                                                        <Link to={`/products/show/${item.goods_seq}`} className="link">링크</Link>
                                                        <div className="like">
                                                            <button
                                                                type="button"
                                                                className="active"
                                                                onClick={() => handleLikeToggle(item.goods_seq)}
                                                            >
                                                                좋아요
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="list-content">
                                                        <Link to="">
                                                            <div className="subject">
                                                                <b>Otok</b>
                                                                <p>
                                                                    {item.goods_name}
                                                                </p>
                                                            </div>
                                                            <div className="price">
                                                                <small>
                                                                    <strike>{formatLocalString(item.consumer_price)}</strike>원
                                                                </small>
                                                                <div>
                                                                    <strong>{item.sale_percent}%</strong>
                                                                    <p>{formatLocalString(item.price)}<em>원</em></p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                        <div className="paging-box mt56 mt-lg-40">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
