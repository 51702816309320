import React from 'react';
// import { Link } from 'react-router-dom';
function Show() {
    return (
        <section>
            <div className="container">
                <div className="agree-box">
                    <h2>오톡 쇼핑수신동의</h2>
                    <ul>
                        <li>
                            <p>
                                다양한 이벤트 및 할인 상품 정보등 스마트한 쇼핑팁을 문자로 받아보실
                                수 있으며,  할인쿠폰 및 혜택, 이벤트, 신상품 소식 등 쇼핑몰에서
                                제공하는 유익한 쇼핑정보를 SMS 또는 카카오톡으로 받아볼 수 있습니다.
                                <br />
                                단, 주문/거래 정보 및 주요 정책과 관련된 내용은 수신동의 여부와
                                관계없이 발송됩니다.
                                <br />
                                제공 동의를 하지 않으셔도 서비스 이용에는 문제가 없습니다.
                                <br />
                                선택 약관에 동의하지 않으셔도 회원가입은 가능하며, 회원가입 후
                                회원정보수정 페이지에서 언제든지 수신여부를 변경하실 수 있습니다.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
      
    )
}

export default Show;
