import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import instance from '../../../services/services';
import { PASSWORD_CHECK_URL } from '../../../utils/urls';
import PopupLoginError from '../../../components/popupComponents/PopupLoginError';

function Index() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const[isPopupLoginError, setIsPopupLoginError] = useState("")

    const handlePasswordCheck = async () => {
        try {
            const response = await instance.post(PASSWORD_CHECK_URL, {
                password: password
            });

            if (response.data.code === 200) {
                navigate("/mypage/update/create");
            } else {
                setIsPopupLoginError(response.data.message);
            }
        } catch (error) {
            console.error("비밀번호 확인 실패", error);
        }
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"회원정보 수정"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">회원정보 수정</h2>
                        </div>
                        <div className="update-box">
                            <p>
                                회원님의 안전한 개인정보 보호를 위해 <br className="is-m" />
                                비밀번호를 다시 한번 확인합니다.
                            </p>
                            <div className="inner">
                                <div className="input-box">
                                    <input
                                        type="password"
                                        placeholder="비밀번호를 입력해주세요."
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="button-box">
                                    <button 
                                        type="button" 
                                        onClick={handlePasswordCheck} 
                                        className="btn btn-black"
                                    >
                                        확인
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopupLoginError isPopup={isPopupLoginError} setIsPopup={setIsPopupLoginError}/>
        </section>
    )
}

export default Index;
