import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';
import { CANCEL_LIST_URL } from '../../../utils/urls';
import instance from '../../../services/services';
import { formatLocalString } from '../../../utils/validation';


function Index() {
    const [cancelList, setCancelList] = useState([]);
    console.log(cancelList,':::cancelList');
    const [activeStep, setActiveStep] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [activePeriod, setActivePeriod] = useState(""); // 선택된 기간 버튼
    const [currentPage, setCurrentPage] = useState(1);

    // 날짜 계산 함수
    const calculateDate = (period) => {
        const today = new Date();
        const end = today.toISOString().split('T')[0];
        
        let start = new Date();
        switch(period) {
            case 'today':
                break;
            case '1month':
                start.setMonth(today.getMonth() - 1);
                break;
            case '3month':
                start.setMonth(today.getMonth() - 3);
                break;
            case '6month':
                start.setMonth(today.getMonth() - 6);
                break;
            case '1year':
                start.setFullYear(today.getFullYear() - 1);
                break;
            default:
                break;
        }
        return {
            start: start.toISOString().split('T')[0],
            end: end
        };
    };

    // 취소/교환/반품 리스트 조회 함수
    const fetchCancelList = async () => {
        try {
            const response = await instance.post(CANCEL_LIST_URL, {
                sdate: startDate,
                edate: endDate,
                step: activeStep,
                limit: 10,
                offset: currentPage
            });
            setCancelList(response.data.data);
        } catch (error) {
            console.error("취소/교환/반품 목록 조회 실패", error);
        }
    };

    // 초기 데이터 로드
    useEffect(() => {
        const initialFetch = async () => {
            await fetchCancelList();
        };
        initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    // 기간 버튼 클릭 핸들러
    const handlePeriodClick = (period) => {
        setActivePeriod(period);
        const dates = calculateDate(period);
        setStartDate(dates.start);
        setEndDate(dates.end);
    };

    // 날짜 직접 입력 핸들러
    const handleDateChange = (type, value) => {
        if (type === 'start') {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
        setActivePeriod(''); // 기간 버튼 선택 해제
    };

    

    // 카테고리 클릭 핸들러
    const handleCategoryClick = (step) => {
        setActiveStep(step);
    };

    // activeStep이 변경될 때마다 API 호출하도록 useEffect 추가
    useEffect(() => {
        fetchCancelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep]);

    const RETURN_TYPE = {
        '반품신청': '반품',
        '교환신청': '교환',
        '교환완료': '교환',
        '결제취소': '취소',
        '주문취소': '취소',
        '교환철회': '교환',
        '반품철회': '반품',
        '배송완료': '배송',
        '배송대기중': '배송'
    };

    // 페이지 클릭 핸들러 수정
    const handlePageClick = async (page) => {
        setCurrentPage(page);
        try {
            const response = await instance.post(CANCEL_LIST_URL, {
                sdate: startDate,
                edate: endDate,
                step: activeStep,
                limit: 10,
                offset: page
            });
            setCancelList(response.data.data);
        } catch (error) {
            console.error("페이지 데이터 조회 실패", error);
        }
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"취소/교환/반품 관리"} />
                    <div className="sub-right">
                        <div className="filter-box">
                            <div className="date-select-box">
                                <ul>
                                    <li className={activePeriod === 'today' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('today')}
                                        >
                                            오늘
                                        </button>
                                    </li>
                                    <li className={activePeriod === '1month' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('1month')}
                                        >
                                            1개월
                                        </button>
                                    </li>
                                    <li className={activePeriod === '3month' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('3month')}
                                        >
                                            3개월
                                        </button>
                                    </li>
                                    <li className={activePeriod === '6month' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('6month')}
                                        >
                                            6개월
                                        </button>
                                    </li>
                                    <li className={activePeriod === '1year' ? 'active' : ''}>
                                        <button 
                                            type="button" 
                                            className="select"
                                            onClick={() => handlePeriodClick('1year')}
                                        >
                                            1년
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="date-box">
                                <div className="date">
                                    <input 
                                        type="date" 
                                        value={startDate}
                                        onChange={(e) => handleDateChange('start', e.target.value)}
                                    />
                                </div>
                                <div className="date">
                                    <input 
                                        type="date" 
                                        value={endDate}
                                        onChange={(e) => handleDateChange('end', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="button-box">
                                <button 
                                    className="btn btn-black h40 f14 fw4"
                                    onClick={fetchCancelList}
                                >
                                    조회
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-vc flex-tj bb2 pb8 mt40">
                            <div className="title-box mb0">
                                <h2 className="tl f18">취소/교환/반품 관리</h2>
                            </div>
                            <div className="category-box bar col-lg-12 mt-lg-16 mb0">
                                <ul>
                                    <li className={activeStep === "" ? "active" : ""}>
                                        <Link 
                                            to="" 
                                            className="link" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleCategoryClick("");
                                            }}
                                        >
                                            전체
                                        </Link>
                                    </li>
                                    <li className={activeStep === "cancel" ? "active" : ""}>
                                        <Link 
                                            to="" 
                                            className="link" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleCategoryClick("cancel");
                                            }}
                                        >
                                            취소
                                        </Link>
                                    </li>
                                    <li className={activeStep === "exchange" ? "active" : ""}>
                                        <Link 
                                            to="" 
                                            className="link" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleCategoryClick("exchange");
                                            }}
                                        >
                                            교환
                                        </Link>
                                    </li>
                                    <li className={activeStep === "return" ? "active" : ""}>
                                        <Link 
                                            to="" 
                                            className="link" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleCategoryClick("return");
                                            }}
                                        >
                                            반품
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {cancelList.length === 0 && (
                            <div className="list-none-box">
                                <p>취소/교환/반품 목록이 없습니다.</p>
                            </div>
                        )}

                        <div className="review-list-box">
                            <ul>
                                {cancelList.map((item, index) => (
                                    <li key={index}>
                                        <div className="list-top">
                                            <dl>
                                                <dd>
                                                    <p>주문일자:</p>
                                                    <b>{item.regist_date.split(' ')[0]}</b>
                                                </dd>
                                                <dd>
                                                    <Link to={`/mypage/orders-cancel/show-return/${item.order_seq}/${item.items[0].item_option_seq}`}
                                                        state={{ step_name: item.items[0].step_name }}
                                                        className="link"
                                                    >
                                                        {item.order_seq}
                                                    </Link>
                                                </dd>
                                            </dl>
                                        </div>
                                        {item.items.map((Dataitem, index) => (
                                        <div className="list-bottom" key={index}>
                                            <div className="bottom-left flex-lg-vt">
                                                <img src={Dataitem.image} alt="상품 이미지" />
                                                <div className="inner">
                                                    <div className="is-m mb12">
                                                        <div className="sticker-box">
                                                            <span className="red">{Dataitem.step_name}</span>
                                                        </div>
                                                    </div>
                                                    <b>O.Tok</b>
                                                    <p>{Dataitem.goods_name}</p>
                                                    <small>수량: {Dataitem.ea}개</small>
                                                </div>
                                            </div>
                                            <div className="bottom-right">
                                                <div className="inner">
                                                    <div className="bottom-right-price">
                                                        <p>{formatLocalString(Dataitem.price)}<em>원</em></p>
                                                    </div>
                                                    <div className="bottom-right-state">
                                                        <p>{Dataitem.step_name}</p>
                                                    </div>
                                                    <div className="bottom-right-text">
                                                        <p>{RETURN_TYPE[Dataitem.step_name] || Dataitem.step_name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        ))}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button" onClick={() => handlePageClick(1)}>처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button" onClick={() => handlePageClick(Math.max(1, currentPage - 1))}>이전</button>
                                </li>
                                {[1, 2, 3, 4, 5].map((page) => (
                                    <li key={page} className={currentPage === page ? 'active' : ''}>
                                        <button type="button" onClick={() => handlePageClick(page)}>
                                            {page}
                                        </button>
                                    </li>
                                ))}
                                <li className="next">
                                    <button type="button" onClick={() => handlePageClick(Math.min(5, currentPage + 1))}>다음</button>
                                </li>
                                <li className="last">
                                    <button type="button" onClick={() => handlePageClick(5)}>맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
